import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
//import { StaffrouteGuard } from './services/staffroute.guard';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';
import { AuthorizeComponent } from './authorize/authorize.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { LoginComponent } from './login/login.component';
import { DefinedRole } from './models/PreDefinedRoles';
import { PaymentStatusComponent } from './payment-status/payment-status.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'unauthorize', component: UnauthorizeComponent },
  { path: 'authorize', component: AuthorizeComponent },
  { path: 'updateuser', component: UpdateUserComponent },
  { path: 'login', component: LoginComponent },
  { path: 'paymentstatus/:statuscode', component: PaymentStatusComponent },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard], data: { roles: [DefinedRole.Admin] } },  
  { path: 'FU', loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule), canActivate: [AuthGuard], data: { roles: [DefinedRole.FU] } },
  { path: 'client', loadChildren: () => import('./client/client-main.module').then(m => m.ClientMainModule), canActivate: [AuthGuard], data: { roles: [DefinedRole.Client] } },
  { path: 'corporate-client', loadChildren: () => import('./corporate-client/corporate-client.module').then(m => m.CorporateClientModule), canActivate: [AuthGuard], data: { roles: [DefinedRole.Corporate] } },
  { path: 'corporate-employee', loadChildren: () => import('./corporate-employee/corporate-employee.module').then(m => m.CorporateEmployeeModule), canActivate: [AuthGuard], data: { roles: [DefinedRole.CC] } },
  { path: 'fustaff', loadChildren: () => import('./fustaff/fustaff.module').then(m => m.FustaffModule), canActivate: [AuthGuard] },
  //{ path: 'staffs', loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule), canActivate: [AuthGuard], canDeactivate: [StaffrouteGuard] },
  { path: 'staffs', loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule), canActivate: [AuthGuard] },
  { path: 'get-start', loadChildren: () => import('./get-start/get-start.module').then(m => m.GetStartModule) },
  { path: ':domain/:id', loadChildren: () => import('./fudomain/fudomain.module').then(m => m.FudomainModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
