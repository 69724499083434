import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError as _observableThrow } from 'rxjs';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, retry } from 'rxjs/operators';
import { OidcConfigService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //API_URL = 'https://localhost:44361/api';
  API_URL = 'https://account.fhconnect.co.za/api';
  constructor(private http: HttpClient, private oidcService: OidcConfigService) {
    if(this.oidcService.clientConfiguration)
      this.API_URL = this.oidcService.clientConfiguration.apiServer
  }

  HttpGet<T>(url, header): Observable<T> {
    return this.http.get<T>(this.API_URL + url, { headers: header });
  }
  HttpPost<T>(url, data, header): Observable<T> {
    return this.http.post<T>(this.API_URL + url, data, { headers: header });
  }
  HttpPut<T>(url, data, header): Observable<T> {
    return this.http.put<T>(this.API_URL + url, data, { headers: header });
  }
  HttpDelete<T>(url, header): Observable<T> {
    return this.http.delete<T>(this.API_URL + url, { headers: header });
  }
}
