import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { VendorService, VendorInfo } from '../../../services';

@Component({
  selector: 'app-update-commission-value',
  templateUrl: './update-commission-value.component.html',
  styleUrls: ['./update-commission-value.component.scss']
})
export class UpdateCommissionValueComponent implements OnInit {

  _form: FormGroup;
  vendorId;
  isloading: boolean;
  saving: boolean
  commission;

  constructor(
    public _formbuilder: FormBuilder,
    private service: VendorService,
    public dialogRef: MatDialogRef<UpdateCommissionValueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ''
  ) { 
    this.vendorId = data['fuid'];
  }

  ngOnInit() {
    this.isloading = true;
    this.service.getCommissionValue(this.vendorId)
    .pipe(
      finalize(() => {
        this.isloading = false;
      })
    )
      .subscribe(res => {
        this.commission = res;
      if(res){
        this._form = this._formbuilder.group({
          initialCommission: [res.initialCommission, Validators.required],
          onGoingCommission: [res.onGoingCommission, Validators.required]
        })
      }
      else{
        this._form = this._formbuilder.group({
          initialCommission: ['', Validators.required],
          onGoingCommission: ['', Validators.required]
        })
      }
      
    });    
  }

  onSubmit(data: VendorInfo){
    this.saving = true;
    data.vendorId = this.vendorId;
    if (this.commission)
      data.id = this.commission.id;
    this.service.updateCommissionValue(data)
    .pipe(
      finalize(() => {
        this.saving = false;
      })
    )
    .subscribe(res => {
      this.dialogRef.close(true);
    });
  }
  
  cancel(){
    this.dialogRef.close(false);
  }
}
