import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientAdd } from '../../models/client/client';
import { ClientService } from '../../services/client.service';
import { PlanService } from '../../services/plan.service';
import { clientservicetype } from '../../models/client/client';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';

export interface DialogData {
  firstname: string;
  email: string;
}

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {

  addclient: ClientAdd;
  result;
  errormsg;
  isenable = true;
  formGroup: FormGroup;
  clientservicetype: clientservicetype;
  clientId;
  plandetails;
  servicesList: string[] = ['Funeral Event', 'Funeral Policy'];
  from;
  fuid;
  furegion;

  constructor(public dialogRef: MatDialogRef<AddClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clientService: ClientService,
    public _formBuilder: FormBuilder,
    public planservice: PlanService,
    public dialog: MatDialog,
    public router: Router) {
    this.from = data['from'];
    this.fuid = data['Id'];
    console.log(this.from);
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      firstname: ["", Validators.required],
      email: ["", Validators.required],
      service: ["", Validators.required]
    });
  }

  onSubmit(data) {
    this.plandetails = data;
    this.isenable = false;
    let title;
    let msg;
    title = 'Service Type';
    msg = 'Are you sure you want to continue?';
    this.confirmDialog(title, msg, 'service');
  }

  confirmDialog(title, msg, func) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { title: title, msg: msg }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addClient();
      }
      else {
        this.isenable = true;
      }
    });
  }

  addClient() {
    let path;
    if (this.plandetails.service == 'Funeral Event') {
      this.addclient = {
        firstname: this.plandetails.firstname,
        email: this.plandetails.email,
        service: 1
      };
      if(this.from == 'fu')
        path = "FU/client/funeralevent";
      if (this.from == 'admin')
        path = "admin/fu/funeralevent/" + this.fuid;
      if (this.from == 'fustaff')
        path = "fustaff/client/addeventclient/" + this.fuid;
    }
    else {
      this.addclient = {
        firstname: this.plandetails.firstname,
        email: this.plandetails.email,
        service: 2
      };
      if (this.from == 'fu')
        path = "FU/client/service";
      if (this.from == 'admin')
        path = "admin/fu/service/" + this.fuid;
      if (this.from == 'fustaff')
        path = "fustaff/client/add/" + this.fuid;
    }
    if (this.from == 'fu') {
      this.clientService.addClient(this.addclient).subscribe(response => {
        this.result = response;
        this.clientId = this.result.reasonPhrase;
        this.dialogRef.close();
        this.router.navigate([path, response.reasonPhrase]);
      }, (err) => {
        if (err.error.errors)
          this.errormsg = err.error.errors[0].description;
        else
          this.errormsg = err.error.Email[0];
      });
    }
    else {
      console.log(232);
      this.clientService.addClientbySA(this.addclient, this.fuid).subscribe(response => {
        this.result = response;
        this.clientId = this.result.reasonPhrase;
        this.dialogRef.close();
        this.router.navigate([path, response.reasonPhrase]);
      }, (err) => {
        if (err.error.errors)
          this.errormsg = err.error.errors[0].description;
        else
          this.errormsg = err.error.Email[0];
      });
    }

  }

  onChange() {
    this.isenable = true;
  }

  onNoClick(): void {
    this.formGroup.reset();
    this.dialogRef.close();
  }
}
