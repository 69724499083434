import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})



export class GoogleCalendarService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }


  AuthenticateCalendar(strDate, endDate) {       
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/GoogleCalendar/AuthenticateCalendar?strDate="+strDate+"&endDate="+endDate, header);    
  }  
}




