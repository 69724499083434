import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { merge, of as observableOf } from 'rxjs';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SupplierService, SupplierPayment } from './../../../services/supplier.service';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { ProductService, CommonService } from '@app/services';
import { ConfirmPopupComponent } from '@app/shared/confirm-popup/confirm-popup.component';
import { EditProductComponent } from '@app/shared/edit-product/edit-product.component';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
  selector: 'app-supplier-product-list',
  templateUrl: './supplier-product-list.component.html',
  styleUrls: ['./supplier-product-list.component.scss']
})
export class SupplierProductListComponent implements OnInit {

  @Input() supplierId;
  @Input() readonly;
  productid;
  data: [] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  searchKey: string = '';
  displayedColumns: string[] = ['image', 'name', 'quantity', 'price', 'Action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public service: ProductService,
    public dialog: MatDialog,
    public common: CommonService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.loaddata();
  }

  loaddata() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          // tslint:disable-next-line: no-non-null-assertion
          return this.service!.getSupplierProducts(this.supplierId, this.paginator.pageIndex);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.supplierProducts.length;
          return data.supplierProducts;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  deleteConSupplierProduct(productid) {
    this.productid = productid;
    const title = 'delete supplier Product';
    const msg = 'Are you sure you want to delete this product from this supplier ?';
    const func = 'deleteSupplierProduct';
    this.confirmDialog(title, msg, func);
  }

  deleteSupplierProduct() {
    this.service.deleteSupplierProduct(this.supplierId,  this.productid).subscribe(res => {
      this.common.openSnackBar('Product deleted successfully', 'Close');
      this.loaddata();
    })
  }

  editProduct(id) {
    let productdata;
    this.service.getSupplierProduct(id).subscribe(res => {
      productdata = res;
      this.editproductpopup(this.supplierId, productdata);
    });
  }

  editproductpopup(supplierid, productdata): void {
    const dialogRef = this.dialog.open(EditProductComponent, {
      width: '650px',
      data: { supplierid: supplierid, productdata: productdata }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loaddata();
        this.common.openSnackBar('Product updated successfully', 'Close');
      }
    });
  }

  confirmDialog(title, msg, func) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { title: title, msg: msg }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (func) {
          case 'deleteSupplierProduct': {
            this.deleteSupplierProduct();
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

}
