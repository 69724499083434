import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from '../../services/product.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  productFormGroup: FormGroup;
  imageURL = '/assets/images/avatar.png';
  isenable = true;
  editdata;
  fileToUpload;
  productList;
  produtoptionlist;
  optionvaluelist;
  categoryList;
  supplierproduct;
  profileerrormsg;
  isloaded = false;
  fileerror;
  path;

  constructor(
    public auth: AuthService,
    public account: AccountService,
    public _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
    public productservice: ProductService
  ) {
    this.editdata = data;
  }

  ngOnInit() {
    this.path = this.editdata.productdata.imageUrl;
    this.imageURL = this.auth.imageURL + this.editdata.productdata.imageUrl;
    this.getProductList();
    this.productFormGroup = this._formBuilder.group({
      productimage: [''],
      product: [this.editdata.productdata.productId, Validators.required],
      quantity: [this.editdata.productdata.quantity, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.required])],
      price: [this.editdata.productdata.price, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.required])]
    });
    if (this.editdata.productdata.productOption.length > 0) {
      var productoption = this._formBuilder.array([]);
      this.editdata.productdata.productOption.forEach((item, index) => {
        productoption.push(this._formBuilder.group({
          productOptionId: [{ value: item.productOptionId, disabled: true }],
          productOptionValueId: [{ value: +item.productOptionValueId, disabled: true }]
        }));
      })
      this.productFormGroup.addControl("productOption", productoption);
    }
    
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  getProductList() {
    this.productservice.getEventProducts().subscribe(res => {
      this.productList = res;
      this.isloaded = true;
    });
    this.productservice.getAllProductOption().subscribe(res => {
      this.produtoptionlist = res;
    });
    this.productservice.getAllProductOptionValue().subscribe(res => {
      this.optionvaluelist = res;
    });
  }

  onSubmit(data) {
    this.supplierproduct = {
      id: this.editdata.productdata.id,
      supplierId: this.editdata.supplierid,
      productId: this.editdata.productdata.productId,
      imageUrl: this.path,
      price: data.price,
      quantity: data.quantity
    }
    this.productservice.updatesupplierProduct(this.editdata.supplierid, this.editdata.productdata.productId, this.supplierproduct).subscribe(res => {
      this.dialogRef.close(true);
    }, err => {
      this.profileerrormsg = err.error.errors[0].description;
    });
  }

  profileupload(file: FileList, files) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.account.uploadimage(formData).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }
}
