import { Injectable } from '@angular/core';
import { OidcConfigService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {

  constructor(private oidcService: OidcConfigService) { }

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.oidcService.onConfigurationLoaded.subscribe(() => {
        resolve();
      }, () => {
        alert("Api Server not connected");
      });
    });
  }

}
