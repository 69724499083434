import { Injectable, EventEmitter, PLATFORM_ID, Inject, NgZone } from '@angular/core';
import { OidcSecurityService, OidcConfigService } from 'angular-auth-oidc-client';
import { isPlatformBrowser } from '@angular/common'
import { HttpHeaders } from '@angular/common/http';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { UserInfo } from '../../models/account/common';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { LocalstorageService } from '../localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  options: InAppBrowserOptions = {
    location: 'yes',//Or 'no' 
    hidden: 'no', //Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes',//Android only ,shows browser zoom controls 
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', //Android only 
    closebuttoncaption: 'Close', //iOS only
    disallowoverscroll: 'no', //iOS only 
    toolbar: 'yes', //iOS only 
    enableViewportScale: 'no', //iOS only 
    allowInlineMediaPlayback: 'no',//iOS only 
    presentationstyle: 'pagesheet',//iOS only 
    fullscreen: 'yes',//Windows only    
  };

  isAuthorized: boolean;
  isAuthStatus = new EventEmitter();
  userRole;
  getstartStatus: boolean;
  _userInfo: UserInfo;
  userpermission: Array<string>;
  imageURL;
  documentUrl;
  platformId: string;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    public api: ApiService,
    private oidcConfig: OidcConfigService,
    private theInAppBrowser: InAppBrowser,
    @Inject(PLATFORM_ID) _platformId: string,
    public router: Router,
    public storageservice: LocalstorageService,
    private ngZone: NgZone
  ) {
    this.platformId = _platformId;
  }

  init() {
    this.oidcSecurityService.getIsAuthorized().subscribe(res => {
      this.isAuthorized = res;
      if (!res) {
        this.isAuthStatus.emit(res);
      }
      else {
        if (!this._userInfo) {
          this.getUserData();
        }
        else {
          this.isAuthStatus.emit(true);
        }
      }
    });
    this.imageURL = this.oidcConfig.clientConfiguration.apiServer + '/Uploader/image?fileId=';
    this.documentUrl = this.oidcConfig.clientConfiguration.apiServer + '/Uploader?fileId=';
  }

  login() {
    const isBrowser = isPlatformBrowser(this.platformId);
    if (isBrowser) {
      //this is only executed on the browser
      this.oidcSecurityService.authorize();
    }
    else {
      this.oidcSecurityService.authorize((authUrl) => {
        this.loginInAppBrowser(authUrl).then(responseHash => {
         // this.oidcSecurityService.authorizedCallback(responseHash);
          this.router.navigateByUrl("/updateuser");
        }, (error) => {
        });
      });
    }
  }
  loginInAppBrowser(url): Promise<any> {
    return new Promise((resolve, reject) => {
      let target = "_blank";
      const browserRef = this.theInAppBrowser.create(url, target, this.options);
      browserRef.on("exit").subscribe((event) => {
        this.ngZone.run(() => this.router.navigateByUrl("/"));
      });
      browserRef.on('loadstop').subscribe((event) => {
        if ((event.url).indexOf('http://localhost') !== -1) {
          browserRef.close();
          let lastIndex = event.url.lastIndexOf('#')
          if (lastIndex === -1) {
            reject("Hash is not valid");
          }
          const responseHash = ((event.url).substring(++lastIndex));
          this.oidcSecurityService.authorizedCallback(responseHash);
          setTimeout(() => {
            this.ngZone.run(() => this.router.navigateByUrl("/updateuser"));
          }, 10);
          
          resolve(responseHash);
        } else {
          reject("Check your identityserver redirect uri")
        }
      });
    });
  }
  logout() {
    this.oidcSecurityService.logoff();
  }
  signout() {
    this.storageservice.clear();
  }
  getIsAuthorized() {
    return this.oidcSecurityService.getIsAuthorized();
  }
  private getHeader() {
    var headers = new HttpHeaders();
    //headers = headers.set('Content-Type', 'application/json');
    //headers = headers.set('Accept', 'application/json');
    //headers = headers.set('Access-Control-Allow-Origin', '*');

    const token = this.oidcSecurityService.getToken();
    if (token !== '') {
      const tokenValue = 'Bearer ' + token;
      headers = headers.set('Authorization', tokenValue);
    }
    return headers;
  }

  getAuthHeader() {
    return this.getHeader();
  }
  getUserData() {
    this.oidcSecurityService.getUserData().subscribe(res => {
      this._userInfo = res;
      this.userRole = this._userInfo.role;
      this.userpermission = this._userInfo.permission;
      this.isAuthStatus.emit(true);
    })
  }

  getmypermission() {
    var mypermission = []
    if (typeof this.userpermission === "object") {
      mypermission = this.userpermission;
    }
    else {
      mypermission.push(this.userpermission);
    }
    return mypermission;
  }

}
