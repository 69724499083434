import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }
  login() {
    let data = {
      "client_id": "fmcclient2",
      "grant_type": "password",
      "username": "antonyclient2@yopmail.com",
      "password": "Admin@123",
      "scopes": "openid profile api1"
    };
    this.http.post("https://localhost:44361/connect/token", data).subscribe(res => {
    })
  }
}
