import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClaimService } from '../../services/claim.service';

@Component({
  selector: 'app-claimpayment',
  templateUrl: './claimpayment.component.html',
  styleUrls: ['./claimpayment.component.css']
})
export class ClaimpaymentComponent implements OnInit {

  claimFormGroup: FormGroup;
  clientdata;

  constructor(public _formbuilder: FormBuilder, public dialogRef: MatDialogRef<ClaimpaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '', public claimservice: ClaimService, ) {
    this.clientdata = data;
  }

  ngOnInit() {
    this.claimFormGroup = this._formbuilder.group({
      paymentMethod: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(1)]]
    });
  }

  getmethod(value) {
    if (value == 'bank') {
      this.claimFormGroup.addControl('bankName', new FormControl({ value: this.clientdata.bankdetials.bankName, disabled: true }));
      this.claimFormGroup.addControl('branch', new FormControl({ value: this.clientdata.bankdetials.branch, disabled: true }));
      this.claimFormGroup.addControl('branchCode', new FormControl({ value: this.clientdata.bankdetials.branchCode, disabled: true }));
      this.claimFormGroup.addControl('accountHolderName', new FormControl({ value: this.clientdata.bankdetials.accountHolderName, disabled: true }));
      this.claimFormGroup.addControl('accountNo', new FormControl({ value: this.clientdata.bankdetials.accountNo, disabled: true }));
      this.claimFormGroup.addControl('typeOfAccount', new FormControl({ value: this.clientdata.bankdetials.typeOfAccount, disabled: true }));
      this.claimFormGroup.addControl('transactionId', new FormControl('', Validators.required));
    }
    else {
      this.claimFormGroup.removeControl('bankName');
      this.claimFormGroup.removeControl('branch');
      this.claimFormGroup.removeControl('branchCode');
      this.claimFormGroup.removeControl('accountHolderName');
      this.claimFormGroup.removeControl('accountNo');
      this.claimFormGroup.removeControl('typeOfAccount');
      this.claimFormGroup.removeControl('transactionId');
    }
    if (this.clientdata.amount > 0)
      this.claimFormGroup.get('amount').setValue(this.clientdata.amount);

  }

  onSubmit(data) {
    if (!data.transactionId) {
      data.transactionId = 0;
    }
    var claimpayment = {
      claimId: this.clientdata.claimId,
      clientId: this.clientdata.clientId,
      beneficiaryId: this.clientdata.id,
      paymentMethod: data.paymentMethod,
      amount: data.amount,
      TransactionId: data.transactionId
    }
    this.claimservice.saveclaimpayment(claimpayment).subscribe(res => {
      this.dialogRef.close(true);
    });
  }


  cancel() {
    this.dialogRef.close(false);
  }
}
