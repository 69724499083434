import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { status, planstatuschange } from '@app/models';
import { MatDialog } from '@angular/material';
import { ConfirmPopupComponent } from '@app/shared/confirm-popup/confirm-popup.component';
import {  CommonService, CorporateService, VendorService } from '@app/services';
import { PopupComponent } from '@app/shared/popup/popup.component';
import { ChangeInceptiondateComponent } from '@app/shared/change-inceptiondate/change-inceptiondate.component';
import { AuthService } from '@app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employeer-profile',
  templateUrl: './employeer-profile.component.html',
  styleUrls: ['./employeer-profile.component.scss']
})
export class EmployeerProfileComponent implements OnInit {

  @Input() data;
  @Input() plandetails;
  @Input() others;
  @Input() getstartstatus;
  @Output() render = new EventEmitter();
  statusList = [{ id: 0, value: 'Pending', text: 'Pending' }, { id: 1, value: 'Active', text: 'Active' }, { id: 2, value: 'Inactive', text: 'Inactive' }, { id: 3, value: 'Suspend', text: 'Suspended' }, { id: 4, value: 'Cancel', text:'Cancelled' }, { id: 5, value: 'Deceased', text:'Deceased' }, { id: 6, value: 'Close', text: 'Closed' }];
  imageURL = '';
  authimageurl;
  fileerror;
  path;
  fileToUpload;
  status: status;
  filename;
  selectedValue;
  changeplanstatus: planstatuschange;
  PlanStatus;
  vendorplan;
  deletelicenseid;
  deletelicensefileId;
  furegion;
  dataSource;
  cancelemployertauts;
  PlanStatusflag = false;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private corporateService: CorporateService,
    public common: CommonService,
    private auth: AuthService,
    private vendorService: VendorService,
  ) { }

  ngOnInit() {
    this.authimageurl = this.auth.imageURL;
    this.imageURL=this.others.imageURL;
  }

  corporateEdit(path) {
    this.router.navigate([path, this.others.vendorId, this.others.id]);
  }

  profileupload(file: FileList, files, userId) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.vendorService.profileImgUpload(formData, userId).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
          this.filename = res.filename;
          this.imageURL = this.auth.imageURL + this.path;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  StatusView(index) {
    return this.statusList[index].text.toString();
  }
  
  onStatus(value) {
    this.status = { id: this.data.corporate.userId, status: value };
    let name;
    switch (value) {
      case 1: {
        name = 'active';
        break;
      }
      case 2: {
        name = 'inactive';
        break;
      }
      case 3: {
        name = 'suspend';
        break;
      }
      case 4: {
        name = 'cancel';
        break;
      }
      case 6: {
        name = 'close';
        break;
      }
      case 0: {
        name = 'pending';
        break;
      }
      default: {
        break;
      }
    }
    let title = name + ' CORPORATE CLIENT ';
    let msg = 'Are you sure you want to change the status of this Corporate Client to ' + name + ' ?';
    let func = 'changeStatus';
    this.confirmDialog(title, msg, func);
  }

  confirmDialog(title, msg, func) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { title: title, msg: msg }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (func) {
          case 'changeStatus': {
            this.changeStatus();
            break;
          }
          case 'deactivate': {
            this.deactivateplan();
            break;
          }
          case 'reactivate': {
            this.common.openSnackBar('Plan reactivated Successfully', 'Close');
            break;
          }
          case 'deleteproposal': {
            this.deleteproposal();
            break;
          }
          case 'cancelemployer': {
            this.cancelemployer();
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

  changeStatus() {
    this.corporateService.changeCorporateClientStatus(this.status).subscribe(res => {
      this.selectedValue = this.status.status;
      this.data.status = this.status.status;
      this.common.openSnackBar('Status updated Successfully', 'Close');
    }, err =>{
      this.common.openSnackBar(err.error.reasonPhrase, 'Close');
    });
  }

  deactivateplan() {
    this.vendorService.changevendorplanstatus(this.changeplanstatus).subscribe(res => {
      this.getplan();
      this.common.openSnackBar('Plan deactivated Successfully', 'Close');
    }, err => {
      this.common.openSnackBar('Plan not deactivated', 'Close');
    })
  }

  deleteproposal() {    ;
    this.corporateService.deleteProposal(this.deletelicenseid, this.deletelicensefileId).subscribe(res => {
      this.getlicense();
      this.common.openSnackBar('Document deleted successfully', 'Close');
    })
  }

  cancelplanpopup(value) {
    let title;
    let msg;
    let func;
    this.cancelemployertauts = value;
    if (this.PlanStatus.type == 'cancelemployer') {
      if (value == 2) {
        title = 'Decline Cancel Employer';
        msg = 'Are you sure you want to decline the cancel employer request?';
        func = 'cancelemployer';
      }
      if (value == 3) {
        title = 'Approve Cancel Employer';
        msg = 'Are you sure you want to approve the cancel employer request?';
        func = 'cancelemployer';
      }
    }
    if (this.PlanStatus.type == 'change') {
      if (value == 2) {
        title = 'Decline Upgrade & Downgrade Plan';
        msg = 'Are you sure you want to decline the Upgrade & Downgrade plan request?';
        func = 'changeplan';
      }
      if (value == 3) {
        title = 'Approve Upgrade & Downgrade Plan';
        msg = 'Are you sure you want to approve the Upgrade & Downgrade plan request?';
        func = 'changeplan';
      }
    }
    this.confirmDialog(title, msg, func);
  }

  cancelemployer() {
    var data = {
      clientId: this.data.corporate.userId,
      status: this.cancelemployertauts,
      type: 'cancelemployer'
    }

    this.corporateService.cancelemployer(data).subscribe(res => {
      if (res.succeeded == true) {
        this.common.openSnackBar(res.reasonPhrase, "Close");
        this.planstatus();
      }
    })
  }

  planstatus() {
    this.corporateService.GetEmployerRequestStatus(this.data.corporate.userId).subscribe(res => {
      this.PlanStatus = res;      
      if (this.PlanStatus) {
        if (this.PlanStatus.status == 1 || !this.plandetails)
          this.PlanStatusflag = true;
      }
    })
  }

  getplan() {
    this.vendorService.getVendorPlan(this.data.corporate.userId, this.furegion).subscribe(res => {
      this.vendorplan = res;
    })
  }

  getlicense() {
    this.vendorService.getLicense(this.data.corporate.userId).subscribe(res => {
      this.dataSource = res;
    });
  }
}
