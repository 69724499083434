import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { status } from '@app/models';
import { MatDialog } from '@angular/material';
import { ConfirmPopupComponent } from '@app/shared/confirm-popup/confirm-popup.component';
import { ClientService, CommonService } from '@app/services';
import { PopupComponent } from '@app/shared/popup/popup.component';
import { ChangeInceptiondateComponent } from '@app/shared/change-inceptiondate/change-inceptiondate.component';
import { AuthService } from '@app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {

  @Input() data;
  @Input() plandetails;
  @Input() others;
  @Output() render = new EventEmitter();
  statusList = [{ id: 0, value: 'Pending', text: 'Pending' }, { id: 1, value: 'Active', text: 'Active' }, { id: 2, value: 'Inactive', text: 'Inactive' }, { id: 3, value: 'Suspend', text: 'Suspended' }, { id: 4, value: 'Cancel', text:'Cancelled' }, { id: 5, value: 'Deceased', text:'Deceased' }, { id: 6, value: 'Close', text: 'Closed' }];
  imageURL = '/assets/images/avatar.png';
  authimageurl;
  fileerror;
  path;
  fileToUpload;
  status: status;
  selectedValue;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private clientService: ClientService,
    public common: CommonService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.authimageurl = this.auth.imageURL;
  }

  StatusView(index) {
    return this.statusList[index].text;
  }

  clientedit(item) {
    let clientid = this.data.id;
    this.router.navigate([item, clientid]);
  }

  onStatus(value) {
    this.status = { id: this.data.id, status: value };
    let name = this.StatusView(value);
    let title = name + ' client ';
    let msg = 'Are you sure you want to change the status to ' + name + '?';
    let func = 'changeStatus';
    this.confirmDialog(title, msg, func);
  }

  // CONFIRM POPUP FUNCTION
  confirmDialog(title, msg, func) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { title: title, msg: msg }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (func) {
          case 'changeStatus': {
            this.changeStatus();
            break;
          }
          default: {
            break;
          }
        }
      }
      
    });
  }

  // STATUS CHANGE API CALL FUNCTION 
  changeStatus() {
    if (this.data.status == 3 && this.status.status == 1) {
      if (this.plandetails) {
        this.clientService.reinstatement(this.plandetails.id).subscribe(res => {
          if (res.reasonPhrase == "reinstatement") {
            this.reinstatementDate();
          }
          if (res.reasonPhrase == "close") {
            this.msgpopup();
          }
          if (res.reasonPhrase == "") {
            this.clientService.changeStatus(this.status).subscribe(res => {
              this.selectedValue = this.status.status;
              this.data.status = this.status.status;
              this.common.openSnackBar('Status updated Successfully', 'Close');
            });
          }
        });
      }
    }
    else {
      this.clientService.changeStatus(this.status).subscribe(res => {
        this.selectedValue = this.status.status;
        this.data.status = this.status.status;
        this.common.openSnackBar('Status updated Successfully', 'Close');
      });
    }
  }

  msgpopup(): void {
    let title = 'Status';
    let msg = 'This user didnt pay premium for 3 month so this account cant activate';
    let icon = 'account_circle';
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '450px',
      data: { title: title, msg: msg, icon: icon }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  reinstatementDate() {
    const dialogRef = this.dialog.open(ChangeInceptiondateComponent, {
      data: { clientid: this.data.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService.savepolicynumber(this.data.id).subscribe(res1 => {
          this.common.openSnackBar('Inception date updated Successfully', 'Close');
          this.clientService.changeStatus(this.status).subscribe(res => {
            this.selectedValue = this.status.status;
            this.common.openSnackBar('Status updated Successfully', 'Close');
          });
        })
        this.render.emit(true);
      }
    });
  }

  profileupload(file: FileList, files, userId) {  
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL= event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);      
      this.clientService.profileImgUpload(formData, userId).subscribe(res => {
        if (res.succeeded) {        
          this.path = res.fileId;
          this.data.profileImgPath = this.path;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  get getImageURL(){
    if (this.data && this.data.profileImgPath)
      this.imageURL = this.authimageurl + this.data.profileImgPath;
    
    return this.imageURL
  }

}
