import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { FuAdd } from '../../models/FU/FU';
import { VendorService } from '../../services/vendor.service';

export interface DialogData {
  firstname: string;
  email: string;
}


@Component({
  selector: 'app-add-fu',
  templateUrl: './add-fu.component.html',
  styleUrls: ['./add-fu.component.css']
})
export class AddFuComponent implements OnInit {

  addfu: FuAdd;
  result;
  errormsg;
  isenable = true;
  saving: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddFuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private vendorService: VendorService) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.data.email = '';
    this.data.firstname = '';
    this.dialogRef.close();
  }

  onSubmit(form) {
    this.isenable = false;
    this.addFU(form);
  }

  addFU(FU) {
    this.addfu = FU;
    this.saving = true;
    this.vendorService.addVendor(this.addfu)
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe(response => {
      this.result = response;
      if (this.result.statusCode == 200) {
        this.dialogRef.close(true);

      }
    }, (err) => {
      if (err.error.errors)
        this.errormsg = err.error.errors[0].description;
      else
        this.errormsg = err.error.Email[0];
    });
  }

  onChange() {
    this.isenable = true;
  }
}
