import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize, retry } from 'rxjs/operators';
import moment from 'moment-timezone';
import { ClientService } from '../../services/client.service';
import { SupplierService } from '../../services/supplier.service';
import { SearchClient, ISearchClient } from '../../models/client/client';
import { EventModel } from '../../models/calendar/Event.model';
import { EventService } from '../../services/event.service';
import { CommonService } from '../../services/common.service';

export interface DialogData {
  id: number;
  manage: boolean;
  ClientName: string;
  ClientId: string;
  VendorId: string,
  SupplierId: string;
  AssigneeName: string;
  StartDate: string;
  StartTime: string;
  End: string;
  Place: string;
  Description: string;
  viewEventList:boolean;
  viewEvent: boolean;
  addEvent: boolean;
  editEvent: boolean;
  toSelect: string;
  currentDate: string;
}

export interface Time {
  value: string;
  viewValue: string;
}
moment.tz.setDefault('Utc');

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  eventData: EventModel;
  eventForm: FormGroup;
  isEdit: boolean;
  eventId: any;
  filteredClients: SearchClient[] = [];
  filteredSuppliers: SearchClient[] = [];
  isLoading = false;
  toSelect;
  dataObject: DialogData[] = [];
  action = [];
  managaeevents: boolean;
  Time: Time[] = [
    { value: '00:00 AM', viewValue: '00:00 AM' },
    { value: '12:30 AM', viewValue: '12:30 AM' },
    { value: '01:00 AM', viewValue: '01:00 AM' },
    { value: '01:30 AM', viewValue: '01:30 AM' },
    { value: '02:00 AM', viewValue: '02:00 AM' },
    { value: '02:30 AM', viewValue: '02:30 AM' },
    { value: '03:00 AM', viewValue: '03:00 AM' },
    { value: '03:30 AM', viewValue: '03:30 AM' },
    { value: '04:00 AM', viewValue: '04:00 AM' },
    { value: '04:30 AM', viewValue: '04:30 AM' },
    { value: '05:00 AM', viewValue: '05:00 AM' },
    { value: '05:30 AM', viewValue: '05:30 AM' },
    { value: '06:00 AM', viewValue: '06:00 AM' },
    { value: '06:30 AM', viewValue: '06:30 AM' },
    { value: '07:00 AM', viewValue: '07:00 AM' },
    { value: '07:30 AM', viewValue: '07:30 AM' },
    { value: '08:00 AM', viewValue: '08:00 AM' },
    { value: '08:30 AM', viewValue: '08:30 AM' },
    { value: '09:00 AM', viewValue: '09:00 AM' },
    { value: '09:00 AM', viewValue: '09:00 AM' },
    { value: '10:00 AM', viewValue: '10:00 AM' },
    { value: '10:30 AM', viewValue: '10:30 AM' },
    { value: '11:00 AM', viewValue: '11:00 AM' },
    { value: '11:30 AM', viewValue: '11:30 AM' },
    { value: '12:00 PM', viewValue: '12:00 PM' },
    { value: '12:30 PM', viewValue: '12:30 PM' },
    { value: '13:00 PM', viewValue: '13:00 PM' },
    { value: '13:30 PM', viewValue: '13:30 PM' },
    { value: '02:00 PM', viewValue: '02:00 PM' },
    { value: '02:30 PM', viewValue: '02:30 PM' },
    { value: '03:00 PM', viewValue: '03:00 PM' },
    { value: '03:30 PM', viewValue: '03:30 PM' },
    { value: '04:00 PM', viewValue: '04:00 PM' },
    { value: '04:30 PM', viewValue: '04:30 PM' },
    { value: '05:00 PM', viewValue: '05:00 PM' },
    { value: '05:30 PM', viewValue: '05:30 PM' },
    { value: '06:00 PM', viewValue: '06:00 PM' },
    { value: '06:30 PM', viewValue: '06:30 PM' },
    { value: '07:00 PM', viewValue: '07:00 PM' },
    { value: '07:30 PM', viewValue: '07:30 PM' },
    { value: '08:00 PM', viewValue: '08:00 PM' },
    { value: '08:30 PM', viewValue: '08:30 PM' },
    { value: '09:00 PM', viewValue: '09:00 PM' },
    { value: '09:30 PM', viewValue: '09:30 PM' },
    { value: '10:00 PM', viewValue: '10:00 PM' },
    { value: '10:30 PM', viewValue: '10:30 PM' },
    { value: '11:00 PM', viewValue: '11:00 PM' },
    { value: '11:30 PM', viewValue: '11:30 PM' },
  ];
  onAdd = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<CalendarComponent>,
    private fb: FormBuilder,
    private clientService: ClientService,
    private supplierService: SupplierService,
    private eventService: EventService,
    public common : CommonService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
      this.toSelect = moment(data.StartTime, 'HH:mm:a').format("hh:mm A");
      if (this.data.id) {
        this.eventId = this.data.id;
      }
      if (this.data.manage) {
        this.managaeevents = true;
      }
      else {
        this.managaeevents = false;
      }
   }

  ngOnInit() {
    let clientdata = new FormControl('', Validators.required);
    if (this.data.ClientId) {
      const defaultclient = new SearchClient(Number(this.data.ClientId), null);
      this.filteredClients.push(defaultclient);
      clientdata = new FormControl(defaultclient, Validators.required);
    }
    let supplierdata = new FormControl('', Validators.required);
    if (this.data.SupplierId) {
      const defaultsupplier = new SearchClient(Number(this.data.SupplierId), null);
      this.filteredSuppliers.push(defaultsupplier);
      supplierdata = new FormControl(defaultsupplier, Validators.required);
    }

    this.eventForm = this.fb.group({
      ClientName: new FormControl(this.data.ClientName),
      ClientId: clientdata,
      SupplierId: supplierdata,
      AssigneeName: new FormControl(this.data.AssigneeName),
      Place: new FormControl(this.data.Place),
      Description: new FormControl(this.data.Description),
      StartTime: new FormControl(this.data.StartTime)
    });
    this.eventForm
      .get('ClientId')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => {
          if (typeof value == "string") {
            return this.common.getusersbystatus({ name: value }, 1, 1).pipe(finalize(() => this.isLoading = false))
          }
          else {
            return this.common.getusersbystatus({ name: value['id'] }, 1, 1).pipe(finalize(() => this.isLoading = false))
          }
        })
      )
      .subscribe((users: ISearchClient) => this.filteredClients = users.users);
    this.eventForm
      .get('SupplierId')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => {
          if (typeof value == 'object') {
            return this.supplierService.suppliersearch({ name: value["id"] }).pipe(finalize(() => this.isLoading = false))
          }
          else {
            return this.supplierService.suppliersearch({ name: value }).pipe(finalize(() => this.isLoading = false))
          }
        })
      ).subscribe((users: ISearchClient) => this.filteredSuppliers = users.users)
  }

  displayFn(user: SearchClient) {
    if (user) { return user.id; }
  }

  displayFnSupplier(user: SearchClient) {
    if (user) { return user.id; }
  }

  onSubmit() {
    this.eventData = this.eventForm.value;
    this.eventData.StartDate = this.data.StartDate;
    if (typeof this.eventData.ClientId == "object") {
      this.eventData.ClientId = this.eventData.ClientId["id"];
    }
    if (typeof this.eventData.SupplierId == "object") {
      this.eventData.SupplierId = this.eventData.SupplierId["id"];
    }
    if (this.data.id) {
      this.eventData.id = this.data.id;
      this.eventService.editEvent(this.data.id, this.eventData).subscribe(() => {
        this.dialogRef.close();
      }, error => {
        if (error.status === 400) {
          if (typeof error.error === 'object') {
            for (var fieldName in error.error) {
              this.eventForm.controls[fieldName].setErrors({ invalid: true });
            }
          }
        }
      });
    }
    else {
      this.eventService.addEvent(this.eventData).subscribe(() => {
        this.dialogRef.close();
      }, error => {
        if (error.status === 400) {
          if (typeof error.error === 'object') {
            for (var fieldName in error.error) {
              this.eventForm.controls[fieldName].setErrors({ invalid: true });
            }
          }
        }
      });
    }
  }

  viewEvent(id) {
    this.action.push(id);
    this.action.push('view');
    this.onAdd.emit(this.action);
  }

  addMoreEvent(date) {
    this.action.push(date);
    this.action.push('add');
    this.onAdd.emit(this.action);
  }

  editEvent(id) {
    this.action.push(id);
    this.action.push('edit');
    this.onAdd.emit(this.action);
  }

  deleteEvent(id) {
    this.action.push(id);
    this.action.push('delete');
    this.onAdd.emit(this.action);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
