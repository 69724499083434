import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment-timezone';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CommonService } from '../../services/common.service';
import { ClientService } from '../../services/client.service';
import { beneficiary, beneficiaryClient } from '../../models/client/client';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrls: ['./add-beneficiary.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})
export class AddBeneficiaryComponent implements OnInit {

  title;
  ageerror;
  clientid;
  personFormGroup: FormGroup;
  relationship;
  plandetails;
  beneficiarycount;
  existbenecount;
  benefit;
  beneficiarydata;
  errormsg;
  beneficiary: beneficiary;
  beneficiaryclient: beneficiaryClient;
  saving = false;
  enable = false;
  age;
  globelagelimt;
  maxDate = new Date();
  dob = new FormControl(moment());
  NDIerror;
  errormessage;

  constructor(public common: CommonService, public _formbuilder: FormBuilder, public clientservice: ClientService, public dialogRef: MatDialogRef<AddBeneficiaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '', ) {
    this.clientid = data;
  }

  manualcheck() {
    this.errormessage = this.common.manualcheck(this.personFormGroup);
  }

  ngOnInit() {
    this.title = this.common._title;
    this.getrelationship();
    this.getplandetails();
    this.personFormGroup = this._formbuilder.group({
      title: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      firstname: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      surname: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      age: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
      relationship: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern('^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$')])],
      PassportNo: ['', Validators.compose([Validators.pattern('^[a-zA-Z0-9]*$')])],
      nationalId: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)])],
      nationality: ['', Validators.pattern('[a-zA-Z ]*$')],
      PhoneNumber: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
      Mobilenumber: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
      benefit: ['', Validators.compose([Validators.required, Validators.min(1)])],
      bankName: [''],
      accountNo: [''],
      typeOfAccount: [''],
      accountHolderName: [''],
      branch: [''],
      branchCode: ['']
    });
    this.getbenefit();
    this.personFormGroup.valueChanges.subscribe(val => {
      this.onChange();
    });
    this.getagelimit();
  }

  onChange() {
    if (this.personFormGroup.valid) {
      this.enable = false;
    }
  }

  calculateage(type: string, event: MatDatepickerInputEvent<Date>) {
    this.age = this.common.calculateage(event.value);
    this.personFormGroup.get('age').setValue(this.age);
  }

  getagelimit() {
    this.common.loadPlanGlobalSettings().subscribe(res => {
      this.globelagelimt = res;
    });
  }

  getrelationship() {
    this.common.loadRelationship().subscribe(res => {
      this.relationship = res;
    });
  }

  getplandetails() {
    this.clientservice.getPlanTypeByclientid(this.clientid.clientid).subscribe(res => {
      this.plandetails = res;
      this.beneficiarycount = this.plandetails.beneficarycount;
    });
  }

  getbenefit() {
    this.clientservice.getBeneficiary(this.clientid.clientid).subscribe(res => {
      this.beneficiarydata = res;
      this.existbenecount = this.beneficiarydata.length;
      this.beneficiarydata.forEach((item, index) => {
        this.benefit = this.benefit + Number(item.benefit);
      });
      this.benefit = 100 - this.benefit;
    });
  }

  bank;
  onSubmit(data) {
    this.saving = true;
    this.enable = true;
    if (data.nationalId != '')
      this.validationnationid()
    if (data.benefit > this.benefit) {
      this.errormsg = 'Monetary Benefit percentage cannot be more than ' + this.benefit;
      this.saving = false;
    }
    else if (data.nationalId != '' && this.NDIerror != '') {
      this.errormsg = 'Enter valid Nation Id';
    }
    else {
      this.saveBeneficiary(data);
    }
  }

  saveBeneficiary(data) {
    this.errormsg = '';
    this.beneficiaryclient = {
      email: data.email,
      phoneNumber: data.PhoneNumber,
      mobilenumber: data.Mobilenumber,
      title: data.title,
      firstname: data.firstname,
      surname: data.surname,
      passportNo: data.PassportNo,
      nationalId: data.nationalId,
      nationality: data.nationality,
      dob: data.dob,
      gender: data.gender
    };
    this.beneficiary = {
      relationship: data.relationship,
      benefit: data.benefit,
      beneficiaryClient: this.beneficiaryclient
    };

    

    this.clientservice.addBeneficiary(this.clientid.clientid, this.beneficiary).subscribe(res => {
      if (data.bankName || data.accountNo || data.typeOfAccount || data.accountHolderName || data.branch || data.branchCode) {
        this.bank = {
          bankName: data.bankName,
          accountNo: data.accountNo,
          typeOfAccount: data.typeOfAccount,
          accountHolderName: data.accountHolderName,
          branch: data.branch,
          branchCode: data.branchCode
        }
        this.clientservice.SaveClientBankDetails(res.reasonPhrase, this.bank).subscribe(res1 => {
          this.saving = false;
          this.common.openSnackBar('Beneficiary added Successfully', 'Close');
          this.dialogRef.close(true);
        }, err1 => {
          this.saving = false;
        });
      }
      else {
        this.saving = false;
        this.common.openSnackBar('Beneficiary added Successfully', 'Close');
        this.dialogRef.close(true);
      }
    }, err => {
      this.saving = false;
      this.errormsg = err.error.reasonPhrase;
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  validationnationid() {
    this.NDIerror = '';
    var id = this.personFormGroup.get('nationalId').value;
    var gender = this.personFormGroup.get('gender').value;
    var DOB = new Date(this.personFormGroup.get('dob').value);
    var idbirth = id.substring(0, 6);
    var year = DOB.getFullYear();
    var mon: any = DOB.getMonth() + 1;
    var date: any = DOB.getDate();
    var date1;
    if (String(mon).length == 1)
      mon = 0 + String(mon);
    if (String(date).length == 1)
      date = 0 + String(date);
    var date1: any = (String(year).substring(2, 4)) + mon + date;
    if (+date1 != +idbirth) {
      this.NDIerror = 'Enter valid Nation Id';
      this.personFormGroup.get('nationalId').reset();
    }
    else {
      var genderno = id.substring(6, 7);
      if (genderno >= 0 && genderno <= 4) {
        if (gender != 'F') {
          this.personFormGroup.get('nationalId').reset();
          this.NDIerror = 'Enter valid Nation Id';
        }
      }
      else if (genderno >= 5 && genderno <= 9) {
        if (gender != 'M') {
          this.personFormGroup.get('nationalId').reset();
          this.NDIerror = 'Enter valid Nation Id';
        }
      }
      else {
        this.NDIerror = '';
      }
    }
  }
}
