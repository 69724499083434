import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RateTableService {

  constructor(private api: ApiService, private auth: AuthService) { }

  GetPremiumrateListByProduct(productId): Observable<PremiumRates> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<PremiumRates>("/RateTable/list/" + productId, header);
  }

  GetPremiumrate(id): Observable<PremiumRate> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<PremiumRate>("/RateTable/" + id, header);
  }

  editPremiumrate(data: PremiumRate) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut("/RateTable/", data, header);
  }

  addPremiumrate(data: PremiumRate) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/RateTable/", data, header);
  }

  uploadcsv(data, productId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/RateTable/import/" + productId, data, header);
  }

  addClassificationrate(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/RateTable/classificationRate/", data, header);
  }

  editClassificationrate(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut("/RateTable/classificationRate/", data, header);
  }

  GetClassificationrate(type): Observable<ClassificationRates> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ClassificationRates>("/RateTable/classificationRate/list/" + type, header);
  }

  addFlatPremiumRate(data, region, planId, packageId, productId, amount) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/RateTable/flatpremium/" + region + "/" + planId + "/" + packageId + "/" + productId + "/" + amount, data, header);
  }

  GetSingleClassificationrate(id): Observable<ClassificationRate> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ClassificationRate>("/RateTable/classificationRate/"+id, header);
  }

  getFlatPremiumRate(region, planId, packageId, productId, amount): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/RateTable/flatpremium/" + region + "/" + planId + "/" + packageId + "/" + productId + "/" + amount, header);
  }

  getFlatRate(region, planId, packageId, productId, amount, type, age): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/RateTable/flatrate/" + region + "/" + planId + "/" + packageId + "/" + productId + "/" + amount + "/" + type + "/" + age, header);
  }

  getflateratepremium(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/RateTable/flatrate/" + id, header);
  }

  editFlaterate(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut("/RateTable/flatrate/", data, header);
  }
}

export interface PremiumRate {
  id: number;
  age: number;
  male: number;
  female: number;
  other: number;
  type: number;
  productId: number;
}

export interface PremiumRates {
  pageSize: number;
  totalCount: number;
  premiumRates: [PremiumRate[]];
}

export interface ClassificationRate {
  id: number;
  salaryMin: number;
  salaryMax: number;
  rate: number;
  type: number;
  classificationOptions: ClassificationOption[];
}

export interface ClassificationOption {
  value: string;
}

export interface ClassificationRates {
  pageSize: number;
  totalCount: number;
  classificationRates: ClassificationRate[];
}

export interface FlatPremium {
  id?: number;
  region: string;
  planId: number;
  packageId: number;
  productId: number;
  coverAmount: number;
  flatPremiumRate: FlatPremiumRate[];
}

export interface FlatPremiumRate {
  id?: number;
  flatPremiumId: number;
  age: number;
  rate: number;
}
