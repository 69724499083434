import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventModel } from '../models/calendar/Event.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }

  getAllEvents(strDate, endDate) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/Calendar/Events?strDate="+strDate+"&endDate="+endDate, header);
  }

  getEventList(strDate, endDate) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/Calendar/Events/me?strDate="+strDate+"&endDate="+endDate, header);
  }
  getEvent(id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/Calendar/Event/" + id, header);
  }

  addEvent(event: EventModel) {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Calendar/Event", event, header);
  }
  deleteEvent(id) {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/Calendar/Event/"+id, header);
  }
  editEvent(id, event:EventModel) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut("/Calendar/Event/"+id, event, header);
  }
}
