import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { FuList, FuAdd, Fu, license } from '../models/FU/FU';
import { status, resetPassword } from '../models/Account/common';

@Injectable({
  providedIn: 'root'
})

export class VendorService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }

  getVendorList(page): Observable<FuList> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<FuList>("/Vendor/?page=" + page, header);
  }

  addVendor(fu: FuAdd): Observable<FuAdd> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<FuAdd>("/Vendor/", fu, header);
  }

  getVendor(id): Observable<Fu> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Fu>("/Vendor/" + id, header);
  }

  editVendor(fu: Fu, id): Observable<Fu> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<Fu>("/Vendor/" + id, fu, header);
  }

  changeVendorStatus(status: status): Observable<status> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<status>("/Vendor/changestatus/", status, header);
  }

  searchVendor(search): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor?search=" + search, header);
  }

  resetPassword(data: resetPassword): Observable<resetPassword> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<resetPassword>("/Vendor/ResetPassword/", data, header);
  }

  getLicense(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/licence/" + id, header);
  }

  getMyLicense(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/licence/me", header);
  }

  profileImgUpload(data, id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Vendor/ProfileUploadImg/" + id, data, header);
  }

  uploadLicense(vendorid, data: license): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Vendor/UploadLicence/" + vendorid , data, header);
  }

  uploadMyLicense(data: license): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Vendor/UploadLicence/me/", data, header);
  }

  deleteLicense(id, fileId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Vendor/license/"+ id + "/" + fileId, header);
  }

  assignplan(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Vendor/assignplan/", data, header);
  }

  getVendorPlan(id, region): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/VendorPlan/" + id + "/" + region, header);
  }

  getMyPlan(region): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/VendorPlan/me/"+region, header);
  }

  getVendorPlanProduct(vendorId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/VendorPlanProduct/" + vendorId, header);
  }

  getMyPlanProduct(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/VendorPlanProduct/me", header);
  }

  getMyPolicyPlan(PlanTypeId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/vendorpolicyplan/me/" + PlanTypeId, header);
  }

  getVendorPolicyPlan(vendorId, PlanTypeId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/vendorpolicyplan/" + vendorId + '/' + PlanTypeId, header);
  }

  getVendorPlanType(vendorId): Observable<any> {
      let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/vendorPlanType/" + vendorId, header);
  }

  getVendorMyPlanType(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/vendorPlanType/me/", header);
  }

  changevendorplanstatus(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Vendor/changePlanStatus/", data, header);
  }

  getClientCount(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/clientcount/", header);
  }

  getClientCountbyId(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/clientcount/" + id, header);
  }

  getCommissionValue(vendorid): Observable<VendorInfo>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<VendorInfo>("/Vendor/Commission/"+vendorid, header);
  }

  updateCommissionValue(data: VendorInfo){
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Vendor/Commission/", data, header);
  }

  calculatecommission(vendorId, data){
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Payment/vendorcommission/calculate/"+vendorId, data, header);
  }

  AddCommission(data: VendorCommission){
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Payment/vendorcommission", data, header);
  }

  GetMyCommission(id): Observable<VendorCommissions>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<VendorCommissions>("/Payment/vendorcommission/list/" + id, header);
  }

  GetAllCommission(pagenumber, keyword): Observable<VendorCommissions>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<VendorCommissions>("/Payment/vendorcommission/list?page=" + (pagenumber+1) + "&search=" + keyword, header);
  }

  commissionLastPaid(id): Observable<VendorCommission>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<VendorCommission>("/Payment/vendorcommission/lastPaid/" + id, header);
  }

  GetVendorBankDetails(vendorid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/GetvendorBankDetails/" + vendorid, header);
  }

  GetDomainSetting(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/GetDomainSetting/" + id, header);
  }

  UpdateDomainSetting(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Vendor/UpdateDomainSetting", data, header);
  }

  CheckDomainName(domain): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/CheckDomain/" + domain, header);
  }

  getvendoractiveplan(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/VendorActivePlan/me/", header);
  }

  getvendoractiveplanbyid(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/VendorActivePlan/"+ id, header);
  }

  Sendplanrequest(planId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Vendor/VendorPlanRequest/" + planId, header);
  }
}

export interface VendorCommission{
  id: number;
  vendorId: number;
  amount: number;
  paymentMethod: string;
  timestamp: string;
}

export interface VendorCommissions{
  pageSize: number;
  totalCount: number;
  commissions: VendorCommission[];
}

export interface VendorInfo {
  id?: number;
  vendorId: number;
  initialCommission: number;
    onGoingCommission: number;
  domainName?: string;
}
