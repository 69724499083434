import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CorporateService } from '@app/services';
import { finalize } from 'rxjs/operators';

export interface DialogData {
  vendorId: number;
  employerId: number;
}

@Component({
  selector: 'app-import-corporate-employee',
  templateUrl: './import-corporate-employee.component.html',
  styleUrls: ['./import-corporate-employee.component.scss']
})
export class ImportCorporateEmployeeComponent implements OnInit {

  errormsg;
  vendorId;
  employerId;
  csvfileId;
  importflag = false;
  validatingflag = false;
  validatingflag1 = false;
  statusflag = false;
  loadingflag = false;
  status;
  fileuploading;

  constructor(public dialogRef: MatDialogRef<ImportCorporateEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private corporateService: CorporateService) {
    this.vendorId = data['vendorId'];
    this.employerId = data['employerId'];
  }

  ngOnInit() {
    this.getstatus();
  }

  getstatus() {
    this.corporateService.csvstatus(this.employerId).subscribe(res => {
      this.status = res;
      this.statusflag = true;
      this.loadingflag = true;
    }, err => {
        this.loadingflag = true;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  upload(files) {
    this.importflag = true;
    this.fileuploading = true;
    for (let file of files) {
      const formData = new FormData();
      formData.append(file.name, file);
      this.corporateService.ImportEmployee(formData, this.vendorId, this.employerId)
        .pipe(
          finalize(() => {
            this.fileuploading = false;
          })
        )
        .subscribe(res => {
          this.csvfileId = res;
          this.importflag = false;
          this.validatingflag = true;
          this.dialogRef.close();
          this.errormsg = "";
        }, err => {
            console.log(err);
            this.importflag = false;
            this.errormsg = err.error.innerException.InnerException.Message;
        });
    }
  }

  saveemployee() {
    this.validatingflag = false;
    this.validatingflag1 = true;
    this.corporateService.SaveImportEmployee(this.csvfileId, this.employerId).subscribe(res => {
      this.validatingflag1 = false;
      this.dialogRef.close();
    })
  }

  errorreport() {
    this.dialogRef.close(true);
  }

  complete() {
    this.corporateService.Updatecsvstatus(this.employerId).subscribe(res => {
      this.dialogRef.close();
    })
  }
}
