import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-import-fu',
  templateUrl: './import-fu.component.html',
  styleUrls: ['./import-fu.component.css', '../add-fu/add-fu.component.css']
})
export class ImportFuComponent implements OnInit {

  errormsg;

  constructor(public dialogRef: MatDialogRef<ImportFuComponent>) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(form) {
    this.onNoClick();
  }
}
