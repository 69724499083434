import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-edit-bankdetails',
  templateUrl: './edit-bankdetails.component.html',
  styleUrls: ['./edit-bankdetails.component.css']
})
export class EditBankdetailsComponent implements OnInit {

  personFormGroup: FormGroup;
  clientId;
  bankdetails;
  errormsg;
  loading = false;
  edit = true;

  constructor(public _formbuilder: FormBuilder, public clientservice: ClientService, public dialogRef: MatDialogRef<EditBankdetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '', ) {
    this.clientId = data;
  }

  ngOnInit() {
    this.getbankdetails();
  }

  getbankdetails() {
    this.clientservice.GetClientBankDetails(this.clientId.id).subscribe(res => {
      if (res) {
        this.bankdetails = res.bankDetails;
        this.edit = false;
        this.personFormGroup = this._formbuilder.group({
          bankName: [this.bankdetails.bankName, Validators.required],
          accountNo: [this.bankdetails.accountNo, Validators.required],
          typeOfAccount: [this.bankdetails.typeOfAccount, Validators.required],
          accountHolderName: [this.bankdetails.accountHolderName, Validators.required],
          branch: [this.bankdetails.branch, Validators.required],
          branchCode: [this.bankdetails.branchCode, Validators.required]
        });
      }
      else {
        this.edit = true;
        this.personFormGroup = this._formbuilder.group({
          bankName: ['', Validators.required],
          accountNo: ['', Validators.required],
          typeOfAccount: ['', Validators.required],
          accountHolderName: ['', Validators.required],
          branch: ['', Validators.required],
          branchCode: ['', Validators.required]
        });
      }
      this.loading = true;
    })
  }

  onSubmit(data) {
    if (this.edit) {
      this.clientservice.SaveClientBankDetails(this.clientId.id, data).subscribe(res => {
        this.dialogRef.close(true);
      });
    }
    else {
      data.id = this.bankdetails.id;
      this.clientservice.updateClientBankDetails(this.clientId.id, data).subscribe(res => {
        this.dialogRef.close(true);
      });
    }
  }


  cancel() {
    this.dialogRef.close(false);
  }
}
