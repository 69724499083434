import { Component, OnInit, Inject, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, ErrorStateMatcher } from '@angular/material';
import { FormGroup, Validators, FormGroupDirective, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { changePassword } from '../../models/account/common';
import { AccountService } from '../../services/account.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  userdata;
  changePassword: changePassword;
  myForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  errorMsg;
  isenabled = true;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: '', public account: AccountService) {
    this.myForm = this.formBuilder.group({
      existpassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['']
    }, { validator: this.checkPasswords });
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onSubmit(value) {
    this.isenabled = false;
    this.changePassword = {
      oldpassword: value.existpassword,
      newpassword: value.password,
      confirmPassword: value.confirmPassword
    };
    this.account.changePassword(this.changePassword).subscribe(res => {
      this.dialogRef.close(true);
    }, err => {
      if (err.error)
        if (err.error.NewPassword)
          this.errorMsg = err.error.NewPassword[0];

      if (err.error)
        if (err.error.errors)
          this.errorMsg = 'Incorrect old password';

    })
  }

  onChange() {
    this.isenabled = true;
  }

}
