import { Component, OnInit, Input } from '@angular/core';
import { status } from '@app/models';
import { CommonService, StafftService } from '@app/services';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { ConfirmPopupComponent } from '@app/shared/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-staff-profile',
  templateUrl: './staff-profile.component.html',
  styleUrls: ['./staff-profile.component.scss']
})
export class StaffProfileComponent implements OnInit {

  @Input() data;
  statusList = [{ id: 0, value: 'Pending', text: 'Pending' }, { id: 1, value: 'Active', text: 'Active' }, { id: 2, value: 'Inactive', text: 'Inactive' }, { id: 3, value: 'Suspend', text: 'Suspended' }];
  authimageurl;
  fileerror;
  path;
  fileToUpload;
  status: status;
  selectedValue;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private staffservice: StafftService,
    public common: CommonService,
    private auth: AuthService
  ) { }

  ngOnInit() {
  }

  StatusView(index) {
    return this.statusList[index].text;
  }

  onStatus(value) {
    this.status = { id: this.data.id, status: value };
    let name = this.StatusView(value);
    let title = name + ' client ';
    let msg = 'Are you sure you want to change the status to ' + name + '?';
    let func = 'changeStatus';
    this.confirmDialog(title, msg, func);
  }

  // CONFIRM POPUP FUNCTION
  confirmDialog(title, msg, func) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { title: title, msg: msg }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (func) {
          case 'changeStatus': {
            this.changeStatus();
            break;
          }
          default: {
            break;
          }
        }
      }
      
    });
  }

  changeStatus() {
    this.staffservice.changeStaffStatus(this.status).subscribe(res => {
      this.common.openSnackBar('Status updated Successfully', 'Close');
      this.selectedValue = this.status.status;
    });
  }

}
