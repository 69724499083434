import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { VendorService, PlanService, CommonService, ProductService } from '@app/services';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit {

  planForm: FormGroup;
  isLoading: boolean;
  planlist;
  packagelist = [];
  packageproduct = [];
  vendorId;
  vendorregion;
  plantype;
  funeralAmount;
  totalAmount;
  Premium;
  TotalPremium;
  age;
  type;
  packageProducts = [];
  extcoveramount = 0;
  commondetails;
  mmpackageamount;
  pakageerror;
  membertype;
  extsubmitflag = true;

  constructor(
    private _formBuilder: FormBuilder,
    public vendorservice: VendorService,
    public planservice: PlanService,
    public common: CommonService,
    public dialogRef: MatDialogRef<SelectPlanComponent>,
    private productservice: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: ''
  ) { 
    this.vendorId = data['vendorId'];
    this.vendorregion = data['region'];
    this.plantype = data['planType'];
    this.age = data['age'];
    this.type = data['type'];
    this.mmpackageamount = data['totalAmount'];
    this.membertype = data['membertype'];
  }

  ngOnInit() {
    this.isLoading = true;
    this.vendorservice.getVendorPolicyPlan(this.vendorId, this.plantype.id)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(res => {
      this.planlist = res;
    });
    this.planForm = this._formBuilder.group({
      plan: ['', Validators.required],
      package: ['', Validators.required]
    });
    this.common.loadPlanGlobalSettings().subscribe((res) => {
      this.commondetails = res;
      
    });
  }

  getpackage(plan) {
    this.planForm.get('package').reset();
    this.pakageerror = '';
    this.packagelist = [];
    this.packageproduct = [];
    this.planservice.getpackagesbyregion(plan.planId, this.vendorregion).subscribe(res => {
      if (this.type == 3) {
        res.forEach((item, index) => {
          if (item.totalAmount <= this.mmpackageamount)
            this.packagelist.push(item);
        })
        if (this.packagelist.length == 0) {
          this.pakageerror = 'No package avaliable less or equal to the main member package in this plan';
        }
      }
      else {
        this.packagelist = res;
      }
    })  
    if (this.commondetails.childAgeClassificationMax3 < this.age) {
      this.extcoveramount = plan.plan.extcoveramount;
    }
    else {
      if (this.commondetails.childAgeClassificationMin1 < this.age && this.commondetails.childAgeClassificationMax1 > this.age) {
        this.extcoveramount = this.commondetails.childMaxCoverAmount1;
      }
      if (this.commondetails.childAgeClassificationMin2 < this.age && this.commondetails.childAgeClassificationMax2 > this.age) {
        this.extcoveramount = this.commondetails.childMaxCoverAmount2;
      }
      if (this.commondetails.childAgeClassificationMin3 < this.age && this.commondetails.childAgeClassificationMax3 > this.age) {
        this.extcoveramount = this.commondetails.childMaxCoverAmount3;
      }
    }
  }

  getpackageproduct(data) {
    this.extsubmitflag = true;
    this.packageproduct = [];
    this.packageProducts = [];
    var planid = (this.planForm.get('plan').value).planId;
    this.planservice.getPlanPackageProduct(data).subscribe(res => {
      res.forEach((item, index) => {
        this.packageProducts.push({
          packageProductId: item.id
        })
      })
      if (this.membertype == 'extendedmember') {
        res.forEach((item, index) => {
          if (item.packageProduct.product.iseventproduct == 'N') {
            this.productservice.getProduct(item.packageProduct.product.id).subscribe(res1 => {

              res1.productMemberType.forEach((item1, index1) => {
                if (this.membertype == item1.type.toLowerCase()) {//string.toLowerCase( )
                  this.packageproduct.push(item);
                }
              })
              if (res1.subProducts.length > 0) {
                this.packageproduct.push(item);
              }
            });
          }
          if (item.packageProduct.product.iseventproduct == 'Y') {
            this.packageproduct.push(item);
          }
        })
        if (this.packageproduct.length == 0)
          this.extsubmitflag = false;
        else
          this.extsubmitflag = true;
      }
      else {
        this.packageproduct = res;
      }
    })
    
    this.packagelist.forEach((item, index) => {
      if (item.id == data) {
        this.funeralAmount = item.funeralExpense;
        this.totalAmount = item.totalAmount;
      }
    })
    this.common.PackagePremiumCalculation(planid, data, this.vendorregion, this.type, this.age, this.plantype.id, this.membertype).subscribe(res => {
      this.Premium = res;
      this.TotalPremium = res.mainmember;
    })
  }

  planSubmit(data){
    data.TotalPremium = this.TotalPremium;
    data.packageProducts = this.packageProducts;
    data.totalAmount = this.totalAmount;
    this.close(data);
  }

  close(data) {
    this.dialogRef.close(data);
  }

}
