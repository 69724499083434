import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-add-product-option',
  templateUrl: './add-product-option.component.html',
  styleUrls: ['./add-product-option.component.css']
})
export class AddProductOptionComponent implements OnInit {

  productFormGroup: FormGroup;
  isenable = true;
  isloaded = false;
  profileerrormsg;
  productoptionlist;
  ProductValueList;
  productid;
  product;

  constructor(public _formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddProductOptionComponent>, @Inject(MAT_DIALOG_DATA) public data: '', public productservice: ProductService) {
    this.productid = data;
  }

  ngOnInit() {
    this.getProductList();
    this.productFormGroup = this._formBuilder.group({
      prodOption: this._formBuilder.array([])
    })
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getProductList() {
    this.productservice.getProduct(this.productid.id).subscribe(res => {
      this.productoptionlist = res.productOptions;
      let option = this.productFormGroup.get('prodOption') as FormArray;
      this.productoptionlist.forEach((item, index) => {
        option.push(this._formBuilder.group({
          productoptionId: [item.id],
          productoption: ['']
        }))
      });
      this.isloaded = true;
      this.getdata();
    });
  }

  getdata() {
    this.productoptionlist.forEach((item, index) => {
      this.productservice.getProductOptionValue(item.id).subscribe(res => {
        this.ProductValueList = res;
        var i;
        var select = document.getElementById('prodOption' + index) as HTMLSelectElement;
        var label = document.getElementById('prolabel' + index) as HTMLElement;
        label.innerHTML = item.prodOption;
        for (i = select.options.length - 1; i >= 1; i--) {
          select.remove(i);
        }
        this.ProductValueList.forEach((item, index) => {
          var opt = document.createElement("option");
          opt.value = item.id;
          opt.text = item.prodOptionValue;
          select.add(opt, 1);
        });
      });
    });
  }

  getProductValueList(data, id) {
    var select = document.getElementById(id) as HTMLSelectElement;
    this.productservice.getProductOptionValue(data).subscribe(res => {
      this.ProductValueList = res;
      var i;
      for (i = select.options.length - 1; i >= 1; i--) {
        select.remove(i);
      }
      this.ProductValueList.forEach((item, index) => {
        var opt = document.createElement("option");
        opt.value = item.id;
        opt.text = item.prodOptionValue;
        select.add(opt, 1);
      });
    });
  }

  onSubmit(data) {
    var option=[];
    data.prodOption.forEach((item, index) => {
      if (item.productoption != '') {
        option.push(item);
      }
    })
    this.dialogRef.close(option);
  }

}
