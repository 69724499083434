import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-reactivate-plan',
  templateUrl: './reactivate-plan.component.html',
  styleUrls: ['./reactivate-plan.component.css', '../add-fu/add-fu.component.css']
})
export class ReactivatePlanComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ReactivatePlanComponent>) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(form) {
    this.dialogRef.close(true);
  }

}
