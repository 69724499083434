import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Users } from '../models/account/Users';
import { ISearchClient, SearchClient } from '../models/client/client';


@Injectable({
  providedIn: 'root'
})

export class ClaimService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }


  getClaimClient(filter: { name: string } = { name: '' }): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Claim/getclaimclients?search=' + filter.name, header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }

  getMyClaimClient(filter: { name: string } = { name: '' }): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Claim/getmyclaimclients?search=' + filter.name , header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }

  getclientbenefit(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/getclaimbenefit/" + clientId , header);
  }

  checkclientClaim(clientId, watingperiod, planId, packageproductId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/ClientClaim/" + clientId + "/" + watingperiod + "/" + planId + "/" + packageproductId, header);
  }

  saveempclaimrequest(ClaimRequest) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/EmpClaimRequest/", ClaimRequest, header);
  }

  getclaimrequests(page, status, search): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/ClaimRequest/?page=" + (page + 1) + "&search=" + search + '&status=' + status, header);
  }

  getempclaimrequests(page, status, search): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/EmpClaimRequest/?page=" + (page + 1) + "&search=" + search + '&status=' + status, header);
  }

  getvendorbasedEmpclaimrequests(page, status, search): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/GetVendorBasedEmpClaimRequests/?page=" + (page + 1) + "&search=" + search + '&status=' + status, header);
  }
  getvendorbasedclaimrequests(page, status, search): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/GetVendorBasedClaimRequests/?page=" + (page + 1) + "&search=" + search + '&status=' + status, header);
  }

  EmpBenefitClaimRequest(ClaimRequest, productId, planId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/EmpBenefitClaimRequest/" + productId + "/" + planId, ClaimRequest, header);
  }

  approveclaimrequest(claimId, status,  watingperiod, planId, packageproductId, data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/updateClaimRequest/" + claimId + "/" + status + "/" + watingperiod + "/" + planId + "/" + packageproductId, data, header);
  }

  approveempclaimrequest(claimId, status, clientId, watingperiod, planId, packageproductId, data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/updateEmpClaimRequest/" + claimId + "/" + status + "/" + clientId + "/" + watingperiod + "/" + planId + "/" + packageproductId, data, header);
  }

  cancelclaimrequest(claimId, status, data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/updateClaimRequest/" + claimId + "/" + status , data, header);
  }

  cancelempclaimrequest(claimId, productId, status, data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/updateEmpClaimRequest/" + claimId + "/" + productId + "/" + status, data, header);
  }

  getclaimrequest(claimId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/ClaimRequest/"+ claimId, header);
  }

  burialProductClaim(ClaimRequest, flag) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/BurialProductClaim/" + flag, ClaimRequest, header);
  }

  benefitProductClaim(ClaimRequest, planId, productId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/BenefitProductClaim/" + planId + "/" + productId , ClaimRequest, header);
  }

  getclaimrequestbyproductId(clientId, productId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/ClaimRequest/" + clientId + "/" + productId, header);
  }

  getmyclaimrequestbyproductId(productId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/MyClaimRequest/" + productId, header);
  }

  saveclaimdocument(Claimdocument) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/SaveClaimDocument/", Claimdocument, header);
  }

  saveclaimpayment(Claimpayment) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/Claimpayment/", Claimpayment, header);
  }

  getclaimpaymet(claimId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/Claimpayment/" + claimId, header);
  }

  empBurialProductClaim(ClaimRequest, flag) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/EmpBurialProductClaim/" + flag, ClaimRequest, header);
  }

  getmyclaimrequestbyclientId(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/ClaimRequestByClientId/" + clientId, header);
  }
  checkDeathClaim(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Claim/CheckDeathClaim/" + clientId, header);
  }

  saveeventempclaim(ClaimRequest) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Claim/SaveEventEmpClaim/", ClaimRequest, header);
  }

  //#region Event manangement
  getEvenetManagementByOrganizer(id, page, search): Observable<EventManagements> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<EventManagements>('/Claim/eventmanagements/' + id + '?page=' + (page + 1) + '&search=' + search, header);
  }
  getEvenetManagementsByCurrentUser(page, search): Observable<EventManagements> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<EventManagements>('/Claim/eventmanagements/me?page=' + (page + 1) + '&search=' + search, header);
  }
  saveEventManagement(data) {
    const header = this.auth.getAuthHeader();
    return this.api.HttpPost('/Claim/eventmanagements', data, header);
  }
  updateEventManagement(id, data) {
    const header = this.auth.getAuthHeader();
    return this.api.HttpPut('/Claim/eventmanagement/'+id, data, header);
  }
  getEvenetManagement(id): Observable<EventManagement> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<EventManagement>('/Claim/eventmanagement/' + id, header);
  }
  getEvenetManagementByRef(refid): Observable<EventManagement> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<EventManagement>('/Claim/eventmanagement/getbyclaim/' + refid, header);
  }
  getEvenetManagementByClientId(clientid): Observable<EventManagement> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<EventManagement>('/Claim/eventmanagement/getbyclient/' + clientid, header);
  }
  assignSupplier(eventid, supplierid){
    const header = this.auth.getAuthHeader();
    return this.api.HttpPost('/Claim/eventmanagement/' + eventid + '/' + supplierid, {}, header);
  }
  closeEventManagement(id){
    const header = this.auth.getAuthHeader();
    return this.api.HttpPost('/Claim/eventmanagement/close/' + id , {}, header);
  }
  //#endregion
  //#region claim payments
  getAllClaimPayments(page, keyword): Observable<ClaimPayments>{
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<ClaimPayments>('/payment/claim/all?page='+(page+1)+"&search="+keyword, header);
  }
  //#endregion
}


export interface ClaimRequest{
  id?: number;
  productType: string;
  productName: string;
  productId: number;
  claimstatus: number;
  clientId?: number;
  claimSubProduct?: ClaimSubProduct;
  hospitalCashClaim?: hospitalCashClaim;
  claimDocument?: ClaimDocument[];
}


export interface ClaimSubProduct {
  id?: number;
  claimRequestId?: number;
  productId: number;
  amount: number;
  balanceAmount: number;
}

export interface hospitalCashClaim {
  id?: number;
  claimRequestId?: number;
  year: number;
  days: number;
  amount: number;
}

export interface ClaimDocument {
  id?: number;
  claimRequestId?: number;
  docType: string;
  path: string;
}

export interface EventManagements {
  totalCount: number;
  pageSize: number;
  eventManagements: EventManagement[];
}

export interface EventManagement {
  id: number;
  date: string;
  eventTime: string;
  type: string;
  amount: number;
  venue: string;
  message: string;
  clientId: number;
  organizer: string;
  status: string;
  createdOn: string;
  modifiedOn: string;
  products: EventManagementProduct[];
  referenceId?: number;
}

export interface EventManagementProduct {
  id: number;
  productId: number;
  productName: string;
  amount: number;
  supplierId?: number;
}

export interface ClaimPayment {
  id: number;
  claimId: number;
  clientId: number;
  beneficiaryId: number;
  transactionId: string;
  paymentMethod: string;
  amount: number;
  timestamp: string;
}

export interface ClaimPayments {
  totalCount: number;
  pageSize: number;
  claimPayments: ClaimPayment[];
}
