import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Role } from '../models/account/Role';
import { Roles } from '../models/account/Roles';
import { ApiResponse } from '../models/ApiResponse.model';

@Injectable({
  providedIn: 'root'
})

export class RoleService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }

  getRoles(pageno, search): Observable<Roles> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Roles>("/Role?page=" + (pageno + 1) + '&search=' + search, header);
  }

  getRole(id): Observable<Role> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Role>("/Role/" + id, header);
  }

  

  addRole(role): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Role/", role, header);
  }

  updateRole(role, id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Role/" + id, role, header);
  }

  deleteRole(id): Observable<ApiResponse> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<ApiResponse>("/Role/" + id, header);
  }

}
