import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-add-license',
  templateUrl: './add-license.component.html',
  styleUrls: ['./add-license.component.css']
})
export class AddLicenseComponent implements OnInit {

  fileerror;
  path;
  filename;
  ngForm: FormGroup;
  errormsg;
  enabled = false;

  constructor(public account: AccountService, private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddLicenseComponent>) { }

  ngOnInit() {
    this.ngForm = this._formBuilder.group({
      license:['']
    });
  }

  upload(files) {
    if (files.length === 0)
      return;
    const formData = new FormData();
    for (let file of files) {
      formData.append(file.name, file);
    }
    this.fileerror = null;
    this.account.uploadfile(formData).subscribe(res => {
      if (res.succeeded) {
        this.path = res.fileId;
        this.filename = res.filename;
        this.enabled = true;
      }
      else {
        this.fileerror = res.message;
      }
      this.ngForm.controls["license"].reset();
    }, error => {
      this.fileerror = error.error.reasonPhrase
    });
  }

  remove() {
    this.account.deleteDocument(this.path).subscribe(res => {
      this.path = '';
      this.filename = '';
      this.enabled = false;
    });
  }

  onSubmit(data) {
    this.dialogRef.close(this.path);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cancel() {
    if (this.path)
      this.remove();
    this.dialogRef.close();
  }
}
