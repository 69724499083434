import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService, ClientService, AccountService } from '@app/services';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatDatepickerInputEvent } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { address, personal, employer } from '@app/models/client/client';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'form-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class PersonalInfoComponent implements OnInit {

  form: FormGroup;
  @Input() inputdata;
  @Input() plantype;
  @Input() userplantype;
  @Input() doneby;
  @Output() resultoutput = new EventEmitter();
  personaldetails: personal;
  title;
  profileimageUrl = '/assets/images/avatar.png';
  pcitys;
  scitys;
  ecitys;
  psuburbs;
  ssuburbs;
  esuburbs;
  states;
  countrys;
  radioerr;
  ageerror;
  doberror;
  errormsg;
  fileerror;
  path;
  clientsalary = 0;
  clientgender;
  age;
  minage;
  maxage;
  jobs;
  maxDate = new Date();
  address: address[];
  employer: employer;
  salary;
  errormessage;
  NDIerror;
  fileToUpload;
  filename;

  constructor(
    public common: CommonService,
    public account: AccountService,
    public clientservice: ClientService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.title = this.common._title;
    this.countrys = this.common._country;
    this.jobs = this.common._job;
    this.salary = this.common._salary;
    this.initform(this.inputdata);
    this.initdata();
    this.getregion();
  }

  initdata(){
    this.minage = this.plantype.minAge;
    this.maxage = this.plantype.maxAge;
  }

  getregion() {
    this.common.loadRegion().subscribe(res => {
      this.states = res;
    })
  }

  getcity(type, region) {
    this.common.loadCitites(region).subscribe(res => {
      if (type == 'physical') {
        this.pcitys = res;
        (<FormArray>this.form.controls['physicaladdress']).at(0).get('city').reset();
      }
      if (type == 'postal') {
        this.scitys = res;
        (<FormArray>this.form.controls['postaladdress']).at(0).get('city').reset();
      }
      if (type == 'emp') {
        this.ecitys = res;
        ((<FormArray>this.form.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('city').reset();
      }
    })
  }

  getsuburb(type, city) {
    this.common.loadsuburb(city).subscribe(res => {
      if (type == 'physical') {
        this.psuburbs = res;
        (<FormArray>this.form.controls['physicaladdress']).at(0).get('suburb').reset();
        (<FormArray>this.form.controls['physicaladdress']).at(0).get('pincode').reset();
        (<FormArray>this.form.controls['physicaladdress']).at(0).get('strcode').reset();
      }
      if (type == 'postal') {
        this.ssuburbs = res;
        (<FormArray>this.form.controls['postaladdress']).at(0).get('suburb').reset();
        (<FormArray>this.form.controls['postaladdress']).at(0).get('pincode').reset();
        (<FormArray>this.form.controls['postaladdress']).at(0).get('strcode').reset();
      }
      if (type == 'emp') {
        this.esuburbs = res;
        ((<FormArray>this.form.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('suburb').reset();
        ((<FormArray>this.form.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('pincode').reset();
        ((<FormArray>this.form.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('strcode').reset();
      }
    })
  }

  getcode(type, value) {
    if (type == 'physical') {
      this.psuburbs.forEach((item, index) => {
        if (item.suburb == value) {
          (<FormArray>this.form.controls['physicaladdress']).at(0).get('pincode').setValue(item.bxCode);
          (<FormArray>this.form.controls['physicaladdress']).at(0).get('strcode').setValue(item.strCode);
        }
      });

    }
    if (type == 'postal') {
      this.ssuburbs.forEach((item, index) => {
        if (item.suburb == value) {
          (<FormArray>this.form.controls['postaladdress']).at(0).get('pincode').setValue(item.bxCode);
          (<FormArray>this.form.controls['postaladdress']).at(0).get('strcode').setValue(item.strCode);
        }
      });
    }
    if (type == 'emp') {
      this.esuburbs.forEach((item, index) => {
        if (item.suburb == value) {
          ((<FormArray>this.form.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('pincode').setValue(item.bxCode);
          ((<FormArray>this.form.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('strcode').setValue(item.strCode);
        }
      });
    }
  }

  manualcheck() {
    this.errormessage = this.common.manualcheck(this.form);
  }

  initform(data) {
    if (data.surname) {
      if (data.dob == '0001-01-01T00:00:00')
        data.dob = '';
      this.form = this._formBuilder.group({ 
        title: [data.title, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        firstname: [data.firstname, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        surname: [data.surname, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        dob: [data.dob, Validators.required],
        email: [data.email, Validators.compose([Validators.required, Validators.email, Validators.pattern('^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$')])],
        passportno: [data.passportNo, Validators.compose([Validators.pattern('^[a-zA-Z0-9]*$')])],
        nationalId: [data.nationalId, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)])],
        nationality: [data.nationality, Validators.pattern('[a-zA-Z ]*$')],
        cell: [data.mobilenumber, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        phoneNumber: [data.phonenumber, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        physicaladdress: this._formBuilder.array([this.formaddress(true, data)]),
        postaladdress: this._formBuilder.array([this.formaddress(false, data)]),
        employerdata: this._formBuilder.array([this.formemployer(data)]),
        age: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        gender: [data.gender],
        profile: [''],
      })
      this.showage(data.dob);
    }
    else {
      this.form = this._formBuilder.group({
        title: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        firstname: [data.firstname, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        surname: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        dob: ['', Validators.required],
        email: [data.email, Validators.compose([Validators.required, Validators.email, Validators.pattern('^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$')])],
        passportno: ['', Validators.compose([Validators.pattern('^[a-zA-Z0-9]*$')])],
        nationalId: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)])],
        nationality: ['', Validators.pattern('[a-zA-Z ]*$')],
        cell: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        phoneNumber: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        physicaladdress: this._formBuilder.array([this.formaddress(true, null)]),
        postaladdress: this._formBuilder.array([this.formaddress(false, null)]),
        employerdata: this._formBuilder.array([this.formemployer(null)]),
        age: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        gender: [''],
        profile: [''],
      })
    }
  }

  showage(date) {
    this.age = this.common.calculateage(date);
    this.form.get('age').setValue(this.age);
  }

  formaddress(primary, data) {
    let result = data
    if (result && result.addresses != '') {
      for (let key in result.addresses) {
        let value = result.addresses[key].isPrimary;
        if (value == primary) {
          if (value) {
            this.loadcitys('physical', result.addresses[key].address.state);
            this.loadsuburb('physical', result.addresses[key].address.city);
          }
          if (!value) {
            this.loadcitys('postal', result.addresses[key].address.state);
            this.loadsuburb('postal', result.addresses[key].address.city);
          }
          return this._formBuilder.group({
            street: [result.addresses[key].address.street],
            suburb: [result.addresses[key].address.suburb],
            city: [result.addresses[key].address.city],
            state: [result.addresses[key].address.state],
            country: [result.addresses[key].address.country],
            pincode: [result.addresses[key].address.pincode, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])],
            strcode: [result.addresses[key].address.strcode, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])]
          });
        }
      }
    }
    return this._formBuilder.group({
      street: [''],
      suburb: [''],
      city: [''],
      state: [''],
      country: [''],
      pincode: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])],
      strcode: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])]
    });
  }

  formemployer(data) {
    let result = data;
    if (result && result.employer != null) {
      return this._formBuilder.group({
        employerName: [result.employer.employerName, Validators.pattern('[a-zA-Z ]*$')],
        phone: [result.employer.phone, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        salary: [result.employer.salary],
        job: [result.employer.incl],
        employeraddress: this._formBuilder.array([this.formempaddress(true, result.employer)])
      });
    }
    else {
      return this._formBuilder.group({
        employerName: ['', Validators.pattern('[a-zA-Z ]*$')],
        phone: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        salary: [''],
        job: [''],
        employeraddress: this._formBuilder.array([this.formempaddress(true, '')]),
      });
    }
  }

  formempaddress(primary, data) {
    let result = data
    if (result && result.address != null) {
      return this._formBuilder.group({
        street: [result.address.street],
        suburb: [result.address.suburb],
        city: [result.address.city],
        state: [result.address.state],
        country: [result.address.country],
        pincode: [result.address.pincode, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])],
        strcode: [result.address.strcode, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])]
      });
    }
    return this._formBuilder.group({
      street: [''],
      suburb: [''],
      city: [''],
      state: [''],
      country: [''],
      pincode: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])],
      strcode: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])]
    });
  }

  loadcitys(type, region) {
    this.common.loadCitites(region).subscribe(res => {
      if (type == 'physical') {
        this.pcitys = res;
      }
      if (type == 'postal') {
        this.scitys = res;
      }
      if (type == 'emp') {
        this.ecitys = res;
      }
    })
  }

  loadsuburb(type, city) {
    this.common.loadsuburb(city).subscribe(res => {
      if (type == 'physical') {
        this.psuburbs = res;
      }
      if (type == 'postal') {
        this.ssuburbs = res;
      }
      if (type == 'emp') {
        this.esuburbs = res;
      }
    })
  }

  phyaddres(value) {
    var address: FormGroup = ((<FormArray>this.form.controls['physicaladdress']).at(0)) as FormGroup;
    if (value) {
      this.ssuburbs = this.psuburbs;
      this.scitys = this.pcitys;
      (<FormArray>this.form.controls['postaladdress']).at(0).get('country').setValue(address.get('country').value);
      (<FormArray>this.form.controls['postaladdress']).at(0).get('state').setValue(address.get('state').value);
      (<FormArray>this.form.controls['postaladdress']).at(0).get('city').setValue(address.get('city').value);
      (<FormArray>this.form.controls['postaladdress']).at(0).get('suburb').setValue(address.get('suburb').value);
      (<FormArray>this.form.controls['postaladdress']).at(0).get('street').setValue(address.get('street').value);
      (<FormArray>this.form.controls['postaladdress']).at(0).get('pincode').setValue(address.get('pincode').value);
      (<FormArray>this.form.controls['postaladdress']).at(0).get('strcode').setValue(address.get('strcode').value);
    }
    else {
      (<FormArray>this.form.controls['postaladdress']).at(0).get('country').reset();
      (<FormArray>this.form.controls['postaladdress']).at(0).get('state').reset();
      (<FormArray>this.form.controls['postaladdress']).at(0).get('city').reset();
      (<FormArray>this.form.controls['postaladdress']).at(0).get('suburb').reset();
      (<FormArray>this.form.controls['postaladdress']).at(0).get('street').reset();
      (<FormArray>this.form.controls['postaladdress']).at(0).get('pincode').reset();
      (<FormArray>this.form.controls['postaladdress']).at(0).get('strcode').reset();
    }
  }

  calculateage(type: string, event: MatDatepickerInputEvent<Date>) {
    this.form.get('age').reset();
    this.age = this.common.calculateage(event.value);
    if (this.age >= this.minage && this.age < this.maxage) {
      this.form.get('age').setValue(this.age);
      this.ageerror = '';
      this.doberror = '';
    }
    else {
      this.ageerror = "Age must be more than or equal to " + this.minage + " and less than " + this.maxage;
      this.doberror = "Enter a valid date of birth";
    }
  }

  submit(data){
    if (data.gender) {
      this.radioerr = '';
      let physicaladdressid;
      let postaladressid;
      if (this.inputdata.addresses !== '') {
        // tslint:disable-next-line: forin
        for (let key in this.inputdata.addresses) {
          const value = this.inputdata.addresses[key].isPrimary;
          // tslint:disable-next-line: triple-equals
          if (value == true) {
            physicaladdressid = this.inputdata.addresses[key].addressId;
          }
          // tslint:disable-next-line: triple-equals
          if (value == false) {
            postaladressid = this.inputdata.addresses[key].addressId;
          }
        }
      }

      this.address = [{
        id: physicaladdressid,
        street: data.physicaladdress[0].street,
        suburb: data.physicaladdress[0].suburb,
        city: data.physicaladdress[0].city,
        state: data.physicaladdress[0].state,
        country: data.physicaladdress[0].country,
        pincode: data.physicaladdress[0].pincode,
        strcode: data.physicaladdress[0].strcode,
        isPrimary: true
      }];

      if (data.postaladdress[0].street || data.postaladdress[0].suburb || data.postaladdress[0].city || data.postaladdress[0].state || data.postaladdress[0].country || data.postaladdress[0].pincode) {
        this.address.push({
          street: data.postaladdress[0].street,
          suburb: data.postaladdress[0].suburb,
          city: data.postaladdress[0].city,
          state: data.postaladdress[0].state,
          country: data.postaladdress[0].country,
          pincode: data.postaladdress[0].pincode,
          strcode: data.postaladdress[0].strcode,
          isPrimary: false
        });
      }
      if (postaladressid !== '') {
        this.address.forEach((item, index) => {
          if (index == 1 && item.isPrimary == false){
            this.address[index].id = postaladressid;
          }
        });
      }

      this.employer = {
        employerName: data.employerdata[0].employerName,
        phone: data.employerdata[0].phone,
        incl: data.employerdata[0].job,
        salary: data.employerdata[0].salary
      }
      if (data.employerdata[0].salary == '')
        this.employer.salary = 0;
      if (data.employerdata[0].employeraddress[0].street || data.employerdata[0].employeraddress[0].suburb || data.employerdata[0].employeraddress[0].city || data.employerdata[0].employeraddress[0].state || data.employerdata[0].employeraddress[0].country || data.employerdata[0].employeraddress[0].pincode) {
        this.employer.address = {
          street: data.employerdata[0].employeraddress[0].street,
          suburb: data.employerdata[0].employeraddress[0].suburb,
          city: data.employerdata[0].employeraddress[0].city,
          state: data.employerdata[0].employeraddress[0].state,
          country: data.employerdata[0].employeraddress[0].country,
          pincode: data.employerdata[0].employeraddress[0].pincode,
          strcode: data.employerdata[0].employeraddress[0].strcode,
          isPrimary: true
        };
      }

      this.personaldetails = {
        title: data.title,
        firstname: data.firstname,
        surname: data.surname,
        dob: data.dob,
        email: data.email,
        gender: data.gender,
        mobilenumber: data.cell,
        nationality: data.nationality,
        passportNo: data.passportno,
        nationalId: data.nationalId,
        phoneNumber: data.phoneNumber,
        addresses: this.address,
        employer: this.employer,
        profileImgPath: this.path
      };

      this.clientgender = data.gender;
      this.clientsalary = data.employerdata[0].salary;
      if (this.doneby === 'vendor') {
        this.clientservice.editclient(this.personaldetails, this.inputdata.id).subscribe(res => {
          this.common.openSnackBar('Personal details saved', 'Close');
          this.resultoutput.emit(true);
        }, err => { });
      } else {
        this.clientservice.addPersonal(this.personaldetails).subscribe(res => {
          this.common.openSnackBar('Personal details saved', 'Close');
          this.resultoutput.emit(true);
        }, err => { });
      }
    } else {
      this.radioerr = 'This field is required';
    }
  }

  validationnationid() {
    this.NDIerror = '';
    var id = this.form.get('nationalId').value;
    if (id && id != '') {
      var gender = this.form.get('gender').value;
      var DOB = new Date(this.form.get('dob').value);
      var idbirth = id.substring(0, 6);
      var year = DOB.getFullYear();
      var mon: any = DOB.getMonth() + 1;
      var date: any = DOB.getDate();
      var date1;
      if (String(mon).length == 1)
        mon = 0 + String(mon);
      if (String(date).length == 1)
        date = 0 + String(date);
      var date1: any = (String(year).substring(2, 4)) + mon + date;
      if (+date1 != +idbirth) {
        this.NDIerror = 'Enter valid National Id';
        this.form.get('nationalId').reset();
      }
      else {
        var genderno = id.substring(6, 7);
        if (genderno >= 0 && genderno <= 4) {
          if (gender != 'F') {
            this.form.get('nationalId').reset();
            this.NDIerror = 'Enter valid National Id';
          }
        }
        else if (genderno >= 5 && genderno <= 9) {
          if (gender != 'M') {
            this.form.get('nationalId').reset();
            this.NDIerror = 'Enter valid National Id';
          }
        }
        else {
          this.NDIerror = '';
        }
      }
    }
  }

  profileupload(file: FileList, files) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.profileimageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.account.uploadimage(formData).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
          this.filename = res.filename;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {

        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  get physicaladdress(){
    return this.form.get('physicaladdress') as FormArray;
  }

  get postaladdress(){
    return this.form.get('postaladdress') as FormArray;
  }

  get employerdata(){
    return this.form.get('employerdata') as FormArray;
  }

  get employeraddress(){
    var employerdata = this.form.get('employerdata') as FormArray;
    return employerdata.at(0).get('employeraddress') as FormArray;
  }
}
