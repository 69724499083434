import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { VendorService, CommonService } from '@app/services';
import { ConfirmPopupComponent } from '@app/shared/confirm-popup/confirm-popup.component';
import { status } from '@app/models';
import { AuthService } from '@app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styleUrls: ['./vendor-profile.component.scss']
})
export class VendorProfileComponent implements OnInit {

  @Input() data;
  statusList = [{ id: 0, value: 'Pending', text: 'Pending' }, { id: 1, value: 'Active', text: 'Active' }, { id: 2, value: 'Inactive', text: 'Inactive' }, { id: 3, value: 'Suspend', text: 'Suspended' }];
  imageURL = '/assets/images/avatar.png';
  authimageURL;
  fileToUpload;
  fileerror;
  path;
  filename;
  status: status;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private vendorService: VendorService, 
    public common: CommonService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.authimageURL = this.auth.imageURL;
  }

  StatusView(index) {
    return this.statusList[index].text;
  }

  onStatus(value) {
    this.status = { id: this.data.id, status: value };
    let name = this.StatusView(value);
    let title = name + ' FU ';
    let msg = 'Are you sure you want to change the status of this undertaker to ' + name + ' ?';
    let func = 'changeStatus';
    this.confirmDialog(title, msg, func);
  }

  confirmDialog(title, msg, func) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { title: title, msg: msg }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (func) {
          case 'changeStatus': {
            this.changeStatus();
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

  changeStatus() {
    this.vendorService.changeVendorStatus(this.status).subscribe(() => {
      this.data.status = this.status.status;
      this.common.openSnackBar('Status updated Successfully', 'Close');
    });
  }

  profileupload(file: FileList, files, userId) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.vendorService.profileImgUpload(formData, userId).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
          this.filename = res.filename;
          this.imageURL = this.auth.imageURL + this.path;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  onItemSelected(item, type) {
    this.router.navigate([item, type, this.data.id]);
  }

  get getImageURL(){
    if (this.data.profileImgPath)
      this.imageURL = this.authimageURL + this.data.profileImgPath;
    
    return this.imageURL
  }

}
