import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { ApiService } from './api.service';
import { plans } from '../models/plan/plan';
import { UserAddress } from '../models/account/User';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private api: ApiService, private auth: AuthService) { }

  registerVendor(data: RegisterVendor) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Register/vendor", data, header);
  }

  registerClient(data: RegisterClient){
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Register/client", data, header);
  }

  registerCorporate(data: RegisterEmployer){
    let header = this.auth.getAuthHeader();
    if(data.vendorId == 0){
      return this.api.HttpPost("/Register/ApplyEmployer", data, header);
    }
    return this.api.HttpPost("/Register/employer", data, header);
  }

  searchVendor(keyword): Observable<VendorList> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<VendorList>("/Register/search/vendor?search=" + keyword, header);
  }

  VendorUserPlans(userid): Observable<plans> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<plans>("/Register/vendor/plans/" + userid, header);
  }

  GetProfileVendor(userid): Observable<VendorUser>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<VendorUser>("/Register/vendor/profile/" + userid, header);
  }

  GetDomainSetting(id): Observable<any>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Register/GetDomainSetting/" + id, header);
  }

  saveimmediateclaim(data, clientId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut("/Utility/EditClient/" + clientId, data, header);
  }

  savedeceasedclient(clientId, data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Utility/deceasedmember/" + clientId, data, header);
  }

  getmyvendor(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Utility/getmyvendor/" + clientId, header);
  }

  getVendorPlan(id, region): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Utility/VendorPlan/" + id + "/" + region, header);
  }

  getpackagesbyregion(planId, region): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Utility/PlanPackages/" + planId + "/" + region, header);
  }

  getPlanPackageProduct(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Utility/PlanPackagesProduct/" + id, header);
  }

  saveclientorder(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Utility/saveclientorder/", data, header);
  }

  Getclientorder(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Utility/getclientorder/" + clientId, header);
  }

  Sendinvoice(clientId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Utility/immediateclaim/" + clientId, header);
  }

  AddStartStatus(clientid, status): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Utility/AddStartStatus/" + clientid, status, header);
  }

  getMyClient(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Utility/" + id, header);
  }
  checkCompanyName(companyName): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Register/CheckCompanyName/"+companyName, header);
  }
}

export interface RegisterVendor {
  firstname: string,
  surname: string,
  email: string,
  phoneNumber: string
}
export interface VendorUser {
  id: number;
  firstname: string;
  email: string;
  company: string;
  phoneNumber: string;
  profileImgPath: string;
  shortAddress: string;
  addresses: UserAddress[];
  domainName: string;
}
export interface VendorList {
  users: VendorUser[];
  totalCount: number;
}

export interface RegisterClient{
  firstname: string;
  surname: string;
  email: string;
  phoneNumber: string;
  vendorId: number;
}


export interface RegisterEmployer{
  firstName: string;
  email: string;
  phoneNumber: string;
  vendorId: number;
}
