import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from 'angular-calendar';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import moment from 'moment-timezone';
import { ClientService } from '@app/services';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-change-inceptiondate',
  templateUrl: './change-inceptiondate.component.html',
  styleUrls: ['./change-inceptiondate.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ChangeInceptiondateComponent implements OnInit {

  inceptionDate = new FormControl(moment());
  myForm: FormGroup;
  clientdata;
  errormsg;

  constructor(public _formbuilder: FormBuilder, public dialogRef: MatDialogRef<ChangeInceptiondateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '', public clientservice: ClientService, ) {
    this.clientdata = data;
  }

  ngOnInit() {
    this.myForm = this._formbuilder.group({
      dateOfDeduction: [String(this.clientdata.dateOfDeduction), Validators.required],
      startMonth: [String(this.clientdata.startMonth), Validators.required]
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onSubmit(data) {
    this.errormsg = "";
    var clientPlan = {
      planId: 0,
      inceptionDate: new Date(),
      dateOfDeduction: data.dateOfDeduction,
      startMonth: data.startMonth,
      typeOfPayment: 0,
      status: 1
    };
    var currentdate = new Date();
    var month = data.startMonth - 1;
    var date = +data.dateOfDeduction; 
    var day: Date;
    day = new Date(currentdate.getFullYear(), month, date);
    if (currentdate > day) {
      day = new Date(currentdate.getFullYear() + 1, month, date);
    }
    if (currentdate < day)
      var mondiff = this.monthDiff(
        new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate()),
        new Date(day.getFullYear(), day.getMonth(), day.getDate())
      );
    clientPlan.inceptionDate = day;
    var inceptionDate: Date = new Date(clientPlan.inceptionDate);

    var diff = Math.abs(inceptionDate.getTime() - currentdate.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    if (diffDays > 30) {
      this.errormsg = "Cant change the inception date after early given inception date due date over";
    }
    else {
      if (mondiff > 3) {
        this.errormsg = "Enter a valid date and month for date of Deduction";
      }
      else {
        this.clientservice.updateinceptiondate(clientPlan, this.clientdata.clientid).subscribe(res => {
          this.dialogRef.close(true);
        })
      }
    }
  }

  monthDiff(d1, d2) {
    var diff = moment([d2.getFullYear(), d2.getMonth(), d2.getDate()]).diff(moment([d1.getFullYear(), d1.getMonth(), d1.getDate()]), 'months', true);
    var value = Math.round(diff * 10) / 10;
    return value;
  }

}
