import { Injectable } from '@angular/core';
import { status, resetPassword } from '../models/account/common';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }

  resetPassword(data: resetPassword): Observable<resetPassword> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<resetPassword>("/Employee/ResetPassword/", data, header);
  }
}
