import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-claimdecline',
  templateUrl: './claimdecline.component.html',
  styleUrls: ['./claimdecline.component.css']
})
export class ClaimdeclineComponent implements OnInit {

  formGroup;
  title;

  constructor(public dialogRef: MatDialogRef<ClaimdeclineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
    public _formBuilder: FormBuilder,
    public dialog: MatDialog) {
    this.title = data
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      reason: ["", Validators.required]
    });
  }

  onSubmit(data) {
    this.formGroup.reset();
    this.dialogRef.close(data);
  }

  cancel(): void {
    this.formGroup.reset();
    this.dialogRef.close('');
  }

  onNoClick() {}
}
