import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '@app/services';

@Component({
  selector: 'app-employeer-employee-submember',
  templateUrl: './employeer-employee-submember.component.html',
  styleUrls: ['./employeer-employee-submember.component.scss']
})
export class EmployeerEmployeeSubmemberComponent implements OnInit {

  @Input() data;
  @Input() others;
  @Output() render = new EventEmitter();
  isloaded=false;
  imageURL = '';
  fileToUpload;
  fileerror;
  path;
  filename;

  constructor(public router:Router, public clientservice:ClientService) { }

  ngOnInit() {
    this.imageURL=this.others.imageURL;
   
  }
  edit(path, id) {
    this.router.navigate([path, this.others.employeeId, id])
  }

  profileupload(file: FileList, files, userId) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.clientservice.profileImgUpload(formData, userId).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
          this.filename = res.filename;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }
}
