import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Users } from '../models/account/Users';
import { ISearchClient, SearchClient } from '../models/client/client';
import { beneficiary, personal, submember, extendedmember, deceasedmember, clientservicetype, ClientAssignedPlan, ClientPackage } from '../models/client/client';
import { status, resetPassword } from '../models/account/common';
import { ClientPlan } from './plan.service';

@Injectable({
  providedIn: 'root'
})

export class ClientService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }

  //*************** CLIENT GET*********************//
  getClients(pageno, search?): Observable<Users> {
    let header = this.auth.getAuthHeader();
    if (search)
      return this.api.HttpGet<Users>("/Client?page=" + pageno + '&search=' + search, header);
    else
      return this.api.HttpGet<Users>("/Client?page=" + pageno, header);
  }

  getAllClients(pageno, status, search?): Observable<AllClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<AllClients>("/Client/all?page=" + pageno + '&search=' + search + '&status=' + status, header);
  }

  getVendorClients(vendorid, pageno, status, search?): Observable<AllClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<AllClients>("/Client/vendor/" + vendorid + "?page=" + pageno + '&search=' + search + '&status=' + status, header);
  }

  getMyClients(pageno, status, search?): Observable<AllClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<AllClients>("/Client/me?page=" + pageno + '&search=' + search + '&status=' + status, header);
  }

  getMyClient(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/me/" + id, header);
  }

  getVendorClient(vendorid, clientid): Observable<Users> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Users>("/Client/" + vendorid + "/" + clientid, header);
  }

  getClient(clientid): Observable<Users> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Users>("/Client/" + clientid, header);
  }

  getmyvendor(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getmyvendor/", header);
  }

  getclientvendor(clientid): Observable<any> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>('/Client/getmyvendor/' + clientid, header);
  }

  getclientsbystatus(filter: { name: string } = { name: '' }, page = 1, status): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Client/getclientsbystatus?page=1&search=' + filter.name + '&status=' + status, header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }

  getmyclientsbystatus(filter: { name: string } = { name: '' }, page = 1, status): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Client/getmyclientsbystatus?page=1&search=' + filter.name + '&status=' + status, header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }

  //*************** CLIENT ADD*********************//

  addClient(client): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/", client, header);
  }

  addClientbySA(client, vendorId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/addclient/" + vendorId, client, header);
  }

  addPersonal(client: personal): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Account/myinfo/", client, header);
  }

  editclient(data, clientid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Client/EditClient/" + clientid, data, header);
  }

  getpersonaldetails(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Account/me/", header);
  }

  //*************** CLIENT StartStatus*********************//

  AddStartStatus(clientid, status): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/AddStartStatus/" + clientid, status, header);
  }

  getstartstatus(clientid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getstartStatus/" + clientid, header);
  }

  //*************** CLIENT SEARCH*********************//

  clientsearch(filter: { name: string } = { name: '' }, page = 1): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Client/me?page=1&search=' + filter.name, header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }

  clientallsearch(filter: { name: string } = { name: '' }, page = 1): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Client?page=1&search=' + filter.name, header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }

  //*************** CLIENT OTHERS*********************//

  changeStatus(status: status): Observable<status> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<status>("/Client/changestatus/", status, header);
  }

  StatusChange(memberId, status: status): Observable<status> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<status>("/Client/member/statuschange/" + memberId, status, header);
  }

  resetPassword(data: resetPassword): Observable<resetPassword> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<resetPassword>("/Client/ResetPassword/", data, header);
  }

  profileImgUpload(data, id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/ProfileUploadImg/" + id, data, header);
  }

  deacesedprofileImgUpload(data, id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/DeceasedProfileUploadImg/" + id, data, header);
  }

  getclientpremiumrate(age, gender, plantype, productId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getclientpremiumrate/" + age + "/" + gender + "/" + plantype + "/" + productId, header);
  }

  getRegionlclassification(salary, region, plantype): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getRegionlclassification/" + salary + "/" + region + "/" + plantype, header);
  }

  reinstatement(plainId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/reinstatement/" + plainId, header);
  }

  getdocument(clientid) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/document/" + clientid, header);
  }

  //***************** SUBMEMBER *************//

  getSubmemberslist(clientid, pageno?): Observable<any> {
    let header = this.auth.getAuthHeader();
    if(pageno)
      return this.api.HttpGet<any>("/Client/submembers/" + clientid + "?page=" + pageno, header);
    else
      return this.api.HttpGet<any>("/Client/submembers/" + clientid, header);
  }
  getSubmember(submemberid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/submember/me/" + submemberid, header);
  }

  getSubmemberByClientid(clientid, submemberid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/submember/" + clientid + "/" + submemberid, header);
  }

  getsubmemberrelationship(clientid): Observable<submember> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<submember>("/Client/submembersrelationship/" + clientid, header);
  }

  getmysubmemberrelationship(): Observable<submember> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<submember>("/Client/submembersrelationship/me/", header);
  }

  addMysubmember(data: submember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/submember/me", data, header);
  }

  addSubmember(clientid, data: submember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/submember/" + clientid, data, header);
  }

  updateSubmember(clientid, submemberid, data: submember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Client/submember/" + clientid + "/" + submemberid, data, header);
  }

  deleteSubmember(clientid, submemberid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Client/submember/" + clientid + "/" + submemberid, header);
  }

  deleteMySubmember( submemberid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Client/submember/me/" + submemberid, header);
  }

  //********************** BENEFICIARY ***************//

  getBeneficiaryByClientid(clientid, beneficiaryid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/beneficiary/" + clientid + "/" + beneficiaryid, header);
  }

  getBeneficiary(clientid): Observable<Users> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Users>("/Client/beneficiary/" + clientid, header);
  }

  getMyBeneficiarys(): Observable<Users> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Users>("/Client/beneficiary/me/", header);
  }

  getMyBeneficiary(benid): Observable<Users> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Users>("/Client/beneficiary/me/"+ benid  , header);
  }

  addBeneficiary(clientid, data: beneficiary): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/beneficiary/" + clientid, data, header);
  }

  addMyBeneficiary(data: beneficiary): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/beneficiary/me/", data, header);
  }

  updateBeneficiary(clientid, submemberid, data: beneficiary): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Client/beneficiary/" + clientid + "/" + submemberid, data, header);
  }

  deleteBeneficiary(clientid, submemberid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Client/beneficiary/" + clientid + "/" + submemberid, header);
  }

  //************************** EXTENDED MEMBER ********************//

  getExtendedmemberslist(clientid, pageno?): Observable<any> {
    let header = this.auth.getAuthHeader();
    if (pageno)
      return this.api.HttpGet<any>("/Client/extendedmembers/" + clientid + "?page=" + pageno, header);
    else
      return this.api.HttpGet<any>("/Client/extendedmembers/" + clientid, header);
  }

  getMyExtendedmemberslist(pageno?): Observable<any> {
    let header = this.auth.getAuthHeader();
    if (pageno)
      return this.api.HttpGet<any>("/Client/extendedmembers/me?page=" + pageno, header);
    else
      return this.api.HttpGet<any>("/Client/extendedmembers/", header);
  }

  getExtendedmember(clientid, id): Observable<extendedmember> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<extendedmember>("/Client/extendedmember/" + clientid + "/" + id, header);
  }

  getMyExtendedmember(id): Observable<extendedmember> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<extendedmember>("/Client/extendedmember/me/" + id, header);
  }

  getextendedmembersrelationship(clientid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/extendedmembersrelationship/" + clientid, header);
  }

  getMyextendedmembersrelationship(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/extendedmembersrelationship/me/", header);
  }

  getextendedmembercount(clientid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/extendedmemberscount/" + clientid, header);
  }

  getMyextendedmembercount(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/extendedmemberscount/me", header);
  }

  addExtendedmember(clientid, data: extendedmember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/extendedmember/" + clientid, data, header);
  }

  addMyExtendedmember(data: extendedmember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/extendedmember/me", data, header);
  }

  updateExtendedmember(clientid, id, data: extendedmember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Client/extendedmember/" + clientid + "/" + id, data, header);
  }

  updateMyExtendedmember(id, data: extendedmember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Client/extendedmember/me/" + id, data, header);
  }

  getextendedmemberplan(extid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getextmemberplan/" + extid, header);
  }

  ExtendedChangePlan(clientId, status, mainmid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/ExtendedmemberChangePlanRequest/" + clientId + "/" + status + "/" + mainmid, header);
  }

  deleteExtendedmember(clientid, memberid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Client/extendedmember/" + clientid + "/" + memberid, header);
  }

  deleteMyExtendedmember(memberid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Client/extendedmember/me/" + memberid, header);
  }

  upgradeplan(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/saveextendedmemberplan", data, header);
  }


  //************************** DECEASED MEMBER ********************//

  addDeceasedmember(clientid, data: deceasedmember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/deceasedmember/" + clientid, data, header);
  }

  addMyDeceasedmember(data: deceasedmember): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/deceasedmember/me", data, header);
  }

  getDeceasedmember(clientid): Observable<deceasedmember> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<deceasedmember>("/Client/deceasedmember/" + clientid, header);
  }

  getMyDeceasedmember(): Observable<deceasedmember> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<deceasedmember>("/Client/deceasedmember/me", header);
  }

  Sendinvoice(clientId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<deceasedmember>("/Client/immediateclaim/" + clientId, header);
  }

  //************************** CLIENT SERVICE TYPE ********************//

  saveCilentServiceType(clientid, data: clientservicetype): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/clientservicetype/" + clientid, data, header);
  }
  saveMyCilentServiceType(data: clientservicetype): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/clientservicetype/me", data, header);
  }

  getCilentServiceType(clientid): Observable<clientservicetype> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<clientservicetype>("/Client/clientservicetype/" + clientid, header);
  }

  getMyCilentServiceType(): Observable<clientservicetype> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<clientservicetype>("/Client/clientservicetype/me", header);
  }

  //********************* CLIENT PLAN TYPE***********************//

  getPlanType(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/ClientPlanType/me/", header);
  }

  getPlanTypeByclientid(clientid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/ClientPlanType/" + clientid, header);
  }

  addPlanType(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/ClientPlanType/me?planTypeId=" + data, data, header);
  }

  savePlanByClientId(plantypeId, clientid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/ClientPlanType/" + clientid + "?planTypeId=" + plantypeId, plantypeId, header);
  }

  //************************** CLIENT PLAN ********************//

  saveCilentPlan(clientid, data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/saveclientplan/" + clientid, data, header);
  }
  saveMyPlan(data: ClientPlan): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/saveclientplan/me", data, header);
  }
  getCilentPlan(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getclientplan/" + clientId, header);
  }
  getMyPlan(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getclientplan/me", header);
  }


    //************************** CLIENT PREMIUM ********************//

  saveCilentPremium(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/saveclientpremium/", data, header);
  }

  SaveMyPremium(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/SaveMyPremium/", data, header);
  }


  //************************** CLIENT BANK DETAILS ********************//

  SaveClientBankDetails(clientid, data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/SaveClientBankDetails/" + clientid, data, header);
  }

  SaveMyBankDetails(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/SaveMyBankDetails/", data, header);
  }

  GetClientBankDetails(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/GetClientBankDetails/" + clientId, header);
  }

  GetMyBankDetails(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/GetMyBankDetails", header);
  }

  updateClientBankDetails(clientid, data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/UpdateClientBankDetails/" + clientid, data, header);
  }

  //************************** CLIENT PACKAGE ********************//

  saveCilentPackage(clientid, data: ClientPackage): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/saveclientpackage/" + clientid, data, header);
  }

  saveMyPackage(data: ClientPackage): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/saveclientpackage/", data, header);
  }


  //************************** Payment Status ********************//

  GetPaymentstatus(planId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/paymentstatus/" + planId, header);
  }

  savepayment(clientid, data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Payment/ClientPayment/" + clientid, data, header);
  }

  saveMypayment(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Payment/ClientPayment/", data, header);
  }

  GetcorporatePaymentHistory(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Payment/getCorporatePayment/" , header);
  }

  GetMyPaymentHistory(premiumId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Payment/getMyPayment/" + premiumId, header);
  }

  GetPaymentHistory(clientId, premiumId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Payment/getClientPayment/" + clientId + "/" + premiumId, header);
  }

  GetAllPaymentHistory(pagenumber, keyword): Observable<ClientPayments>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ClientPayments>("/Payment/getAllClientPayment/?page="+ (pagenumber+1) + "&search=" + keyword, header);
  }

  GetAllEmpPaymentHistory(pagenumber, keyword): Observable<EmployerPayments>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<EmployerPayments>("/Payment/getAllEmployerPayment/?page="+ (pagenumber+1) + "&search=" + keyword, header);
  }

  //************************** Client Order ********************//

  saveclientorder(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/saveclientorder/", data, header);
  }

  saveMyorder(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/saveclientorder/me", data, header);
  }

  Getclientorder(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getclientorder/" + clientId, header);
  }

  Getmyorder(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/getclientorder/", header);
  }

  //************************** Cancel Plan ********************//

  cancelplan(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/CancelPlan/", data, header);
  }

  cancelMyplan(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/CancelPlan/me", data, header);
  }

  GetMyPlanStatus(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/CancelPlan/me", header);
  }

  GetPlanStatus(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/CancelPlan/" + clientId, header);
  }

  GetcoolingoffPeriod(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/coolingoffPeriod/" + clientId, header);
  }

  //************************** Upgrade & downgrade Plan ********************//

  ChangePlanApprove(clientId, data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/ChangePlanApprove/" + clientId, data, header);
  }

  ChangePlanCancel(clientId, data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/ChangePlanCancel/" + clientId, data, header);
  }

  GetClientDocument(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/DocumentCenter/ClientDocuments/" + clientId, header);
  }

  updateinceptiondate(data, clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/updateInceptiondate/"+ clientId, data, header);
  }

  savepolicynumber(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Client/policynumber/" + clientId, '', header);
  }

  GetRequestedPlan(clientId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Client/requestedplan/" + clientId, header);
  }

  deleteClient(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Client/deleteClient/" + id, header);
  }
  Individualclientupload(data, vendorId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/ClientUpload/ImportClientCsv/" + vendorId, data, header);
  }
  Inceptiondateupload(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/ClientUpload/UpdateInceptionDate/", data, header);
  }
}
export interface EmployerPayment {
  id: number;
  clientId: number;
  amount: number;
  status: number;
  paymentMethod: string;
  timestamp: string;
  userstamp: number;
}
export interface EmployerPayments{
  pageSize: number;
  totalCount: number;
  employerPayments: EmployerPayment[];
}
export interface ClientPayment {
  id: number;
  clientId: number;
  amount: number;
  status: number;
  paymentMethod: string;
  timestamp: string;
  userstamp: number;
}

export interface ClientPayments{
  pageSize: number;
  totalCount: number;
  clientPayments: ClientPayment[];
}

export interface VendorClients {
  pageSize: number;
  totalCount: number;
  users: VendorClient[];
}

export interface AllClients {
  pageSize: number;
  totalCount: number;
  data: VendorClient[];
}

export interface VendorClient {
  client: Users;
  vendor: Users;
}
