import { Injectable } from '@angular/core';
import { OidcSecurityStorage } from 'angular-auth-oidc-client';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService implements OidcSecurityStorage {
  constructor(private localstorage: LocalStoreManager) {}

  public read(key: string): any {
    return JSON.parse(this.localstorage.getData(key));
  }

  public write(key: string, value: any): void {
    value = value == undefined ? null : value;
    this.localstorage.savePermanentData(JSON.stringify(value), key);
  }

  clear() {
    this.localstorage.clearLocalStorage();
  }

}
