export interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName?: string;
  permission?: string;
  route?: string;
  children?: NavItem[];
}


export const superadminMenu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    route: '/admin'
  },
  {
    displayName: 'Users',
    iconName: 'group',
    children: [
      {
        displayName: 'Funeral Undertaker',
        route: 'admin/fu'
      },
      {
        displayName: 'Staff',
        route: 'admin/staff/roles'
      }
    ]
  },
  {
    displayName: 'Funeral Service',
    iconName: 'control_camera',
    children: [
      {
        displayName: 'Supplier',
        route: 'admin/supplier'
      },
      {
        displayName: 'Event/Product',
        route: 'admin/product'
      },
      {
        displayName: 'Calendar',
        route: 'admin/calendar'
      }
    ]
  },
  {
    displayName: 'Clients',
    iconName: 'person',
    children: [
      {
        displayName: 'All Clients',
        route: 'admin/client'
      },
      {
        displayName: 'Corporate Clients',
        route: 'admin/corporate'
      }
    ]
  },
  {
    displayName: 'Insurance Claim',
    iconName: 'equalizer',
    route: 'admin/insurance'
  },
  {
    displayName: 'Finance',
    iconName: 'receipt',
    route: 'admin/finance'
  },
  {
    displayName: 'Manage User Roles',
    iconName: 'assignment_ind',
    route: 'admin/role'
  },
  {
    displayName: 'Email Template',
    iconName: 'article',
    route: 'admin/email-template'
  },
  {
    displayName: 'Manage Plans',
    iconName: 'layers',
    children: [
      {
        displayName: 'PlanType',
        route: 'admin/plan-type'
      },
      {
        displayName: 'Plan',
        route: 'admin/plan'
      },
      {
        displayName: 'Package',
        route: 'admin/package'
      },
      {
        displayName: 'Settings',
        route: 'admin/global-settings'
      }
    ]
  },
  {
    displayName: 'Rate Table',
    iconName: 'list_alt',
    route: '',
    children: [
      {
        displayName: 'Re-insurance Rate',
        route: 'admin/rate-table'
      },
      {
        displayName: 'Classification Rate',
        route: 'admin/classification-rate'
      },
      {
        displayName: 'Flat Rate',
        route: 'admin/flat-rate'
      }
    ]
  },
  {
    displayName: 'Reports',
    iconName: 'list_alt',
    route: 'report',
    children: [
      {
        displayName: 'Report',
        route: 'admin/report'
      },
      {
        displayName: 'Audit trail',
        route: 'admin/audit-log'
      }
    ]
  }
];

export const fuMenu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    route: '/FU'
  },
  {
    displayName: 'Staff',
    iconName: 'group',
    route: 'FU/staff'
  },
  {
    displayName: 'Clients',
    iconName: 'person',
    route: 'FU/client'
  },
  {
    displayName: 'Corporate Clients',
    iconName: 'group',
    route: 'FU/corporate-clients'
  },
  {
    displayName: 'Insurance Claim',
    iconName: 'equalizer',
    route: 'FU/insurance'
  },
  {
    displayName: 'Calendar',
    iconName: 'calendar_today',
    route: 'FU/calendar'
  },
  {
    displayName: 'Supplier', 
    iconName: 'store',
    route: 'FU/supplier'
  },
  {
    displayName: 'Manage Plans',
    iconName: 'layers',
    route: 'FU/plan'
  },
  {
    displayName: 'Reports',
    iconName: 'list_alt',
    route: 'FU/report',
  },
  {
    displayName: 'Customize Domain',
    iconName: 'settings',
    route: 'FU/setting',
  },
  {
    displayName: 'Event Management',
    iconName: 'calendar_today',
    route: 'FU/event-management',
  }
];

export const clientMenu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    route: '/client'
  },
  {
    displayName: 'My Account',
    iconName: 'person',
    route: '/client/account'
  },
  {
    displayName: 'My Plan',
    iconName: 'person',
    route: '/client/myplan'
  }
];

export const corporateMenu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    route: 'corporate'
  },
  {
    displayName: 'Employees & Plans',
    iconName: 'person',
    route: 'corporate/myplan'
  },
  {
    displayName: 'Funeral Event',
    iconName: 'payment',
    route: 'corporate/funeral'
  }
];

export const staffMenu: NavItem[] = [
  {
    displayName: 'Supplier',
    iconName: 'supervisor_account',
    permission: 'supplier.view',
    route: 'staff/supplier'
  },
  {
    displayName: 'Event/Product',
    iconName: 'store',
    permission: 'product.view',
    route: 'staff/product'
  },
  {
    displayName: 'Calendar',
    iconName: 'event',
    permission: 'event.view',
    route: 'staff/calendar'
  },
  {
    displayName: 'All Clients',
    iconName: 'account_circle',
    permission: 'users.view',
    route: 'staff/client'
  },
  {
    displayName: 'Corporate Clients',
    iconName: 'account_box',
    permission: 'corporate.view',
    route: 'staff/corporate'
  },
  {
    displayName: 'Insurance Claim',
    iconName: 'equalizer',
    permission: 'claim.view',
    route: 'staff/insurance'
  },
  {
    displayName: 'Finance',
    iconName: 'receipt',
    permission: 'finance.view',
    route: 'staff/finance'
  }
];

export const corporateClientMenu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'person',
    route: 'corporate-client'
  },
  {
    displayName: 'My Account',
    iconName: 'person',
    route: '/corporate-client/account'
  },
  {
   displayName: 'Employees',
   iconName: 'local_florist',
   route: 'corporate-client/employees'
  },
  {
   displayName: 'Payment',
   iconName: 'payment',
   route: 'corporate-client/payment'
  }
];

export const corporateEmployeeMenu: NavItem[] = [
  {
    displayName: 'Policy Details',
    iconName: 'receipt',
    route: 'corporate-employee/policy-details'
  }
];

export const fustaffMenu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    route: '/fustaff'
  },
  {
    displayName: 'Clients',
    iconName: 'person',
    permission: 'users.view',
    route: 'fustaff/client'
  },
  {
    displayName: 'Corporate Clients',
    iconName: 'group',
    permission: 'corporate.view',
    route: 'fustaff/corporate'
  },
  {
    displayName: 'Insurance Claim',
    iconName: 'equalizer',
    permission: 'claim.view',
    route: 'fustaff/insuranceclaim'
  },
  {
    displayName: 'Supplier',
    iconName: 'store',
    permission: 'supplier.view',
    route: 'fustaff/supplier'
  },
  {
    displayName: 'Manage Plans',
    iconName: 'layers',
    permission: 'plan.view',
    route: 'fustaff/plan'
  },
  {
    displayName: 'Reports',
    iconName: 'list_alt',
    permission: 'reports.view',
    route: 'fustaff/report',
  },
  {
    displayName: 'Event Management',
    iconName: 'calendar_today',
    permission: 'event.view',
    route: 'fustaff/event-management',
  }
];
