import { CommonService } from '@app/services';
import { Component, AfterViewInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-notification',
  templateUrl: './top-notification.component.html',
  styleUrls: ['./top-notification.component.scss']
})
export class TopNotificationComponent implements AfterViewInit {
  notifications: any;
  isLoading = true;
  userRole;
  constructor(
    public router: Router,
    private auth: AuthService,
    public common: CommonService
  ) { }

  ngAfterViewInit() {
    this.isLoading = true;
    this.loaddata();
  }

  ngAfterContentInit(){
  }

  loaddata() {
    this.common.getUnreadList()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(res => {
      this.notifications = res;
      this.common.readAllUnread().subscribe();
    });
  }

  gotonotification() {
    this.userRole = this.auth.userRole;
    switch (this.userRole) {
      case 'Developer': {
        this.router.navigate(['admin/notifications']);
        break;
      }
      case 'Administrator': {
        this.router.navigate(['admin/notifications']);
        break;
      }
      case 'Funeral Undertaker': {
        this.router.navigate(['FU/notifications']);
        break;
      }
      case 'Clients': {
        this.router.navigate(['client/notifications']);
        break;
      }
      case 'Corporate': {
        this.router.navigate(['corporate-client/notifications']);
        break;
      }
      case 'Corporate Client': {
        this.router.navigate(['corporate-employee/notifications']);
        break;
      }
      default: {
        this.router.navigate(['staff/notifications']);
        break;
      }
    }
  }
}
