import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-underwriting-question',
  templateUrl: './underwriting-question.component.html',
  styleUrls: ['./underwriting-question.component.scss']
})
export class UnderwritingQuestionComponent implements OnInit {

  questions;
  productform: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: '', public fb: FormBuilder,
    public dialogRef: MatDialogRef<UnderwritingQuestionComponent>) {
    this.questions = data;}

  ngOnInit() {
    this.productform = this.fb.group({
      questions: this.fb.array([])
    });
    var quesarr = this.questions.question;
    var quest = this.productform.get('questions') as FormArray;
    quesarr.forEach((item, index) => {
      if (item.needtoverify) {
        quest.push(
          this.fb.group({
            question: [item.question],
            answer: ["", Validators.required],
            clientId: [this.questions.clientId]
          }));
      }
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onProductSubmit(data) {
    this.dialogRef.close(data);
  }
}
