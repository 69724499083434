import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@app/services/auth/auth.service';
import { CommonService } from '@app/services';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {

  public pushRightClass: string;
  public userRole;
  public notificationcount = 0;
  loadnotify = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    public router: Router,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private service: CommonService,
    private ngZone: NgZone
  ) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.unreadnotificationcount();
  }

  unreadnotificationcount(){
    this.service.getUnreadCount().subscribe(res => {
      this.notificationcount = res;
    })
  }

  opennotification(){
    this.loadnotify = true;
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    this.auth.logout();
    //this.ngZone.run(() => this.router.navigateByUrl("/"));
  }

  changeLang(language: string) {
    this.translate.use(language);
  }
  gotoprofile() {
    this.userRole = this.auth.userRole;
    switch (this.userRole) {
      case 'Developer': {
        this.router.navigate(['admin/account']);
        break;
      }
      case 'Administrator': {
        this.router.navigate(["admin/account"]);
        break;
      }
      case 'Funeral Undertaker': {
        this.router.navigate(["FU/account"]);
        break;
      }
      case 'Clients': {
        this.router.navigate(["client/account"]);
        break;
      }
      case 'Corporate': {
        this.router.navigate(["corporate-client/account"]);
        break;
      }
      case 'Corporate Client': {
        this.router.navigate(["corporate-employee/account"]);
        break;
      }
      default: {
        this.router.navigate(["staff/account"]);
        break;
      }
    }
  }

  gotoversion() {
    this.userRole = this.auth.userRole;
    switch (this.userRole) {
      case 'Developer': {
        this.router.navigate(['admin/version']);
        break;
      }
      case 'Administrator': {
        this.router.navigate(['admin/version']);
        break;
      }
      case 'Funeral Undertaker': {
        this.router.navigate(['FU/version']);
        break;
      }
      case 'Clients': {
        this.router.navigate(['client/version']);
        break;
      }
      case 'Corporate': {
        this.router.navigate(['corporate-client/version']);
        break;
      }
      case 'Corporate Client': {
        this.router.navigate(['corporate-employee/version']);
        break;
      }
      default: {
        this.router.navigate(['staff/version']);
        break;
      }
    }
  }

  gotonotification() {
    this.userRole = this.auth.userRole;
    switch (this.userRole) {
      case 'Developer': {
        this.router.navigate(['admin/notifications']);
        break;
      }
      case 'Administrator': {
        this.router.navigate(['admin/notifications']);
        break;
      }
      case 'Funeral Undertaker': {
        this.router.navigate(['FU/notifications']);
        break;
      }
      case 'Clients': {
        this.router.navigate(['client/notifications']);
        break;
      }
      case 'Corporate': {
        this.router.navigate(['corporate-client/notifications']);
        break;
      }
      case 'Corporate Client': {
        this.router.navigate(['corporate-employee/notifications']);
        break;
      }
      default: {
        this.router.navigate(['staff/notifications']);
        break;
      }
    }
  }
}
