import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Location } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment-timezone';
import { CommonService } from '../../services/common.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth/auth.service';
import { ClientService } from '../../services/client.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EditClientComponent implements OnInit {

  @Input() dataObject;
  @Output() data = new EventEmitter();
  personalresult;
  personFormGroup: FormGroup;
  isloaded = false;
  pcitys;
  scitys;
  ecitys;
  psuburbs;
  ssuburbs;
  esuburbs;
  states;
  countrys;
  jobs;
  age;
  ageerror;
  maxDate = new Date();
  dob = new FormControl(moment());
  minage;
  maxage;
  title;
  servicetype;
  NDIerror;
  salary;
  errormessage;

  constructor(private _formBuilder: FormBuilder, public common: CommonService, private location: Location, public account: AccountService, public auth: AuthService, public clientservice: ClientService) { }

  ngOnInit() {
    this.countrys = this.common._country;
    this.jobs = this.common._job;
    this.title = this.common._title;
    this.salary = this.common._salary;
    this.getregion();
    this.getplandetails();

  }

  getregion() {
    this.common.loadRegion().subscribe(res => {
      this.states = res;
    })
  }

  getcity(type, region) {
    this.common.loadCitites(region).subscribe(res => {
      if (type == 'physical') {
        this.pcitys = res;
        (<FormArray>this.personFormGroup.controls['physicaladdress']).at(0).get('city').reset();
      }
      if (type == 'postal') {
        this.scitys = res;
        (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('city').reset();
      }
      if (type == 'emp') {
        this.ecitys = res;
        ((<FormArray>this.personFormGroup.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('city').reset();
      }
    })
  }

  getsuburb(type, city) {
    this.common.loadsuburb(city).subscribe(res => {
      if (type == 'physical') {
        this.psuburbs = res;
        (<FormArray>this.personFormGroup.controls['physicaladdress']).at(0).get('suburb').reset();
        (<FormArray>this.personFormGroup.controls['physicaladdress']).at(0).get('pincode').reset();
        (<FormArray>this.personFormGroup.controls['physicaladdress']).at(0).get('strcode').reset();
      }
      if (type == 'postal') {
        this.ssuburbs = res;
        (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('suburb').reset();
        (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('pincode').reset();
        (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('strcode').reset();
      }
      if (type == 'emp') {
        this.esuburbs = res;
        ((<FormArray>this.personFormGroup.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('suburb').reset();
        ((<FormArray>this.personFormGroup.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('pincode').reset();
        ((<FormArray>this.personFormGroup.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('strcode').reset();
      }
    })
  }

  geteventsuburb(city) {
    this.common.loadsuburb(city).subscribe(res => {
      this.psuburbs = res;
      this.personFormGroup.controls['suburb'].reset();
      this.personFormGroup.controls['pincode'].reset();
      this.personFormGroup.controls['strcode'].reset();
    })
  }

  geteventcode(value) {
    this.psuburbs.forEach((item, index) => {
      if (item.suburb == value) {
        this.personFormGroup.controls['pincode'].setValue(item.bxCode);
        this.personFormGroup.controls['strcode'].setValue(item.strCode);
      }
    });
  }

  getcode(type, value) {
    if (type == 'physical') {
      this.psuburbs.forEach((item, index) => {
        if (item.suburb == value) {
          (<FormArray>this.personFormGroup.controls['physicaladdress']).at(0).get('pincode').setValue(item.bxCode);
          (<FormArray>this.personFormGroup.controls['physicaladdress']).at(0).get('strcode').setValue(item.strCode);
        }
      });

    }
    if (type == 'postal') {
      this.ssuburbs.forEach((item, index) => {
        if (item.suburb == value) {
          (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('pincode').setValue(item.bxCode);
          (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('strcode').setValue(item.strCode);
        }
      });
    }
    if (type == 'emp') {
      this.esuburbs.forEach((item, index) => {
        if (item.suburb == value) {
          ((<FormArray>this.personFormGroup.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('pincode').setValue(item.bxCode);
          ((<FormArray>this.personFormGroup.controls['employerdata']).at(0).get('employeraddress') as FormArray).at(0).get('strcode').setValue(item.strCode);
        }
      });
    }
  }

  getcityevent(region) {
    this.common.loadCitites(region).subscribe(res => {
      this.pcitys = res;
      this.personFormGroup.controls['city'].reset();
    })
  }

  loadcityevent(region) {
    this.common.loadCitites(region).subscribe(res => {
      this.pcitys = res;
    })
  }

  loadcitys(type, region) {
    this.common.loadCitites(region).subscribe(res => {
      if (type == 'physical') {
        this.pcitys = res;
      }
      if (type == 'postal') {
        this.scitys = res;
      }
      if (type == 'emp') {
        this.ecitys = res;
      }
    })
  }

  loadsuburb(type, city) {
    this.common.loadsuburb(city).subscribe(res => {
      if (type == 'physical') {
        this.psuburbs = res;
      }
      if (type == 'postal') {
        this.ssuburbs = res;
      }
      if (type == 'emp') {
        this.esuburbs = res;
      }
    })
  }

  loadeventsuburb(city) {
    this.common.loadsuburb(city).subscribe(res => {
        this.psuburbs = res;
    })
  }

  phyaddres(value) {
    var address: FormGroup = ((<FormArray>this.personFormGroup.controls['physicaladdress']).at(0)) as FormGroup;
    if (value) {
      this.ssuburbs = this.psuburbs;
      this.scitys = this.pcitys;
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('country').setValue(address.get('country').value);
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('state').setValue(address.get('state').value);
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('city').setValue(address.get('city').value);
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('suburb').setValue(address.get('suburb').value);
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('street').setValue(address.get('street').value);
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('pincode').setValue(address.get('pincode').value);
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('strcode').setValue(address.get('strcode').value);
    }
    else {
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('country').reset();
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('state').reset();
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('city').reset();
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('suburb').reset();
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('street').reset();
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('pincode').reset();
      (<FormArray>this.personFormGroup.controls['postaladdress']).at(0).get('strcode').reset();
    }
  }

  getplandetails() {
    this.clientservice.getPlanTypeByclientid(this.dataObject.id).subscribe(res => {
      this.minage = res.minAge;
      this.maxage = res.maxAge;
      this.servicetype = 'Policy';
      this.getpersonaldetails();
    }, err => {
      this.servicetype = 'Event';
      this.getpersonaldetails();
    });
  }

  manualcheck() {
    this.errormessage = this.common.manualcheck(this.personFormGroup);
  }

  getpersonaldetails() {
    this.personalresult = this.dataObject;
    if (this.servicetype == 'Policy') {
      if (this.personalresult.dob != '0001-01-01T00:00:00')
        this.personalresult.age = this.showage(this.personalresult.dob);
      else
        this.personalresult.dob = '';

      this.personFormGroup = this._formBuilder.group({
        title: [this.personalresult.title, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        firstname: [this.personalresult.firstname, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        surname: [this.personalresult.surname, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        dob: [this.personalresult.dob, Validators.required],
        email: [this.personalresult.email, Validators.compose([Validators.required, Validators.email, Validators.pattern('^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$')])],
        passportno: [this.personalresult.passportNo, Validators.compose([Validators.pattern('^[a-zA-Z0-9]*$')])],
        nationalId: [this.personalresult.nationalId, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)])],
        nationality: [this.personalresult.nationality],
        cell: [this.personalresult.mobilenumber, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        phonenumber: [this.personalresult.phonenumber, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],


        physicaladdress: this._formBuilder.array([this.formaddress(true, this.personalresult)]),
        postaladdress: this._formBuilder.array([this.formaddress(false, this.personalresult)]),
        employerdata: this._formBuilder.array([this.formemployer(this.personalresult)]),

        age: [this.personalresult.age, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        gender: [this.personalresult.gender, Validators.required],
        profile: ['']
      });
    }
    if (this.servicetype == 'Event') {
      if (this.personalresult.dob == '0001-01-01T00:00:00')
        this.personalresult.dob = '';
      let address;
      this.personalresult.addresses.forEach((item, index) => {
        if (item.isPrimary)
          address = item.address;
      })
      this.loadcityevent(address.state);
      this.loadeventsuburb(address.city);
      this.personFormGroup = this._formBuilder.group({
        title: [this.personalresult.title, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        firstname: [this.personalresult.firstname, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        surname: [this.personalresult.surname, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        dob: [this.personalresult.dob, Validators.required],
        email: [this.personalresult.email, Validators.compose([Validators.required, Validators.email, Validators.pattern('^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$')])],
        passportno: [this.personalresult.passportNo, Validators.compose([Validators.pattern('^[a-zA-Z0-9]*$')])],
        nationalId: [this.personalresult.nationalId, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)])],
        nationality: [this.personalresult.nationality],
        mobilenumber: [this.personalresult.mobilenumber, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        phoneNumber: [this.personalresult.phonenumber, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        street: [address.street, Validators.required],
        suburb: [address.suburb, Validators.required],
        city: [address.city, Validators.required],
        state: [address.state, Validators.required],
        country: [address.country, Validators.required],
        pincode: [address.pincode, Validators.compose([Validators.minLength(4), Validators.maxLength(4)])],
        strcode: [address.strcode, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])]
      });
    }
    this.isloaded = true;
  }

  formemployer(data) {
    let result = data;
    if (result.employer != null) {
      return this._formBuilder.group({
        employerName: [result.employer.employerName, Validators.pattern('[a-zA-Z ]*$')],
        phone: [result.employer.phone, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        salary: [result.employer.salary],
        job: [result.employer.incl],
        employeraddress: this._formBuilder.array([this.formempaddress(true, result.employer)]),

      });
    }
    else {
      return this._formBuilder.group({
        employerName: ['', Validators.pattern('[a-zA-Z ]*$')],
        phone: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
        salary: [''],
        job: [''],
        employeraddress: this._formBuilder.array([this.formempaddress(true, '')]),

      });
    }
  }

  formempaddress(primary, data) {
    let result = data;
    if (result.address != null) {
      this.loadcitys('emp', result.address.state);
      this.loadsuburb('emp', result.address.city);
      return this._formBuilder.group({
        street: [result.address.street],
        suburb: [result.address.suburb],
        city: [result.address.city],
        state: [result.address.state],
        country: [result.address.country],
        pincode: [result.address.pincode, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])],
        strcode: [result.address.strcode, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])]
      });
    }
    return this._formBuilder.group({
      street: [''],
      suburb: [''],
      city: [''],
      state: [''],
      country: [''],
      pincode: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])],
      strcode: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])]
    });
  }

  formaddress(primary, data) {
    let result = data
    if (result.addresses != '') {
      for (let key in result.addresses) {
        let value = result.addresses[key].isPrimary;
        if (value == primary) {
          if (value) {
            this.loadcitys('physical', result.addresses[key].address.state);
            this.loadsuburb('physical', result.addresses[key].address.city);
          }
          if (!value) {

            this.loadcitys('postal', result.addresses[key].address.state);
            this.loadsuburb('postal', result.addresses[key].address.city);
          }
          return this._formBuilder.group({
            street: [result.addresses[key].address.street],
            suburb: [result.addresses[key].address.suburb],
            city: [result.addresses[key].address.city],
            state: [result.addresses[key].address.state],
            country: [result.addresses[key].address.country],
            pincode: [result.addresses[key].address.pincode, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])],
            strcode: [result.addresses[key].address.strcode, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])]
          });
        }
      }
    }
    return this._formBuilder.group({
      street: [''],
      suburb: [''],
      city: [''],
      state: [''],
      country: [''],
      pincode: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])],
      strcode: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)])]
    });
  }

  showage(date) {
    this.age = this.common.calculateage(date);
    return this.age;
  }

  calculateage(type: string, event: MatDatepickerInputEvent<Date>) {
    this.personFormGroup.get('age').reset();
    this.age = this.common.calculateage(event.value);
    if (this.age >= this.minage && this.age < this.maxage) {
      this.personFormGroup.get('age').setValue(this.age);
      this.ageerror = '';
    }
    else {
      this.ageerror = "Age must be more than or equal to " + this.minage + " and less than " + this.maxage;
    }
    if (this.personFormGroup.get('nationalId').value != '') {
      this.validationnationid();
    }
  }

  onSubmit(form) {
    this.data.emit(form);
  }

  cancel() {
    this.location.back();
  }

  validationnationid() {
    this.NDIerror = '';
    var id = this.personFormGroup.get('nationalId').value;
    if (id && id != '') {
      var gender = this.personFormGroup.get('gender').value;
      var DOB = new Date(this.personFormGroup.get('dob').value);
      var idbirth = id.substring(0, 6);
      var year = DOB.getFullYear();
      var mon: any = DOB.getMonth() + 1;
      var date: any = DOB.getDate();
      var date1;
      if (String(mon).length == 1)
        mon = 0 + String(mon);
      if (String(date).length == 1)
        date = 0 + String(date);
      var date1: any = (String(year).substring(2, 4)) + mon + date;
      if (+date1 != +idbirth) {
        this.NDIerror = 'Enter valid National Id';
        this.personFormGroup.get('nationalId').reset();
      }
      else {
        var genderno = id.substring(6, 7);
        if (genderno >= 0 && genderno <= 4) {
          if (gender != 'F') {
            this.personFormGroup.get('nationalId').reset();
            this.NDIerror = 'Enter valid National Id';
          }
        }
        else if (genderno >= 5 && genderno <= 9) {
          if (gender != 'M') {
            this.personFormGroup.get('nationalId').reset();
            this.NDIerror = 'Enter valid National Id';
          }
        }
        else {
          this.NDIerror = '';
        }
      }
    }
  }

}
