import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CorporateService } from '@app/services';
import { finalize } from 'rxjs/operators';

export interface DialogData {
  Id: number;
}

@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.scss']
})
export class ErrorReportComponent implements OnInit {

  employerId;
  errormsg;
  loadingflag = false;

  constructor(public dialogRef: MatDialogRef<ErrorReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private corporateService: CorporateService) {
    this.employerId = data["Id"];
  }

  ngOnInit() {
    this.geterrorreport();
  }

  geterrorreport() {
    this.corporateService.GetErrorReport(this.employerId).subscribe(res => {
      this.errormsg = res;
      this.loadingflag = true;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
