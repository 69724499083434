import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-add-plan-product',
  templateUrl: './add-plan-product.component.html',
  styleUrls: ['./add-plan-product.component.css']
})
export class AddPlanProductComponent implements OnInit {

  productFormGroup: FormGroup;
  isenable = true;
  productList;
  categoryList;
  isloaded = false;
  productoptionlist;
  ProductValueList;
  productname;
  producttype;
  benefitOptions;

  constructor(
    public _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddPlanProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
    public productservice: ProductService
  ) { }

  ngOnInit() {
    this.productFormGroup = this._formBuilder.group({
      category: ['', Validators.required],
      product: [''],
      productId: ['', Validators.required]
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getProductList(value) {
    this.producttype = value;
    this.productoptionlist = [];
    this.productList = [];
    this.productFormGroup.get("product").reset();
    
    if (value == "Y") {
      this.productservice.getEventProducts().subscribe(res => {
        this.productList = res;
      });
    }
    else {
      this.productservice.getBenefitProducts().subscribe(res => {
        this.productList = res;
      });
    }
  }

  getProductOptionList(value) {
    this.productoptionlist = [];
    this.productList.products.forEach((item, index) => {
      if (item.id == value) {
        this.productname = item.name;
        if (item.productOptions.length > 0) {
          this.productoptionlist = item.productOptions;
          this.productFormGroup.addControl("productOption", this._formBuilder.array([]));
          let product = <FormArray>this.productFormGroup.get('productOption');
          for (var i = product.length - 1; i >= 0; i--) {
            product.removeAt(i);
          }
          this.productoptionlist.forEach((item1, index1) => {
            product.push(this._formBuilder.group({
              prodOption: [{ value: item1.prodOption, disabled: true }, Validators.required],
              prodValue: ['', Validators.required]
            }))
          })
          if (this.producttype != 'Y') {
            this.benefitOptions = item.benefitOptions;
          }
          this.getProductValueList();
        }
        else {
          this.productFormGroup.removeControl("productOption");
        }
      }
    })
  }

  getProductValueList() {
    this.productoptionlist.forEach((item, index) => {
      this.productservice.getProductOptionValue(item.id).subscribe(res => {
        var select = document.getElementById('prodValue' + index) as HTMLSelectElement;
        this.ProductValueList = res;
        var i;
        for (i = select.options.length - 1; i >= 1; i--) {
          select.remove(i);
        }
        this.ProductValueList.forEach((item, index) => {
          var opt = document.createElement("option");
          opt.value = item.prodOptionValue;
          opt.text = item.prodOptionValue;
          select.add(opt, 1);
        });
      });
    });

    
  }

  onSubmit(data) {
    data.product = this.productname;
    if (this.producttype != 'Y') {
      data.benefitOption = this.benefitOptions;
    }  
    this.dialogRef.close(data);
  }
}
