import { Component, OnInit, Inject, NgModule, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, ErrorStateMatcher } from '@angular/material';
import { FormGroup, Validators, FormGroupDirective, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { resetPassword } from '../../models/account/common';
import { StafftService } from '../../services/staff.service';
import { VendorService } from '../../services/vendor.service';
import { ClientService } from '../../services/client.service';
import { finalize } from 'rxjs/operators';
import { CorporateService } from '@app/services/corporate.service';
import { EmployeeService } from '@app/services/employee.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild(FormGroupDirective, { static: true }) form;
  userdata;
  resetPassword: resetPassword;
  myForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  errorMsg;
  isenable = true;
  saving: boolean = false;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
    private staffservice: StafftService,
    private vendorservice: VendorService,
    private clientService: ClientService,
    private corporateService: CorporateService,
    private employeeService: EmployeeService
  ) {
    this.userdata = data;
    this.myForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['']
    }, { validator: this.checkPasswords });
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onSubmit(value) {
    this.saving = true;
    this.isenable = false;
    this.resetPassword = {
      userName: this.userdata.userName,
      userId: this.userdata.userId,
      password: value.password,
      confirmPassword: value.confirmPassword
    };
    switch (this.userdata.service) {
      case 'vendor': {
        this.vendorservice.resetPassword(this.resetPassword)
          .pipe(
            finalize(() => {
              this.saving = false;
            })
          )
          .subscribe(res => {
            this.dialogRef.close(true);
          }, err => {
            this.errorMsg = err.error.errors[0].description;
            this.reset();
          });
        break;
      }
      case 'staff': {
        this.staffservice.resetPassword(this.resetPassword)
          .pipe(
            finalize(() => {
              this.saving = false;
            })
          )
          .subscribe(res => {
            this.dialogRef.close(true);
          }, err => {
            this.errorMsg = err.error.errors[0].description;
            this.reset();
          });
        break;
      }
      case 'client': {
        this.clientService.resetPassword(this.resetPassword)
          .pipe(
            finalize(() => {
              this.saving = false;
            })
          )
          .subscribe(res => {
            this.dialogRef.close(true);
          }, err => {
            this.errorMsg = err.error.errors[0].description;
            this.reset();
          });
        break;
      }
      case 'employer': {
        this.corporateService.resetPassword(this.resetPassword)
          .pipe(
            finalize(() => {
              this.saving = false;
            })
          )
          .subscribe(res => {
            this.dialogRef.close(true);
          }, err => {
            this.errorMsg = err.error.errors[0].description;
            this.reset();
          });
        break;
      }
      case 'employee': {
        this.employeeService.resetPassword(this.resetPassword)
          .pipe(
            finalize(() => {
              this.saving = false;
            })
          )
          .subscribe(res => {
            this.dialogRef.close(true);
          }, err => {
            this.errorMsg = err.error.errors[0].description;
            this.reset();
          });
        break;
      }
      default:
        break;
    }
  }

  onChange() {
    this.isenable = true;
  }

  reset() {
    this.form.resetForm()
  }
}
