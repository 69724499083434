import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment-timezone';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CommonService } from '../../services/common.service';
import { ClientService } from '../../services/client.service';
import { subMemberUser, extendedmember } from '../../models/client/client';
import { VendorService } from '../../services/vendor.service';
import { PlanService } from '../../services/plan.service';
import { AccountService } from '../../services/account.service';
import { ProductService } from '../../services/product.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-add-extendedmember',
  templateUrl: './add-extendedmember.component.html',
  styleUrls: ['./add-extendedmember.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AddExtendedmemberComponent implements OnInit {

  clientid;
  title;
  personFormGroup: FormGroup;
  relation;
  plandetails;
  beneficiarycount;
  extendedmembercount;
  existextendedmembercount;
  beneficiarydata;
  existbenecount;
  benefit;
  relationship;
  relationshipdetails;
  extrelation = [];
  age;
  errormsg;
  extendedmember: extendedmember;
  extendedMemberUser: subMemberUser;
  saving = false;
  profileimageUrl = '/assets/images/avatar.png';
  maxDate = new Date();
  dob = new FormControl(moment());
  radioerr;
  ageerror;
  doberror;
  globelagelimt;
  planlist;
  packagelist = [];
  packageproduct = [];
  NDIerror;
  enable = false;
  bank;
  errormessage;
  vendor;
  vendorId;
  vendorregion;
  commondetails;
  pakageerror;
  type = 3;
  extcoveramount;
  packageProducts;
  membertype = 'extendedmember';
  extsubmitflag = false;
  funeralAmount;
  totalAmount;
  Premium;
  TotalPremium;
  path;
  fileToUpload;
  fileerror;

  constructor(
    public common: CommonService,
    public _formbuilder: FormBuilder,
    public clientservice: ClientService,
    public vendorservice: VendorService,
    public account: AccountService,
    public planservice: PlanService,
    public dialogRef: MatDialogRef<AddExtendedmemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '', private productservice: ProductService,) {
    this.clientid = data;
  }

  manualcheck() {
    this.errormessage = this.common.manualcheck(this.personFormGroup);
  }

  ngOnInit() {
    this.title = this.common._title;
    this.getrelationship();
    this.getplandetails();
    this.personFormGroup = this._formbuilder.group({
      title: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      Firstname: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      Surname: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      age: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
      relationship: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern('^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$')])],
      PassportNo: ['', Validators.compose([Validators.pattern('^[a-zA-Z0-9]*$')])],
      nationalId: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)])],
      nationality: ['', Validators.pattern('[a-zA-Z ]*$')],
      PhoneNumber: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
      Mobilenumber: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
      profileImgPath: [''],
      beneficiary: [''],
      benefit: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });
    this.personFormGroup.controls["benefit"].disable();
    this.getbenefit();
    this.getagelimit();
    this.personFormGroup.valueChanges.subscribe(val => {
      this.onChange();
    });
  }

  onChange() {
    if (this.personFormGroup.valid) {
      this.enable = false;
    }
  }

  getplandetails() {
    this.relation = [];
    this.clientservice.getPlanTypeByclientid(this.clientid.clientid).subscribe(res => {
      this.plandetails = res;
      this.beneficiarycount = this.plandetails.beneficarycount;
      this.extendedmembercount = this.plandetails.extendedCount;
    });
    if (this.extendedmembercount != 0) {
      this.clientservice.getextendedmembercount(this.clientid.clientid).subscribe(res => {
        this.existextendedmembercount = res;
      })
      this.clientservice.getextendedmembersrelationship(this.clientid.clientid).subscribe(res => {
        this.relationshipdetails = res;
        var count = 0;
        var name;
        this.relationshipdetails.extendedMembers.forEach((item, index) => {
          name = item.relationship;
          count = 0;
          this.relationshipdetails.extendedMembers.forEach((items, indexs) => {
            if (name == items.relationship)
              count = count + 1;
          })
          this.extrelation.push({ name: name, count: count });
        })
      })
    }
  }

  getbenefit() {
    this.clientservice.getBeneficiary(this.clientid.clientid).subscribe(res => {
      this.beneficiarydata = res;
      this.existbenecount = this.beneficiarydata.length;
      this.beneficiarydata.forEach((item, index) => {
        this.benefit = this.benefit + Number(item.benefit);
      });
      this.benefit = 100 - this.benefit;
    });
  }

  getrelationship() {
    this.common.loadRelationship().subscribe(res => {
      this.relationship = res;
    });
  }

  getagelimit() {
    this.common.loadPlanGlobalSettings().subscribe(res => {
      this.globelagelimt = res;
    });
  }

  calculateage(type: string, event: MatDatepickerInputEvent<Date>) {
    this.age = this.common.calculateage(event.value);
    this.personFormGroup.get('age').setValue(this.age);
  }

  bencheck(value) {
    if (value.checked) {
      this.personFormGroup.controls["benefit"].enable();
      this.personFormGroup.addControl('bankName', new FormControl(''));
      this.personFormGroup.addControl('accountNo', new FormControl(''));
      this.personFormGroup.addControl('typeOfAccount', new FormControl(''));
      this.personFormGroup.addControl('accountHolderName', new FormControl(''));
      this.personFormGroup.addControl('branch', new FormControl(''));
      this.personFormGroup.addControl('branchCode', new FormControl(''));
    }
    else {
      this.personFormGroup.controls["benefit"].disable();
      this.personFormGroup.controls["benefit"].reset();
      this.personFormGroup.removeControl('bankName');
      this.personFormGroup.removeControl('accountNo');
      this.personFormGroup.removeControl('typeOfAccount');
      this.personFormGroup.removeControl('accountHolderName');
      this.personFormGroup.removeControl('branch');
      this.personFormGroup.removeControl('branchCode');
    }
  }

  onSubmit(data) {
    this.enable = true;
    var relationflag;
    var relationcount;
    var existrelationflag;
    var exitrelationcount = 0;
    var minAge;
    var maxAge;
    var flag;
    if (this.plandetails.extendedMember.length != 0) {
      this.plandetails.extendedMember.forEach((item, index) => {
        if (data.relationship == item.relationship) {
          relationflag = true;
          relationcount = item.count;
          minAge = item.minAge;
          maxAge = item.maxAge;
        }
      });
      this.extrelation.forEach((item, index) => {
        if (data.relationship == item.name) {
          existrelationflag = true;
          exitrelationcount = item.count;
        }
      });

      if (relationflag) {
        if (existrelationflag) {
          if (relationcount > exitrelationcount) {
            if (minAge <= data.age && maxAge > data.age)
              flag = true;
            else
              this.errormsg = 'Age need to be greater or equal to ' + minAge + ' and less than ' + maxAge;
          }
          else {
            this.errormsg = 'Already ' + relationcount + ' ' + data.relationship + ' added. So cant add more';
          }
        }
        else {
          if (minAge <= data.age && maxAge > data.age)
            flag = true;
          else
            this.errormsg = 'Age need to be greater or equal to ' + minAge + ' and less than ' + maxAge;
        }
      }
      else {
        this.errormsg = 'This relationship not allowed';
      }
    }
    else {
      if (this.extendedmembercount > this.existextendedmembercount) {
        if (this.globelagelimt.childEntryMinAge <= data.age && this.globelagelimt.adultEntryMaxAge > data.age)
          flag = true;
        else
          this.errormsg = 'Age need to be greater or equal to ' + this.globelagelimt.childEntryMinAge + ' and less than ' + this.globelagelimt.adultEntryMaxAge;
      }
      else {
        this.errormsg = 'Already ' + this.extendedmembercount + ' Extended member added. So cant add more ';
      }
    }


    if (flag) {
      if (data.beneficiary == true) {
        if (this.beneficiarycount > this.existbenecount) {
          if (data.benefit > this.benefit) {
            if (this.benefit == 0)
              this.errormsg = 'Cannot save this extendedmember as beneficiary. Already monetary benefit is 100%';
            else
              this.errormsg = 'Monetary Benefit percentage cannot be more than ' + this.benefit;
          }
          else {
            this.errormsg = '';
            this.saveExtendedmember(data);
          }
        }
        else {
          this.errormsg = 'Already ' + this.beneficiarycount + ' beneficiary added. So cant save this extendedmember as beneficiary ';
        }
      }
      else {
        this.errormsg = '';
        this.saveExtendedmember(data);
      }
    }
  }

  saveExtendedmember(data) {
    this.saving = true;
    this.extendedMemberUser = {
      email: data.email,
      PhoneNumber: data.PhoneNumber,
      Mobilenumber: data.Mobilenumber,
      title: data.title,
      Firstname: data.Firstname,
      Surname: data.Surname,
      PassportNo: data.PassportNo,
      nationalId: data.nationalId,
      nationality: data.nationality,
      gender: data.gender,
      dob: data.dob,
      profileImgPath: this.path
    }
    this.extendedmember = {
      extendedMemberUser: this.extendedMemberUser,
      relationship: data.relationship
    }
    if (data.beneficiary) {
      this.extendedmember.benefit = data.benefit;
      this.extendedmember.asBeneficiary = data.beneficiary;
    }
    if (data.beneficiary == '') {
      this.extendedmember.asBeneficiary = false;
    }
    this.clientservice.addExtendedmember(this.clientid.clientid, this.extendedmember).subscribe(res => {
      if (data.beneficiary && (data.bankName || data.accountNo || data.typeOfAccount || data.accountHolderName || data.branch || data.branchCode)) {
        this.bank = {
          bankName: data.bankName,
          accountNo: data.accountNo,
          typeOfAccount: data.typeOfAccount,
          accountHolderName: data.accountHolderName,
          branch: data.branch,
          branchCode: data.branchCode
        }
        this.clientservice.SaveClientBankDetails(res.reasonPhrase, this.bank).subscribe(res1 => { });
      }
        this.common.openSnackBar('Extendedmember added Successfully', 'Close');
        this.dialogRef.close(true);
        this.saving = false;
    }, err3 => {
        this.saving = false;
      this.errormsg = err3.error.reasonPhrase;
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  profileupload(file: FileList, files) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.profileimageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.account.uploadimage(formData).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  validationnationid() {
    this.NDIerror = '';
    var id = this.personFormGroup.get('nationalId').value;
    var gender = this.personFormGroup.get('gender').value;
    var DOB = new Date(this.personFormGroup.get('dob').value);
    var idbirth = id.substring(0, 6);
    var year = DOB.getFullYear();
    var mon: any = DOB.getMonth() + 1;
    var date: any = DOB.getDate();
    var date1;
    if (String(mon).length == 1)
      mon = 0 + String(mon);
    if (String(date).length == 1)
      date = 0 + String(date);
    var date1: any = (String(year).substring(2, 4)) + mon + date;
    if (+date1 != +idbirth) {
      this.NDIerror = 'Enter valid Nation Id';
      this.personFormGroup.get('nationalId').reset();
    }
    else {
      var genderno = id.substring(6, 7);
      if (genderno >= 0 && genderno <= 4) {
        if (gender != 'F') {
          this.personFormGroup.get('nationalId').reset();
          this.NDIerror = 'Enter valid Nation Id';
        }
      }
      else if (genderno >= 5 && genderno <= 9) {
        if (gender != 'M') {
          this.personFormGroup.get('nationalId').reset();
          this.NDIerror = 'Enter valid Nation Id';
        }
      }
      else {
        this.NDIerror = '';
      }
    }
  }

  getvendor() {
    this.clientservice.getclientvendor(this.clientid.clientid).subscribe(res => {
      this.vendor = res;
      this.vendorId = res.id;
      this.getplans();
      res.addresses.forEach((item, index) => {
        if (item.isPrimary) {
          this.vendorregion = item.address.state;
        }
      })
    });

    this.common.loadPlanGlobalSettings().subscribe((res) => {
      this.commondetails = res;
    });
  }

  getplans() {
    this.vendorservice.getVendorPolicyPlan(this.vendorId, this.clientid.plantype.id).subscribe(res => {
      this.planlist = res;
    });
  }

  getpackage(plan) {
    this.pakageerror = '';
    this.packagelist = [];
    this.packageproduct = [];
    this.planservice.getpackagesbyregion(plan.planId, this.vendorregion).subscribe(res => {
      if (this.type == 3) {
        res.forEach((item, index) => {
          if (item.totalAmount <= this.clientid.mmpackageamount)
            this.packagelist.push(item);
        })
        if (this.packagelist.length == 0) {
          this.pakageerror = 'No package avaliable less or equal to the main member package in this plan';
        }
      }
      else {
        this.packagelist = res;
      }
    })
    if (this.commondetails.childAgeClassificationMax3 < this.age) {
      this.extcoveramount = plan.plan.extcoveramount;
    }
    else {
      if (this.commondetails.childAgeClassificationMin1 < this.age && this.commondetails.childAgeClassificationMax1 > this.age) {
        this.extcoveramount = this.commondetails.childMaxCoverAmount1;
      }
      if (this.commondetails.childAgeClassificationMin2 < this.age && this.commondetails.childAgeClassificationMax2 > this.age) {
        this.extcoveramount = this.commondetails.childMaxCoverAmount2;
      }
      if (this.commondetails.childAgeClassificationMin3 < this.age && this.commondetails.childAgeClassificationMax3 > this.age) {
        this.extcoveramount = this.commondetails.childMaxCoverAmount3;
      }
    }
  }

  getpackageproduct(data) {
    this.packageproduct = [];
    this.packageProducts = [];
    var planid = (this.personFormGroup.get('plan').value).planId;
    this.planservice.getPlanPackageProduct(data).subscribe(res => {
      res.forEach((item, index) => {
        this.packageProducts.push({
          packageProductId: item.id
        })
      })
      if (this.membertype == 'extendedmember') {
        res.forEach((item, index) => {
          if (item.packageProduct.product.iseventproduct == 'N') {
            this.productservice.getProduct(item.packageProduct.product.id).subscribe(res1 => {
              res1.productMemberType.forEach((item1, index1) => {
                if (this.membertype == item1.type.toLowerCase()) {//string.toLowerCase( )
                  this.packageproduct.push(item);
                }
              })
              if (res1.subProducts.length > 0) {
                this.packageproduct.push(item);
              }
            });
          }
          if (item.packageProduct.product.iseventproduct == 'Y') {
            this.packageproduct.push(item);
          }

        })
        if (this.packageproduct.length == 0)
          this.extsubmitflag = false;
        else
          this.extsubmitflag = true;
      }
      else {
        this.packageproduct = res;
      }
    })

    this.packagelist.forEach((item, index) => {
      if (item.id == data) {
        this.funeralAmount = item.funeralExpense;
        this.totalAmount = item.totalAmount;
      }
    })
    this.age = this.personFormGroup.get('age').value;
    this.common.PackagePremiumCalculation(planid, data, this.vendorregion, 3, this.age, this.clientid.plantype.id, this.membertype).subscribe(res => {
      this.Premium = res;
      this.TotalPremium = res.mainmember;
    })
  }
}
