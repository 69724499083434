import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private api: ApiService, private auth: AuthService) { }

  GetAllAuditLogs(pagenumber, keyword): Observable<AuditLogs>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<AuditLogs>("/log/AuditLogs?page="+(pagenumber+1)+"&search="+keyword, header);
  }

  GetAllLogs(pagenumber, keyword): Observable<Logs>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Logs>("/log/Logs?page=" + (pagenumber + 1) + "&search=" + keyword, header);
  }
}

export interface AuditLog{
  id: number;
  tableName: string;
  dateTime: string;
  keyValues: string;
  oldValues: string;
  newValues: string;
  changedby: number;
  message: string;
}

export interface AuditLogs{
  logs: AuditLog[];
  totalCount: number;
  pageSize: number;
}

export interface Log{
  id: number,
  message: string,
  messageTemplate: string,
  level: string,
  timeStamp: string,
  exception: string,
  logEvent: string,
  properties: string
}

export interface Logs{
  logs: Log[];
  totalCount: number;
  pageSize: number;
}
