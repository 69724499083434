import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth/auth.service';
import { Plugins } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'Funeral Hub Connect';
  isAuthorized: boolean;
  isAppLoaded: boolean;
  removePrebootScreen: boolean;

  constructor(
    private translate: TranslateService,
    public auth: AuthService
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.auth.init();
    this.auth.isAuthStatus.subscribe(res => {
      setTimeout(() => this.isAppLoaded = true, 1000);
      setTimeout(() => this.removePrebootScreen = true, 1500);
      this.isAuthorized = res;
    });
  }
}
