import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { VendorCommission, VendorService } from '../../services/vendor.service';

@Component({
  selector: 'app-pay-fu-commission',
  templateUrl: './pay-fu-commission.component.html',
  styleUrls: ['./pay-fu-commission.component.scss']
})
export class PayFuCommissionComponent implements OnInit {

  lastCommission: VendorCommission;
  PyaCommissionForm: FormGroup;
  CommissionFilter: FormGroup;
  isloading: boolean;
  filtered: boolean = false;
  saving: boolean;
  vendorId;
  timestamp;

  constructor(
    public _formbuilder: FormBuilder,
    private service: VendorService,
    public dialogRef: MatDialogRef<PayFuCommissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ''
  ) { 
    this.vendorId = data['fuid'];
    this.timestamp = data['timestamp'];
  }

  ngOnInit() {
    this.isloading = true;
    this.service.commissionLastPaid(this.vendorId)
      .pipe(
        finalize(() => {
          this.isloading = false;
        })
      )
      .subscribe(res => {
        this.lastCommission = res;
        if(res){
          this.CommissionFilter = this._formbuilder.group({
            from: [res.timestamp],
            to: [(new Date()).toISOString()],
          });
        }
        else{
          this.CommissionFilter = this._formbuilder.group({
            from: [this.timestamp],
            to: [(new Date()).toISOString()],
          });
        }
      });    
  }

  calculateCommission(data){
    this.service.calculatecommission(this.vendorId, data)
      .pipe(
        finalize(() => {
          this.filtered = true;
        })
      )
      .subscribe(res => {
        this.PyaCommissionForm = this._formbuilder.group({
          amount: [res, [Validators.required, Validators.min(1)]],
          paymentMethod: ['']
        });
      });
  }

  onSubmit(data: VendorCommission){
    data.vendorId = this.vendorId;
    this.saving = true;
    this.service.AddCommission(data)
    .pipe(
      finalize(() => {
        this.saving = false;
      })
    )
    .subscribe(res => {
      this.dialogRef.close(true);
    });
  }

  cancel(){
    this.dialogRef.close(false);
  }
}
