import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
//import { ChartsModule } from '@progress/kendo-angular-charts';
import { ChartsModule as Ng2Charts } from 'ng2-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MaterialModule } from '../material/material.module'
import { ChangePasswordComponent } from '../shared/change-password/change-password.component';
import { EmptyPipe } from './pipes/empty.pipe';
import { ConfirmPopupComponent } from '../shared/confirm-popup/confirm-popup.component';
import { ResetPasswordComponent } from '../shared/reset-password/reset-password.component';
import { PaynowComponent } from '../shared/paynow/paynow.component';
import { ChangeInceptiondateComponent } from '../shared/change-inceptiondate/change-inceptiondate.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';

import { AddBeneficiaryComponent } from './add-beneficiary/add-beneficiary.component';
import { AddClientComponent } from './add-client/add-client.component';
import { AddCorporateComponent } from './add-corporate/add-corporate.component';
import { AddEmpBeneficiaryComponent } from './add-emp-beneficiary/add-emp-beneficiary.component';
import { AddEmpSubmemberComponent } from './add-emp-submember/add-emp-submember.component';
import { AddExtendedmemberComponent } from './add-extendedmember/add-extendedmember.component';
import { AddFuComponent } from './add-fu/add-fu.component';
import { AddLicenseComponent } from './add-license/add-license.component';
import { AddPlanProductComponent } from './add-plan-product/add-plan-product.component';
import { AddProductComponent } from './add-product/add-product.component'
import { AddProductOptionComponent } from './add-product-option/add-product-option.component';
import { AddProposalComponent } from './add-proposal/add-proposal.component'
import { AddSubmemberComponent } from './add-submember/add-submember.component';
import { AssignPlanComponent } from './assign-plan/assign-plan.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ClaimdeclineComponent } from './claimdecline/claimdecline.component';
import { ClaimpaymentComponent } from './claimpayment/claimpayment.component';
import { DeactivatePlanComponent } from './deactivate-plan/deactivate-plan.component';
import { EditBankdetailsComponent } from './edit-bankdetails/edit-bankdetails.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { ImportFuComponent } from './import-fu/import-fu.component'
import { PayFuCommissionComponent } from './pay-fu-commission/pay-fu-commission.component';
import { ProductSettingComponent } from './product-setting/product-setting.component';
import { ReactivatePlanComponent } from './reactivate-plan/reactivate-plan.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component'
import { PopupComponent } from './popup/popup.component';
import { EditClientComponent } from './common/edit-client.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MenuListItemComponent } from './sidebar/menu-list-item/menu-list-item.component';
import { TopnavComponent } from './topnav/topnav.component';
import { NotitificationsComponent } from './notitifications/notitifications.component';
import { UpdateCommissionValueComponent } from '../admin/FU/update-commission-value/update-commission-value.component';
import { ProvinceForm, TownForm, BenefitForm } from './location/forms';
import { PersonalInfoComponent } from './forms/personal-info/personal-info.component';
import { PersonalInfoFormComponent } from './popup/personal-info-form/personal-info-form.component';
import { SelectPlanComponent } from './select-plan/select-plan.component';
import { PlanSelectionComponent } from './forms/plan-selection/plan-selection.component';
import { UnderwritingQuestionComponent } from './underwriting-question/underwriting-question.component';
import { VersionComponent } from './help/version/version.component';
import { TopNotificationComponent } from './popup/top-notification/top-notification.component';
import { ViewPackageComponent } from '@app/admin/corporate/view-package/view-package.component';
import { ChangeEmailidComponent } from './change-emailid/change-emailid.component';
import { ImportCorporateEmployeeComponent } from './import-corporate-employee/import-corporate-employee.component';
import { ErrorReportComponent } from './error-report/error-report.component';

@NgModule({
  declarations: [
    ChangePasswordComponent,
    EmptyPipe,
    DynamicFormComponent,
    ConfirmPopupComponent,
    ResetPasswordComponent,
    PaynowComponent,
    ChangeInceptiondateComponent,
    AddBeneficiaryComponent,
    AddClientComponent,
    AddCorporateComponent,
    AddEmpBeneficiaryComponent,
    AddEmpSubmemberComponent,
    AddExtendedmemberComponent,
    AddFuComponent,
    AddLicenseComponent,
    AddPlanProductComponent,
    AddProductComponent,
    AddProductOptionComponent,
    AddProposalComponent,
    AddSubmemberComponent,
    AssignPlanComponent,
    CalendarComponent,
    ClaimdeclineComponent,
    ClaimpaymentComponent,
    DeactivatePlanComponent,
    EditBankdetailsComponent,
    EditProductComponent,
    ErrorPopupComponent,
    ImportFuComponent,
    PayFuCommissionComponent,
    ProductSettingComponent,
    ReactivatePlanComponent,
    UploadDocumentComponent,
    PopupComponent,
    EditClientComponent,
    SidebarComponent,
    TopnavComponent,
    NotitificationsComponent,
    MenuListItemComponent,
    UpdateCommissionValueComponent,
    ProvinceForm,
    TownForm,
    BenefitForm,
    PersonalInfoComponent,
    PersonalInfoFormComponent,
    SelectPlanComponent,
    PlanSelectionComponent,
    UnderwritingQuestionComponent,
    VersionComponent,
    TopNotificationComponent,
    ViewPackageComponent,
    ChangeEmailidComponent,
    ImportCorporateEmployeeComponent,
    ErrorReportComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ContextMenuModule.forRoot({ useBootstrap4: true }),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    //ChartsModule,
    Ng2Charts,
    ReactiveFormsModule,
    InfiniteScrollModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ContextMenuModule,
    //ChartsModule,
    Ng2Charts,
    ChangePasswordComponent,
    EmptyPipe,
    MaterialModule,
    CalendarModule,
    DynamicFormComponent,
    InfiniteScrollModule,
    ConfirmPopupComponent,
    ResetPasswordComponent,
    AddBeneficiaryComponent,
    AddClientComponent,
    AddCorporateComponent,
    AddEmpBeneficiaryComponent,
    AddEmpSubmemberComponent,
    AddExtendedmemberComponent,
    AddFuComponent,
    AddLicenseComponent,
    AddPlanProductComponent,
    AddProductComponent,
    AddProductOptionComponent,
    AddProposalComponent,
    AddSubmemberComponent,
    AssignPlanComponent,
    CalendarComponent,
    ClaimdeclineComponent,
    ClaimpaymentComponent,
    DeactivatePlanComponent,
    EditBankdetailsComponent,
    EditProductComponent,
    ErrorPopupComponent,
    ImportFuComponent,
    PayFuCommissionComponent,
    ProductSettingComponent,
    ReactivatePlanComponent,
    UploadDocumentComponent,
    PopupComponent,
    EditClientComponent,
    SidebarComponent,
    TopnavComponent,
    PaynowComponent,
    ChangeInceptiondateComponent,
    NotitificationsComponent,
    MenuListItemComponent,
    UpdateCommissionValueComponent,
    ProvinceForm,
    TownForm,
    BenefitForm,
    PersonalInfoComponent,
    PersonalInfoFormComponent,
    SelectPlanComponent,
    PlanSelectionComponent,
    UnderwritingQuestionComponent,
    ChangeEmailidComponent,
    ViewPackageComponent,
    ImportCorporateEmployeeComponent,
    ErrorReportComponent
  ],
  entryComponents: [
    ChangePasswordComponent,
    ConfirmPopupComponent,
    ResetPasswordComponent,
    AddBeneficiaryComponent,
    AddClientComponent,
    AddCorporateComponent,
    AddEmpBeneficiaryComponent,
    AddEmpSubmemberComponent,
    AddExtendedmemberComponent,
    AddFuComponent,
    AddLicenseComponent,
    AddPlanProductComponent,
    AddProductComponent,
    AddProductOptionComponent,
    AddProposalComponent,
    AddSubmemberComponent,
    AssignPlanComponent,
    CalendarComponent,
    ClaimdeclineComponent,
    ClaimpaymentComponent,
    DeactivatePlanComponent,
    EditBankdetailsComponent,
    EditProductComponent,
    ErrorPopupComponent,
    ImportFuComponent,
    PayFuCommissionComponent,
    ProductSettingComponent,
    ReactivatePlanComponent,
    UploadDocumentComponent,
    PaynowComponent,
    ChangeInceptiondateComponent,
    UpdateCommissionValueComponent,
    PopupComponent,
    PersonalInfoFormComponent,
    SelectPlanComponent,
    UnderwritingQuestionComponent,
    ViewPackageComponent,
    ChangeEmailidComponent,
    ImportCorporateEmployeeComponent,
    ErrorReportComponent
  ]
})
export class SharedModule { }
