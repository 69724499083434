import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from '../../services/product.service';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  productFormGroup: FormGroup;
  imageURL = '/assets/images/avatar.png';
  isenable = true;
  supplierid;
  fileToUpload;
  productList;
  categoryList;
  isloaded = false;
  profileerrormsg;
  supplierproduct;
  productoptionlist = [];
  ProductValueList;
  fileerror;
  path;

  constructor(
    public account: AccountService,
    public _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
    public productservice: ProductService) {
    this.supplierid = data;
  }

  ngOnInit() {
    this.productFormGroup = this._formBuilder.group({
      imageUrl: [''],
      productId: ['', Validators.required],
      productOption: this._formBuilder.array([]),
      quantity: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.required])],
      price: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.required])]
    });
    this.productservice.getEventProducts().subscribe(res => {
      this.productList = res;
      this.isloaded = true;
    });
  }

  productoption() {
    return this._formBuilder.group({
      productOptionId: [''],
      productOptionValueId: ['']
    })
  }

  addProdOption() {
    var option = this.productFormGroup.get('productOption') as FormArray;
    option.push(this.productoption());
  }

  removeProdOption(i) {
    var option = this.productFormGroup.get('productOption') as FormArray;
    option.removeAt(i);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  getProductOptionList(value) {
    this.productoptionlist = [];
    this.productList.products.forEach((item, index) => {
      if (item.id == value) {
        if (item.productOptions.length > 0) {
          this.productoptionlist = item.productOptions;
        }
        else {
          this.productoptionlist = [];
        }
      }
    })
    if (this.productoptionlist.length > 0) {
      this.addProdOption();
    }
    else {
      var option = this.productFormGroup.get('productOption') as FormArray;
      if (option.length > 0) {
        for (var a = option.length; a >= 0; a--) {
          option.removeAt(a);
        }
      }
    }
  }
  
  getProductValueList(data, id) {
    var select = document.getElementById(id) as HTMLSelectElement;
    this.productservice.getProductOptionValue(data).subscribe(res => {
      this.ProductValueList = res;
      var i;
      for (i = select.options.length - 1; i >= 1; i--) {
        select.remove(i);
      }
      this.ProductValueList.forEach((item, index) => {
        var opt = document.createElement("option");
        opt.value = item.id;
        opt.text = item.prodOptionValue;
        select.add(opt, 1);
      });
    });
  }

  onSubmit(data) {
    data.imageUrl = this.path;
    var test = data.productOption.length;
    if (test > 0) {
      for (var a = test-1; a >= 0; a--) {
        if (data.productOption[a].productOptionId == "" || data.productOption[a].productOptionValueId == "")
          data.productOption.pop(a);
      }
    }
    this.dialogRef.close(data);
  }

  profileupload(file: FileList, files) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.account.uploadimage(formData).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }
}
