import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { Province, Town } from '../../models/account/common';
import { ApiResponse } from '../../models/ApiResponse.model';

@Component({
  selector: 'province-form',
  templateUrl: 'province-form.html',
})
export class ProvinceForm implements OnInit {
  _provinceForm: FormGroup;
  error: ApiResponse;
  constructor(
    private bottomSheetRef: MatBottomSheetRef<ProvinceForm>, 
    private _formBuilder: FormBuilder, 
    private _commonservice: CommonService
  ) { }

  saveProvince(data: Province) {
    this._commonservice.addProvince(data).subscribe(res => {
      this.bottomSheetRef.dismiss(res);
    }, err => {
      this.error = err.error;
      this._commonservice.openSnackBar(this.error.reasonPhrase, "Close");
    });
  }

  ngOnInit() {
    this._provinceForm = this._formBuilder.group({
      name: ['', Validators.required]      
    });
  }

}

@Component({
  selector: 'town-form',
  templateUrl: 'town-form.html',
})
export class TownForm implements OnInit {
  _townForm: FormGroup;
  error: ApiResponse;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<TownForm>,
    private _formBuilder: FormBuilder,
    private _commonservice: CommonService
  ) {
  }

  saveTown(formdata: Town) {
    formdata.provinceId = this.data.Id;    
    this._commonservice.addTown(formdata).subscribe(res => {
      this.bottomSheetRef.dismiss(formdata);
    }, err => {
      this.error = err.error;
      this._commonservice.openSnackBar(this.error.reasonPhrase, "Close");
    });
  }

  ngOnInit() {
    this._townForm = this._formBuilder.group({
      name: ['', Validators.required]
    });
  }

}

@Component({
  selector: 'benefit-form',
  templateUrl: 'benefit-form.html',
})
export class BenefitForm implements OnInit {
  _benefitForm: FormGroup;
  error: ApiResponse;
  flag = false;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<BenefitForm>,
    private _formBuilder: FormBuilder,
    private _commonservice: CommonService
  ) {

  }

  saveBenefit(data) {
    var benefit = {
      name: data.name,
      benefitAdditionalOptions: data.benefitAdditionalOptions
    }
    this._commonservice.addBenefit(benefit).subscribe(res => {
      this.bottomSheetRef.dismiss(benefit);
    }, err => {
      this.error = err.error;
      this._commonservice.openSnackBar(this.error.reasonPhrase, "Close");
    });
  }

  ngOnInit() {
    this._benefitForm = this._formBuilder.group({
      name: ['', Validators.required],
      isChecked:['']
    });
  }

  check(data) {
    if (data.checked) {
      this.flag = true;
      this._benefitForm.addControl("benefitAdditionalOptions", this._formBuilder.array([this.createbenefit()]));
    }
    else{
      this.flag = false;
      this._benefitForm.removeControl("benefitAdditionalOptions");
    }
  }

  createbenefit() {
    return this._formBuilder.group({
      name: ['', Validators.required]
    });
  }

  addBenefit() {
    var benefit = this._benefitForm.get('benefitAdditionalOptions') as FormArray;
    benefit.push(this.createbenefit());
  }

  removeBenefit(i: number) {
    var benefit = this._benefitForm.get('benefitAdditionalOptions') as FormArray;
    benefit.removeAt(i);
  }
}
