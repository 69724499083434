import { isSameDay } from 'date-fns';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonService, ClientPlan, extenedMemberPlan, VendorService, ClientService, PlanService } from '../../../services';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelectPlanComponent } from '../../select-plan/select-plan.component';
import { UnderwritingQuestionComponent } from '../../underwriting-question/underwriting-question.component';
import { MatDialog } from '@angular/material';
import moment from 'moment-timezone';

@Component({
  selector: 'plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss']
})
export class PlanSelectionComponent implements OnInit {

  @Input() extendedmemberdata = [];
  @Input() clientdata;
  @Input() vendor;
  @Input() doneby;
  @Input() plantypedetails;
  @Input() planstatus = 1;
  @Output() result = new EventEmitter();
  Saving;
  planForm: FormGroup;
  clientPlan: ClientPlan;
  extendedMembersPlan: extenedMemberPlan[] = [];
  totalpremiumamount = 0;
  mainmemberpackageamount = 0;
  mainmemberage;
  userplantype = 1;
  vendorregion;
  plansubmiterror;
  questions;
  questionflag = false;

  constructor(
    public dialog: MatDialog,
    public common: CommonService,
    public vendorservice: VendorService,
    public clientservice: ClientService,
    public planservice: PlanService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.initialdata();
    this.getplandetails();
    this.getbankdetails();
  }

  initialdata(){
    this.vendor.addresses.forEach((item, index) => {
      if (item.isPrimary) {
        this.vendorregion = item.address.state;
      }
    });
    this.mainmemberage = this.common.calculateage(this.clientdata.dob);
    if (this.plantypedetails) {
      this.userplantype = this.plantypedetails.planType;
    }
  }

  getplandetails() {
    for (let ex = 0; ex < this.extendedmemberdata.length; ex++){
      const extendedMemberUserId = this.extendedmemberdata[ex].extendedMemberUserId;
      const extage = this.common.calculateage(this.extendedmemberdata[ex].extendedMemberUser.dob);
      this.extendedMembersPlan.push({
        clientId: extendedMemberUserId,
        planId: 0,
        premiumAmount: 0,
        age: extage,
        planPackages: [],
        packageId: 0,
        questions: [],
        status: 1
      });
    }
    this.clientPlan = {
      planId: 0,
      inceptionDate: new Date(),
      dateOfDeduction: '',
      startMonth: '',
      typeOfPayment: '',
      premium: { premiumAmount: 0 },
      extendedMemberPlans: this.extendedMembersPlan,
      packages: [],
      status: 1,
      packageId: 0,
      questions: []
    };
    this.planForm = this._formBuilder.group({
      typeOfPayment: ['', Validators.required],
      dateOfDeduction: ['', Validators.required],
      startMonth: ['', Validators.required]
    });
  }

  getplans() {
  }

  selectPlan(type, age, index) {
    this.totalpremiumamount = 0;
    let membertype;
    if (type == 3) {
      membertype = 'extendedmember';
    }
    else {
      membertype = 'mainmember';
    }
    const dialogRef = this.dialog.open(SelectPlanComponent, {
      width: '450px',
      data: {
        region: this.vendorregion,
        vendorId: this.vendor.id,
        planType: this.plantypedetails,
        age: age,
        type: type,
        totalAmount: this.mainmemberpackageamount,
        membertype: membertype
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.Saving = false;
        if (type == 3) {
          this.extendedMembersPlan[index].status = this.planstatus;
          this.extendedMembersPlan[index].planId = result.plan.planId;
          this.extendedMembersPlan[index].premiumAmount = result.TotalPremium;
          this.extendedMembersPlan[index].packageId = result.package;
          this.extendedMembersPlan[index].planPackages.push({ packageId: result.package, packageProducts: result.packageProducts})
        } else {
          if (this.clientPlan.packages.length !== 0) {
            this.extendedMembersPlan = [];
            if (this.extendedmemberdata.length > 0) {
              for (let ex = 0; ex < this.extendedmemberdata.length; ex++) {
                const extendedMemberUserId = this.extendedmemberdata[ex].extendedMemberUserId;
                const extage = this.common.calculateage(this.extendedmemberdata[ex].extendedMemberUser.dob);
                this.extendedMembersPlan.push({
                  clientId: extendedMemberUserId,
                  planId: 0,
                  premiumAmount: 0,
                  age: extage,
                  planPackages: [],
                  packageId: 0
                });
              }
            }
            this.clientPlan.extendedMemberPlans = this.extendedMembersPlan;
          }
          this.questionflag = false;
          this.clientPlan.planId = result.plan.planId;
          this.clientPlan.packageId = result.package;
          this.clientPlan.premium.premiumAmount = result.TotalPremium;
          this.clientPlan.packages = [];
          this.clientPlan.packages.push(
            { packageId: result.package, packageProducts: result.packageProducts, clientId: this.clientdata.id }
          );
          this.mainmemberpackageamount = result.totalAmount;
          this.getquestion(this.clientPlan.packageId);
        }
        this.totalpremiumamount = this.totalpremiumamount + this.clientPlan.premium.premiumAmount;
        this.extendedMembersPlan.forEach(ele => {
          this.totalpremiumamount = ele.premiumAmount + this.totalpremiumamount;
        })
        
      }
    });
  }

  paymentmethod(value) {
    if (value == 1){
      this.planForm.addControl('bank', this.Createbankdetails());
    }
    if (value == 0){
      this.planForm.removeControl('bank');
    }
  }

  getbankdetails() {
    this.clientservice.GetMyBankDetails().subscribe(res => {
      if (res) {
        this.planForm.get('typeOfPayment').setValue('1');
        this.planForm.addControl('bank',
         this._formBuilder.group({
          bankName: [res.bankDetails.bankName, Validators.required],
          accountNo: [res.bankDetails.accountNo, Validators.required],
          typeOfAccount: [res.bankDetails.typeOfAccount, Validators.required],
          accountHolderName: [res.bankDetails.accountHolderName, Validators.required],
          branch: [res.bankDetails.branch, Validators.required],
          branchCode: [res.bankDetails.branchCode, Validators.required]
         }));
      }
    });
  }

  Createbankdetails() {
    return this._formBuilder.group({
      bankName: ['', Validators.required],
      accountNo: ['', Validators.required],
      typeOfAccount: ['', Validators.required],
      accountHolderName: ['', Validators.required],
      branch: ['', Validators.required],
      branchCode: ['', Validators.required]
    });
  }

  planSubmit(data) {
    let totalpremium = 0;
    this.plansubmiterror = '';
    if (this.clientPlan.planId == 0){
      this.plansubmiterror = 'Please select plan for all the members';
      return;
    } else {
      this.extendedMembersPlan.forEach(ele => {
        if (ele.planId == 0){
          this.plansubmiterror = 'Please select plan for all the members';
          return;
        }
        totalpremium = ele.premiumAmount + totalpremium;
      });
    }
    totalpremium = this.clientPlan.premium.premiumAmount + totalpremium;
    this.clientPlan.startMonth = data.startMonth;
    this.clientPlan.dateOfDeduction = data.dateOfDeduction;

    this.clientPlan.typeOfPayment = data.typeOfPayment;
    this.clientPlan.status = this.planstatus;
    this.clientPlan.premium.totalPremiumAmount = totalpremium;
    this.clientPlan.premium.clientId = this.clientdata.id;
    

    const currentdate = new Date();
    const month = data.startMonth - 1;
    const date = +data.dateOfDeduction + 1;
    let day: Date;
    day = new Date(currentdate.getFullYear(), month, date);
    if (currentdate > day) {
      day = new Date(currentdate.getFullYear() + 1, month, date);
    }
    let mondiff;
    if (currentdate < day){
      mondiff = this.monthDiff(
        new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate()),
        new Date(day.getFullYear(), day.getMonth(), day.getDate())
      );
    }
    this.clientPlan.inceptionDate = day;
    this.clientPlan.premium.nextDue = day;
    if (mondiff > 3) {
      this.plansubmiterror = 'Enter a valid data and month for date of Deduction';
    } else {
      this.clientPlan.policyStatus = 0;
      this.Saving = true;
      if (this.doneby == 'vendor') {
        this.clientservice.saveCilentPlan(this.clientdata.id, this.clientPlan).subscribe(res => {
          this.common.openSnackBar('Plan details saved', 'Close');
          if (data.typeOfPayment == 1) {
            this.onbankSubmit(data.bank);
          } else {
            this.result.emit(true);
          }
        });
      } else {
        this.clientservice.saveMyPlan(this.clientPlan).subscribe(res => {
          if (data.typeOfPayment == 1) {
            this.onbankSubmit(data.bank);
          } else {
            this.result.emit(true);
          }
        });
      }
    }

  }

  onbankSubmit(data) {
    if (this.doneby == 'vendor') {
      this.clientservice.SaveClientBankDetails(this.clientdata.id, data).subscribe(res => {
        this.Saving = false;
        this.result.emit(true);
      });
    } else {
      this.clientservice.SaveMyBankDetails(data).subscribe(res => {
        this.Saving = false;
        this.result.emit(true);
      });
    }
  }

  monthDiff(d1, d2) {
    const diff = moment([d2.getFullYear(), d2.getMonth(), d2.getDate()]).diff(moment([d1.getFullYear(), d1.getMonth(), d1.getDate()]), 'months', true);
    const value = Math.round(diff * 10) / 10;
    return value;
  }

  question(packageId, clientId, type) {
    const dialogRef = this.dialog.open(UnderwritingQuestionComponent, {
      width: '450px',
      data: {
        question: this.questions,
        clientId: clientId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type == 1){
          this.clientPlan.questions = result.questions;
        }
        if (type == 3) {
          this.extendedMembersPlan.forEach((item, index) => {
            if (item.clientId == clientId) {
              item.questions = result.questions;
            }
          });
        }
      }
    });
  }

  get totalpremium() {
    let total = this.clientPlan.premium.premiumAmount;
    const extmembers = this.extendedMembersPlan;
    extmembers.forEach(function(val) {
      total += val.premiumAmount;
    });
    return total;
  }

  getquestion(packageId) {
    this.planservice.getQuestion(packageId).subscribe(res => {
      this.questions = res;
      if (this.questions.length == 0) {
        this.questionflag = false;
      }
      else {
        this.questionflag = true;
      }
    });
  }
}
