import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { AddCorporateDto } from '@app/models/corporate/corporate';
import { CorporateService } from '@app/services';

export interface DialogData {
  firstname: string;
  email: string;
  vendorId: number;
  action: string;
}


@Component({
  selector: 'app-add-corporate',
  templateUrl: './add-corporate.component.html',
  styleUrls: ['./add-corporate.component.css']
})
export class AddCorporateComponent implements OnInit {

  addCorporate: AddCorporateDto;
  result;
  errormsg;
  isenable = true;
  saving: boolean = false;
  companyNameError: boolean;
  
  constructor(public dialogRef: MatDialogRef<AddCorporateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private corporateService: CorporateService) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.data.email = '';
    this.data.firstname = '';
    this.dialogRef.close();
  }

  checkCompanyName(email, company){    
    this.isenable = false;
    this.companyNameError = false;
    this.corporateService.checkCompanyName(email, company).subscribe(res => {

      if(res.succeeded == false){
        this.companyNameError = true;      
        this.isenable = false;      
      }
      if(res.succeeded == true){
        this.companyNameError = false;
        this.isenable = true;
      }
    });
  }

  onSubmit(form) {
    this.isenable = false;
    this.addFU(form, this.data.vendorId, this.data.action);
  }

  addFU(data, vendorId, action) {    
    this.addCorporate = data;
    this.saving = true;
    this.corporateService.addCorporate(this.addCorporate, vendorId, action)
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe(response => {
      this.result = response;
      if (this.result.statusCode == 200) {
        this.dialogRef.close(true);

      }
    }, (err) => {
      if (err.error.errors)
        this.errormsg = err.error.errors[0].description;
      else
        this.errormsg = err.error.Email[0];
    });
  }

  onChange() {
    this.isenable = true;
  }
}
