import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { Categories, Category, Products, Product } from '../models/supplier/supplier';

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  constructor(private api: ApiService, private auth: AuthService) { }

  //CATEGORY API

  getCategories(): Observable<Categories> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Categories>("/Product/categories/", header);
  }

  getCategory(id): Observable<Category> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Category>("/Product/category/" + id, header);
  }

  addCategory(category): Observable<Category> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<Category>("/Product/category/", category, header);
  }

  updateCategory(category, id): Observable<Category> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<Category>("/Product/category/" + id, category, header);
  }

  deleteCategory(id): Observable<Category> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Category>("/Product/category/" + id, header);
  }
  getCategoryBasedProduct(id): Observable<Products> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Products>("/Product/category/" + id + "/productList", header);
  }
  getEventProducts(): Observable<Products> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Products>("/Product/eventproduct", header);
  }
  getBenefitProducts(): Observable<Products> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Products>("/Product/benefitproduct", header);
  }

  getEventProductList(page, keyword): Observable<Products> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Products>("/Product/eventproduct/list?page="+(page+1)+"&search="+keyword, header);
  }
  getBenefitProductList(page, keyword): Observable<Products> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Products>("/Product/benefitproduct/list?page="+(page+1)+"&search="+keyword, header);
  }

  //PRODUCT API

  getProducts(): Observable<Products> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Products>("/Product/", header);
  }

  getProduct(id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Product>("/Product/" + id, header);
  }

  addProduct(product): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<Product>("/Product/", product, header);
  }

  addbenefitProduct(product): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<Product>("/Product/benefitproduct/", product, header);
  }

  updateProduct(category, id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<Product>("/Product/" + id, category, header);
  }

  deleteProduct(id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/" + id, header);
  }

  deleteProductOption(id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/productoption/" + id, header);
  }

  //supplier product

  getSupplierProducts(supplierid, pageno): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Product/SupplierProducts/" + supplierid + "?page=" + pageno, header);
  }

  getSupplierProduct(Id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Product/SupplierProduct/" + Id , header);
  }

  addsupplierProduct(product): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Product/SupplierProducts/", product, header);
  }

  updatesupplierProduct(supplierid, productid, product): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Product/SupplierProducts/" + supplierid + "/" + productid, product, header);
  }

  deleteSupplierProduct(supplierid, productid): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/SupplierProducts/" + supplierid + "/" + productid, header);
  }

  //Product Option

  getAllProductOption(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Product/getProductOption/", header);
  }

  //Product Option Value

  getAllProductOptionValue(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Product/getProductOptionValue/", header);
  }

  getProductOptionValue(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Product/productOptionValue/" + id, header);
  }

  deleteProductOptionValue(productoptionvalueid): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/productoptionvalue/" + productoptionvalueid, header);
  }

  deleteBenefitOption(id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/benefitoption/" + id, header);
  }

  deleteSubproduct(id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/Subproduct/" + id, header);
  }

  deleteQuestion(id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/benefitquestion/" + id, header);
  }

  deleteDocList(id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/DocChecklist/" + id, header);
  }

  deletemembertype(id): Observable<Product> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Product>("/Product/MemberType/" + id, header);
  }

  alreadyproductused(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Product/AlreadyProductUsed/" + id, header);
  }
}
