import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { myaccount, changePassword } from '../models/account/common';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }

  uploadfile(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Uploader/", data, header);
  }

  deleteDocument(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Uploader/" + id, header);
  }

  uploadimage(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Uploader/UploadImage", data, header);
  }

  getimage(fileid): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Uploader/image?fileId=" + fileid, header);
  }


  saveDocument(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/DocumentCenter/", data, header);
  }

  // all user document
  getDocument(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/DocumentCenter/", header);
  }

  // vendor user document
  getMeDocument(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/DocumentCenter/me/", header);
  }

  deleteUserDocument(documentId, path): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/DocumentCenter/" + documentId + "/" + path, header);
  }


  updateaccount(client: myaccount): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Account/myinfo/", client, header);
  }

  getaccount(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Account/me/", header);
  }

  getstartStatus(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Account/getstartStatus/", header);
  }

  addstartStatus(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Account/AddStartStatus/", data, header);
  }

  vendorgetstart(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Account/vendorGetStart/", data, header);
  }

  changePassword(data: changePassword): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Account/changePassword/", data, header);
  }

  profileImgUpload(data, id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Account/ProfileUploadImg/" + id, data, header);
  }

  logoImgUpload(data, id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/GlobalSettings/uploadlogo", data, header);
  }
  getLogo() {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/getlogo", header);
  }

  emailupdate(email): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Account/UpdateEmailId/" + email, "", header);
  }

  getStaffMenu(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Account/StaffMenu/", header);
  }
}
