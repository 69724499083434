import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module'
import { VendorProfileComponent } from './profile/vendor-profile/vendor-profile.component';
import { AddressViewComponent } from './common/address-view/address-view.component';
import { BankDetailsComponent } from './common/bank-details/bank-details.component';
import { ClientProfileComponent } from './profile/client-profile/client-profile.component';
import { SupplierProfileComponent } from './profile/supplier-profile/supplier-profile.component';
import { StatComponent } from './common/stat/stat.component';
import { RouterModule } from '@angular/router';
import { StaffProfileComponent } from './profile/staff-profile/staff-profile.component';
import { SubmemberProfileComponent } from './profile/submember-profile/submember-profile.component';
import { ExtendedmemberProfileComponent } from './profile/extendedmember-profile/extendedmember-profile.component';
import { SupplierProductListComponent } from './common/supplier-product-list/supplier-product-list.component';
import { EmployeerProfileComponent } from './profile/employeer-profile/employeer-profile.component';
import { EmployeerEmployeeProfileComponent } from './profile/employeer-employee-profile/employeer-employee-profile.component';
import { EmployeerEmployeeSubmemberComponent } from './profile/employeer-employee-submember/employeer-employee-submember.component';

@NgModule({
  declarations: [
    VendorProfileComponent,
    AddressViewComponent,
    BankDetailsComponent,
    ClientProfileComponent,
    SupplierProfileComponent,
    StatComponent,
    StaffProfileComponent,
    SubmemberProfileComponent,
    ExtendedmemberProfileComponent,
    SupplierProductListComponent,
    EmployeerProfileComponent,
    EmployeerEmployeeProfileComponent,
    EmployeerEmployeeSubmemberComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    VendorProfileComponent,
    ClientProfileComponent,
    SupplierProfileComponent,
    AddressViewComponent,
    BankDetailsComponent,
    StatComponent,
    StaffProfileComponent,
    SubmemberProfileComponent,
    ExtendedmemberProfileComponent,
    SupplierProductListComponent,
    EmployeerProfileComponent,
    EmployeerEmployeeProfileComponent,
    EmployeerEmployeeSubmemberComponent,
  ]
})
export class ViewsModule { }
