import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpRequest, HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment-timezone';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth/auth.service';
import { MatDatepickerInputEvent } from '@angular/material';
import { CommonService } from '../../services/common.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DynamicFormComponent implements OnInit {

  @Input() dataObject;
  @Output() data = new EventEmitter();
  @ViewChild("fileInput", { static: true }) fileInput;
  objectProps;
  form: FormGroup;
  imageUrl: string = '/assets/images/avatar.png';
  isenable = true;
  dob = new FormControl(moment());
  maxDate = new Date();
  fileerror;
  path;
  filename;
  fileToUpload;
  authimageUrl;
  imageflag = false;
  age;

  constructor(private http: HttpClient, private location: Location, public account: AccountService, public auth: AuthService, public common: CommonService) {
  }

  ngOnInit() {
    this.authimageUrl = this.auth.imageURL;
    this.objectProps =
      Object.keys(this.dataObject)
        .map(prop => {
          return Object.assign({}, { key: prop }, this.dataObject[prop]);
        });

    const formGroup = {};
    for (let prop of Object.keys(this.dataObject)) {
      formGroup[prop] = new FormControl(this.dataObject[prop].value || '', this.mapValidators(this.dataObject[prop].validation));
    }

    this.form = new FormGroup(formGroup);
    if (this.form.controls['beneficiary'])
      this.form.controls["benefit"].disable();

    if (this.form.controls['profileImgPath'])
      if (this.form.controls['profileImgPath']['value'])
        this.imageflag = true;

    if (this.form.controls['age']) {
      this.form.controls["age"].disable();
      this.age = this.form.get('age').value;
    }

    this.form.valueChanges.subscribe(val => {
      this.onChange();
    });
  }

  private mapValidators(validators) {
    const formValidators = [];

    if (validators) {
      for (const validation of Object.keys(validators)) {
        if (validation === 'required') {
          formValidators.push(Validators.required);
        } else if (validation === 'min') {
          formValidators.push(Validators.min(validators[validation]));
        }
        else if (validation === 'minlength') {
          formValidators.push(Validators.minLength(validators[validation]));
        }
        else if (validation === 'maxlength') {
          formValidators.push(Validators.maxLength(validators[validation]));
        }
      }
    }

    return formValidators;
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  onSubmit(form) {
    this.isenable = false;
    if (this.form.controls['profileImgPath'])
      form.profileImgPath = this.path;

    if (this.form.controls['age'])
      form.age = this.age;

    this.data.emit(form);
    
  }

  onChange() {
    if (this.form.valid) {
      this.isenable = true;
    }
  }

  cancel() {
    this.form.reset();
    this.location.back();
  }

  bencheck(value) {
    if (value.checked) {
      this.form.controls["benefit"].enable();
    }
    else {
      this.form.controls["benefit"].disable();
      this.form.controls["benefit"].reset();
      this.onChange();
    }
  }

  profileupload(file: FileList, files) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    this.imageflag = false;
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.account.uploadimage(formData).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
          this.filename = res.filename;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  calculateage(type: string, event: MatDatepickerInputEvent<Date>) {
    this.age = this.common.calculateage(event.value);
    this.form.get('age').setValue(this.age);  
  }
}
