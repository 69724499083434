import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { DeviceDetectorModule } from 'ngx-device-detector';
//import { ChartsModule as Ng2Charts } from 'ng2-charts';

import {
  AuthModule,
  OidcSecurityService,
  OpenIDImplicitFlowConfiguration,
  OidcConfigService,
  AuthWellKnownEndpoints
} from 'angular-auth-oidc-client';

import { SharedModule } from './shared/shared.module';
import { ViewsModule } from './views/views.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { environment } from '../environments/environment';
import { MaterialModule } from './material/material.module';
import { AppLoadService } from './services/app-load.service';
import { LocalstorageService } from './services/localstorage.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';
import { AuthService } from './services/auth/auth.service';
import { AuthorizeComponent } from './authorize/authorize.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { LoginComponent } from './login/login.component';
//import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { ErrorInterceptor } from './services/auth/error.interceptor';
import { ThemeService } from 'ng2-charts';


export const createTranslateLoader = (http: HttpClient) => {
  /* for development
  return new TranslateHttpLoader(
      http,
      '/start-javascript/sb-admin-material/master/dist/assets/i18n/',
      '.json'
  );*/
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const oidc_configuration = './assets/auth.clientConfiguration.json';

export function loadConfig(oidcConfigService: OidcConfigService) {
  console.log(oidc_configuration);
  return () => oidcConfigService.load(oidc_configuration);
}
export function init_app(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizeComponent,
    AuthorizeComponent,
    UpdateUserComponent,
    LoginComponent,
    PaymentStatusComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot({ storage: LocalstorageService }),
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MaterialModule,
    DeviceDetectorModule.forRoot(),
    InfiniteScrollModule,
    SharedModule,
    ViewsModule,
    //Ng2Charts,
    //ChartsModule
  ],
  providers: [
    AuthService,
    AppLoadService,
    OidcConfigService,
    LocalStoreManager,
    InAppBrowser,
    ThemeService,
    { provide: APP_INITIALIZER, useFactory: loadConfig, deps: [OidcConfigService], multi: true, },
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private oidcConfigService: OidcConfigService
  ) {
    this.oidcConfigService.onConfigurationLoaded.subscribe((res) => {
      if (res) {
        const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
        openIDImplicitFlowConfiguration.stsServer = this.oidcConfigService.clientConfiguration.stsServer;
        openIDImplicitFlowConfiguration.redirect_url = this.oidcConfigService.clientConfiguration.redirect_url;
        // The Client MUST validate that the aud (audience) Claim contains its client_id value registered at the Issuer
        // identified by the iss (issuer) Claim as an audience.
        // The ID Token MUST be rejected if the ID Token does not list the Client as a valid audience,
        // or if it contains additional audiences not trusted by the Client.
        openIDImplicitFlowConfiguration.client_id = this.oidcConfigService.clientConfiguration.client_id;
        openIDImplicitFlowConfiguration.response_type = this.oidcConfigService.clientConfiguration.response_type;
        openIDImplicitFlowConfiguration.scope = this.oidcConfigService.clientConfiguration.scope;
        openIDImplicitFlowConfiguration.post_logout_redirect_uri = this.oidcConfigService.clientConfiguration.post_logout_redirect_uri;
        openIDImplicitFlowConfiguration.start_checksession = this.oidcConfigService.clientConfiguration.start_checksession;
        openIDImplicitFlowConfiguration.silent_renew = this.oidcConfigService.clientConfiguration.silent_renew;
        openIDImplicitFlowConfiguration.silent_renew_url = this.oidcConfigService.clientConfiguration.silent_renew_url;
        openIDImplicitFlowConfiguration.post_login_route = this.oidcConfigService.clientConfiguration.startup_route;
        // HTTP 403
        openIDImplicitFlowConfiguration.forbidden_route = this.oidcConfigService.clientConfiguration.forbidden_route;
        // HTTP 401
        openIDImplicitFlowConfiguration.unauthorized_route = this.oidcConfigService.clientConfiguration.unauthorized_route;
        openIDImplicitFlowConfiguration.log_console_warning_active = this.oidcConfigService.clientConfiguration.log_console_warning_active;
        openIDImplicitFlowConfiguration.log_console_debug_active = this.oidcConfigService.clientConfiguration.log_console_debug_active;
        // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
        // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
        openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = this.oidcConfigService.clientConfiguration.max_id_token_iat_offset_allowed_in_seconds;

        const authWellKnownEndpoints = new AuthWellKnownEndpoints();
        authWellKnownEndpoints.setWellKnownEndpoints(this.oidcConfigService.wellKnownEndpoints);

        this.oidcSecurityService.setupModule(
          openIDImplicitFlowConfiguration,
          authWellKnownEndpoints
        );
      }
      else {
        const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
        openIDImplicitFlowConfiguration.stsServer = "https://account.fhconnect.co.za";
        openIDImplicitFlowConfiguration.redirect_url = "http://localhost/authorize";
        // The Client MUST validate that the aud (audience) Claim contains its client_id value registered at the Issuer
        // identified by the iss (issuer) Claim as an audience.
        // The ID Token MUST be rejected if the ID Token does not list the Client as a valid audience,
        // or if it contains additional audiences not trusted by the Client.
        openIDImplicitFlowConfiguration.client_id = "mobileapp";
        openIDImplicitFlowConfiguration.response_type = "id_token token";
        openIDImplicitFlowConfiguration.scope = "openid profile api1 roles custom.profile";
        openIDImplicitFlowConfiguration.post_logout_redirect_uri = "http://localhost";
        openIDImplicitFlowConfiguration.start_checksession = false;
        openIDImplicitFlowConfiguration.silent_renew = false;
        openIDImplicitFlowConfiguration.silent_renew_url = "http://localhost/silent-renew.html";
        openIDImplicitFlowConfiguration.post_login_route = "/updateuser";
        // HTTP 403
        openIDImplicitFlowConfiguration.forbidden_route = "/forbidden";
        // HTTP 401
        openIDImplicitFlowConfiguration.unauthorized_route = "/";
        openIDImplicitFlowConfiguration.log_console_warning_active = true;
        openIDImplicitFlowConfiguration.log_console_debug_active = true;
        // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
        // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
        openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 1000;

        const authWellKnownEndpoints = new AuthWellKnownEndpoints();
        authWellKnownEndpoints.issuer = 'https://account.fhconnect.co.za';

        authWellKnownEndpoints.jwks_uri =
          'https://account.fhconnect.co.za/.well-known/openid-configuration/jwks';
        authWellKnownEndpoints.authorization_endpoint = 'https://account.fhconnect.co.za/connect/authorize';
        authWellKnownEndpoints.token_endpoint = 'https://account.fhconnect.co.za/connect/token';
        authWellKnownEndpoints.userinfo_endpoint = 'https://account.fhconnect.co.za/connect/userinfo';
        authWellKnownEndpoints.end_session_endpoint = 'https://account.fhconnect.co.za/connect/endsession';
        authWellKnownEndpoints.check_session_iframe =
          'https://account.fhconnect.co.za/connect/checksession';
        authWellKnownEndpoints.revocation_endpoint = 'https://account.fhconnect.co.za/connect/revocation';
        authWellKnownEndpoints.introspection_endpoint =
          'https://account.fhconnect.co.za/connect/introspect';

        //authWellKnownEndpoints.setWellKnownEndpoints(this.oidcConfigService.wellKnownEndpoints);
        this.oidcConfigService.clientConfiguration.apiServer = "https://account.fhconnect.co.za/api";

        this.oidcSecurityService.setupModule(
          openIDImplicitFlowConfiguration,
          authWellKnownEndpoints
        );
      }
    }, (error) => {
        console.log("error occured", error);
    });
  }
}
