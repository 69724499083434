import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {
  statuscode: any;
  status: any;
  constructor(public route: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.statuscode = params.statuscode;
    });
    this.getStatus(parseInt(this.statuscode));
  }

  getStatus(id) {
    switch (id) {
      case 0:
        this.status = "Not Done";
        break;
      case 1:
        this.status = "Approved";
        break;
      case 2:
        this.status = "Declined";
        break;
      case 3:
        this.status = "Cancelled";
        break;
      case 4:
        this.status = "User Cancelled";
        break;
      case 5:
          this.status = "Received by PayGate";
          break;
      case 7:
          this.status = "Settlement Voided";
          break;
      case -1:
        this.status = "Unable to reconsile transaction";
        break;
      case -2:
        this.status = "Checksum Error. The values have been altered";
      default:
        this.status = "Unknown Status";
        break;
    }
  }

  onClick(){
    this.router.navigate(['/'])
  }
}
