import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { Staff, Staffs } from '../models/staff/staff';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Roles } from '../models/account/Roles';
import { status, resetPassword } from '../models/account/common';


@Injectable({
  providedIn: 'root'
})

export class StafftService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }

  getStaffRole(page?: number): Observable<Roles> {
    let header = this.auth.getAuthHeader();
    if (page)
      return this.api.HttpGet<Roles>("/Role/Staffrole?page=" + page, header);
    else
      return this.api.HttpGet<Roles>("/Role/Staffrole", header);
  }

  getStafflist(roleid, page): Observable<Staffs> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Staffs>("/Staff/Role/" + roleid + "?page=" + page, header);
  }

  getStaff(id): Observable<Staff> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Staff>("/Staff/" + id, header);
  }

  addStaff(staff: Staff, flag): Observable<Staff> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<Staff>("/Staff/"+ flag, staff, header);
  }

  editStaff(staff: Staff, id): Observable<Staff> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<Staff>("/Staff/" + id, staff, header);
  }

  changeStaffStatus(status: status): Observable<status> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<status>("/Staff/changestatus/", status, header);
  }

  resetPassword(data: resetPassword): Observable<resetPassword> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<resetPassword>("/Staff/ResetPassword/", data, header);
  }

  searchStaff(roleid, search): Observable<Staffs> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Staffs>("/Staff/Role/" + roleid + "?search=" + search, header);
  }

  profileImgUpload(data, id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Staff/ProfileUploadImg/" + id, data, header);
  }

  getFUStaffRole(page?: number): Observable<Roles> {
    let header = this.auth.getAuthHeader();
    if (page)
      return this.api.HttpGet<Roles>("/Role/FUStaffrole?page=" + page, header);
    else
      return this.api.HttpGet<Roles>("/Role/FUStaffrole", header);
  }
}
