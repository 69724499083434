import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClientService } from '@app/services/client.service';
import { CorporateService } from '@app/services/corporate.service';
import { SupplierService } from '@app/services/supplier.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { RegisterService } from '../../services/register.service';
import { AccountService } from '@app/services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-paynow',
  templateUrl: './paynow.component.html',
  styleUrls: ['./paynow.component.scss']
})
export class PaynowComponent implements OnInit {

  premium;
  clientPayment;
  premiumStatus: boolean;
  role;
  payFormGroup: FormGroup;
  msg;
  diablesave = false;
  clientdata: any;

  constructor(public dialogRef: MatDialogRef<PaynowComponent>, @Inject(MAT_DIALOG_DATA) public data: '', 
    public clientservice: ClientService, public common: CommonService, public corporateService: CorporateService, public _formbuilder: FormBuilder,
    public supplierservice: SupplierService, private account: AccountService,
    public register: RegisterService) {
    this.premium = data;
   }

  ngOnInit() {
    this.payFormGroup = this._formbuilder.group({
      paymentMethod: ['', Validators.required]
    });
    this.role = this.premium.role;
    this.getInitialdata();  
  }

  getInitialdata() {
    this.account.getaccount()
      .pipe(
        finalize(() => {
          
        })
      )
      .subscribe(res => {
        this.clientdata = res;        
      })
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  public post(path, params, method='post') {      
      const form = document.createElement('form');
      form.method = method;
      form.action = path;
    
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = params[key];
    
          form.appendChild(hiddenField);
        }
      }    
      document.body.appendChild(form);
      form.submit();
    }

  save(data) {
    if (this.premium.role == 'employer') {    
      var premiumdata = {
        premiumAmount: this.premium.premium,
        paymentMethod: data.paymentMethod,
      }
      if (data.paymentMethod != "cash") {
        this.corporateService.GetPayGatePage(this.premium.premium, this.clientdata.email, this.clientdata.id).subscribe(res => {
          this.post('https://secure.paygate.co.za/payweb3/process.trans', { PAY_REQUEST_ID: res.PAY_REQUEST_ID, CHECKSUM: res.CHECKSUM });
        })
      }
      else {
         this.corporateService.SaveEmployerPremium(premiumdata).subscribe(res => {        
           this.premiumStatus = true;        
           this.dialogRef.close(true);
           this.common.openSnackBar('Payment Completed Successfully!', 'close');
          });
      }
    }
    if (this.premium.role == 'client') {
      if (data.paymentMethod != "cash") {
        this.corporateService.GetPayGatePage(this.premium.premium, this.premium.email, this.premium.clientId).subscribe(res => {
          this.post('https://secure.paygate.co.za/payweb3/process.trans', { PAY_REQUEST_ID: res.PAY_REQUEST_ID, CHECKSUM: res.CHECKSUM });
        })
      }
      else {
        this.clientPayment = {
          amount: this.premium.premium,
          status: 1,
          paymentMethod: data.paymentMethod,
          premiumId: this.premium.premiumId,
          planId: this.premium.planId
        }
        this.clientservice.savepayment(this.premium.clientId, this.clientPayment).subscribe(res => {
          this.dialogRef.close(true);
        })
      }
    }
    if (this.premium.role == 'immediate') {
      if (data.paymentMethod != "cash") {
        this.corporateService.GetPayGatePage(this.premium.premium, this.premium.email, this.premium.clientId).subscribe(res => {
          this.post('https://secure.paygate.co.za/payweb3/process.trans', { PAY_REQUEST_ID: res.PAY_REQUEST_ID, CHECKSUM: res.CHECKSUM });
        })
      }
      else {
        this.clientservice.Sendinvoice(this.premium.clientId).subscribe(res => {
          this.dialogRef.close(true);
        })
      }
    }
    if (this.premium.role == 'immediatefront') {
      if (data.paymentMethod != "cash") {
        this.corporateService.GetPayGatePage(this.premium.premium, this.premium.email, this.premium.clientId).subscribe(res => {
          this.post('https://secure.paygate.co.za/payweb3/process.trans', { PAY_REQUEST_ID: res.PAY_REQUEST_ID, CHECKSUM: res.CHECKSUM });
        })
      }
      else {
        this.register.Sendinvoice(this.premium.clientId).subscribe(res => {
          this.dialogRef.close(true);
        })
      }
    }
    if (this.premium.role == 'supplier') {
      this.dialogRef.close(true); 
    }
  }

  getmethod(value) {
    this.msg = '';
    this.diablesave = false;
    if (value == 'Card') {
      this.msg = 'Coming Soon';
      this.diablesave = true;
    }
  }
}
