import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@app/services/common.service';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'app-notitifications',
  templateUrl: './notitifications.component.html',
  styleUrls: ['./notitifications.component.scss']
})
export class NotitificationsComponent implements OnInit {

  notifications: any;
  displayedColumns: string[] = ['title', 'description', 'timestamp', 'action'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  searchKey: string = '';
  pageSize = 10;
  data;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(public common: CommonService) { }

  ngOnInit() {
    
  }

  delete(data) {
    this.common.DeleteNotifications(data).subscribe(res => {
      this.notifications = res;
      this.loaddata();
    });
  }

  loaddata(){
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.common!.GetNotifications(this.paginator.pageIndex, this.searchKey);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.totalCount;
          this.pageSize = data.pageSize;

          return data.notifications;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 1);
    this.loaddata();    
  }

  applyFilter(keyword){
    this.loaddata();
  }

}
