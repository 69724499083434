import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { status, resetPassword } from '../models/account/common';
import { AddCorporateDto, CorporateClientList, CorporateClientGetStart, proposal, categoryDto, EmployeeDto, PolicyDateDto, CorporateClient, CorporateUpdateDto, CorporateClients } from '../models/corporate/corporate';
import { Users } from '../models/account/Users';
import { ApiResponse } from '../models/ApiResponse.model';
import { ISearchClient, SearchClient } from '../models/client/client';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CorporateService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }
  
  resetPassword(data: resetPassword): Observable<resetPassword> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<resetPassword>("/Corporate/ResetPassword/", data, header);
  }

  checkCompanyName(email, companyName): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/CheckCompanyName/"+email+"/"+companyName, header);
  }

  addCorporate(addCorporate: AddCorporateDto, vendorId:number, action:string) : Observable<any> {
    let header = this.auth.getAuthHeader();
    if(vendorId !== undefined){
      if(action == 'add'){
        return this.api.HttpPost("/Corporate/AddCorporateClient/me/"+vendorId, addCorporate, header);
      }
      if(action == 'assign'){
        return this.api.HttpPost("/Corporate/AssignFu/"+vendorId, addCorporate, header);
      }
      
    }  
  }
  
  updateCorporate(vendorId:number, corpId:number, corporate: CorporateUpdateDto) : Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Corporate/UpdateEmployer/"+vendorId+"/"+corpId, corporate, header);    
  }

  updateEmployee(vendorId:number, corpId:number, employeeId, employee) : Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Corporate/UpdateEmployee/"+vendorId+"/"+corpId+"/"+employeeId, employee, header);    
  }
  updateFuEmployee(employee) : Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Corporate/UpdateFuEmployee/", employee, header);    
  }
  updateMyEmployee(employee) : Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Corporate/UpdateMyEmployee/", employee, header);    
  }
  updateEmployeeMyInfo(employee) : Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Corporate/UpdateEmployeeMyInfo/", employee, header);    
  }  

  isFuAssigned(corporateId): Observable<any> {
  let header = this.auth.getAuthHeader();
  return this.api.HttpGet<any>("/Corporate/IsFuAssigned/" + corporateId, header);
}
getAllCorporateClients(pageno, status, search?): Observable<CorporateClients> {
    let header = this.auth.getAuthHeader();   
    return this.api.HttpGet<CorporateClients>("/Corporate/all?page=" + pageno + '&search=' + search + '&status=' + status, header);
  }
  
  GetMyEmployersList(pageno, status, search?): Observable<Users> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Users>("/Corporate/GetMyEmployers?page=" + pageno + '&search=' + search + '&status=' + status, header);
  }
  
  getAdminMyCorporateClients(vendorId, page, search?): Observable<any> {
    let header = this.auth.getAuthHeader();
    if (search)
    return this.api.HttpGet<Users>("/Corporate/MyCorporateClients/"+vendorId+"?page="+page+'&search='+search, header);
    else
      return this.api.HttpGet<Users>("/Corporate/MyCorporateClients/"+vendorId+"?page=" + page, header);
  }
  
  getCorporate(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetCorporate/" + id, header);
  }
  getCorporateClient(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/me/" + id, header);
  }

  getstartstatus(corporateId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/getstartStatus/" + corporateId, header);
  }
  changeCorporateClientStatus(status: status): Observable<status> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<status>("/Corporate/changestatus/", status, header);
  }

  corporateGetStart(corClient: CorporateClientGetStart): Observable<any> {
    let header = this.auth.getAuthHeader();   
    return this.api.HttpPost<any>("/Corporate/corporateGetStart/", corClient, header);
  }
  uploadProposal(corporateid, data: proposal): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Corporate/UploadProposal/" + corporateid , data, header);
  }
  getProposal(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/proposal/" + id, header);
  }
  deleteProposal(id, fileId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Corporate/DeleteProposal/"+ id + "/" + fileId, header);
  }

  addPackage(category: categoryDto) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Corporate/AddCategory/", category, header);
  }
  editPackage(vendorId, corpId, packageId, category: categoryDto):Observable<ApiResponse>{    
    let header = this.auth.getAuthHeader();    
    return this.api.HttpPut<ApiResponse>("/Corporate/EditPackage/"+vendorId+"/"+corpId+"/"+packageId, category, header);
  }
  
  getPackageList(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/List/" + id, header);
  }
  GetActivePackages(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetActivePackages/" + id, header);
  }

  addEmployee(data: EmployeeDto): Observable<any> {
    let header = this.auth.getAuthHeader();    
    return this.api.HttpPost<any>("/Corporate/AddEmployee/", data, header);
  }

  addVendorCorpEmployee(corporateId, data: EmployeeDto): Observable<any> {
    let header = this.auth.getAuthHeader();    
    return this.api.HttpPost<any>("/Corporate/AddEmployee/"+corporateId, data, header);
  }

  addAdminCorpEmployee(vendorId, corporateId, data: EmployeeDto): Observable<any> {
    let header = this.auth.getAuthHeader();    
    return this.api.HttpPost<any>("/Corporate/AddEmployee/"+vendorId+"/"+corporateId, data, header);
  }
  
  getMyEmployeeList(pageno, search?): Observable<Users> {
    let header = this.auth.getAuthHeader();    
    if (search)
      return this.api.HttpGet<Users>("/Corporate/GetMyEmployeeList?page=" + pageno + '&search=' + search, header);
    else
      return this.api.HttpGet<Users>("/Corporate/GetMyEmployeeList?page=" + pageno, header);
  }
  getFuEmployeeList(corpId, pageno, search?): Observable<Users> {
    let header = this.auth.getAuthHeader();    
    if (search)
      return this.api.HttpGet<Users>("/Corporate/GetFuEmployeeList/"+corpId+"/?page=" + pageno + '&search=' + search, header);
    else
      return this.api.HttpGet<Users>("/Corporate/GetFuEmployeeList/"+corpId+"/?page=" + pageno, header);
  }
  getAdminEmployeeList(vendorId, corpId, pageno, search?): Observable<Users> {
    let header = this.auth.getAuthHeader();    
    if (search)
      return this.api.HttpGet<Users>("/Corporate/GetAdminEmployeeList/"+vendorId+"/"+corpId+"/?page=" + pageno + '&search=' + search, header);
    else
      return this.api.HttpGet<Users>("/Corporate/GetAdminEmployeeList/"+vendorId+"/"+corpId+"/?page=" + pageno, header);
  }
  getCorpPackage(packageId): Observable<any>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/ViewCategory/" + packageId, header);
  }
  IsPackageAssigned(vendorId, corpId, packageId): Observable<ApiResponse> {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ApiResponse>("/Corporate/IsPackageAssigned/"+vendorId+"/"+corpId+"/"+packageId, header);
  }
  deletePackage(vendorId, corpId, packageId): Observable<ApiResponse> {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<ApiResponse>("/Corporate/DeleteCategory/"+vendorId+"/"+corpId+"/"+packageId, header);
  }
  changeCategoryStatus(data):Observable<ApiResponse>{    
    let header = this.auth.getAuthHeader();    
    return this.api.HttpPut<ApiResponse>("/Corporate/ChangeCategoryStatus/", data, header);
  }
  getMyPackage(): Observable<any> {
    let header = this.auth.getAuthHeader();    
    return this.api.HttpGet<any>("/Corporate/GetEmpPackage/", header);
  }
  getMyEmpPackage(empId): Observable<any> {
    let header = this.auth.getAuthHeader();    
    return this.api.HttpGet<any>("/Corporate/GetMyEmpPackage/"+empId, header);
  }
  getMyEmployeePackage(empId): Observable<any> {
    let header = this.auth.getAuthHeader();    
    return this.api.HttpGet<any>("/Corporate/GetMyEmployeePackage/"+empId, header);
  }

  showPremium(empId?): Observable<any> {    
    let header = this.auth.getAuthHeader();    
    if(empId){
      return this.api.HttpGet<any>("/Corporate/ShowEmpPremium/?empId="+empId, header);
    }else{
      return this.api.HttpGet<any>("/Corporate/ShowEmpPremium/", header);
    }    
  }
  getCorpFuId(CorpId): Observable<any> {
    let header = this.auth.getAuthHeader();    
    return this.api.HttpGet<any>("/Corporate/getmyvendorid/"+CorpId, header);
  }
  getFuIdByEmployeeId(employeeId): Observable<any> {
    let header = this.auth.getAuthHeader();    
    return this.api.HttpGet<any>("/Corporate/getFuIdByEmployeeId/"+employeeId, header);
  }
  uploadcsv(data) {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Corporate/ImportEmployeeCsv", data, header);
  }
  ImportEmployee(data, VendorId, EmployerId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Corporate/ImportEmployee/"+VendorId+"/"+EmployerId, data, header);
  }

  getEmployeeMyInfo(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetEmployeeMyInfo", header);
  }
  
  getCorporateMyInfo(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetCorporateMyInfo", header);
  }

  getEmployeeInfo(corpId, empId): Observable<any> {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetEmployeeInfo/me/"+corpId+"/"+ empId, header);
  }
  getVendorCorpEmpInfo(vendorId, corpId, empId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetEmployeeInfo/me/"+vendorId+"/"+corpId+"/"+ empId, header);
  }
  getMyEmployeeInfo(empId): Observable<any> {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetMyEmployeeInfo/"+empId, header);
  }

  getMyemployeesPremium(): Observable<any> {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetMyEmployeesPremium/", header);
  }
  
  updatePolicyDate(data: PolicyDateDto): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/Corporate/UpdatePolicyDate/", data, header);
  }

  getEmployerPremium(): Observable<any> {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetEmployerPremium/", header);
  }
  SaveEmployerPremium(data): Observable<any> {    
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Corporate/SaveEmployerPremium/", data, header);
  }

  
  savepayment(empId, data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Payment/EmployerPayment/" + empId, data, header);
  }

  saveMypayment(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Payment/EmployerPayment/", data, header);
  }

  GetEmployerRequestMyStatus(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/CancelEmployer/me", header);
  }
  cancelEmployerMyStatus(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Corporate/CancelEmployer/me", data, header);
  }  
  cancelemployer(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Corporate/Cancelemployer/", data, header);
  }

  GetEmployerRequestStatus(empId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/CancelEmployer/" + empId, header);
  }

  getMyEmployers(filter: { name: string } = { name: '' }): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Corporate/GetMyClaimEmployers?search=' + filter.name , header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }

  GetAllEmployees(filter: { name: string } = { name: '' }): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Corporate/GetAllEmployees?search=' + filter.name, header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }

  ImportTransaction(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Payment/ImportTransaction/", data, header);
  }

  getEmployeesCount(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/EmployeesCount/", header);
  }

  GetPayGatePage(amount, email, userId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/PayGateway/"+amount+"/"+ email+"/"+ userId, header);
  }

  csvstatus(empolyerid) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetCSVStatus/" + empolyerid, header);
  }

  SaveImportEmployee(csvfileId, employerId) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/SaveImportEmployee/" + csvfileId + "/" + employerId, header);
  }

  GetErrorReport(empolyerid) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Corporate/GetErrorReport/" + empolyerid, header);
  }

  Updatecsvstatus(empolyerid) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Corporate/UpdateCSVStatus/" + empolyerid, "", header);
  }

  deleteEmployee(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Corporate/deleteEmployee/" + id , header);
  }

  deleteEmployer(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Corporate/deleteEmployer/" + id, header);
  }
}
