//import moment from 'moment-timezone';
import { Moment } from 'moment';



export interface ClientAdd {
  firstname: string;
  email: string;
  service: number;
}

export interface beneficiary {
  relationship: string;
  benefit: string;
  beneficiaryClientId?: number;
  beneficiaryClient: beneficiaryClient;
}

export interface beneficiaryClient {
  email: string;
  phoneNumber: string;
  mobilenumber?: string;
  title: string;
  firstname: string;
  surname: string;
  gender?: string;
  passportNo ?: string;
  nationalId ?: string;
  nationality: string;
  dob?: number;
  profileImgPath?: string;
}

export interface personal {
  title: string;
  firstname: string;
  surname: string;
  dob: string;
  email: string;
  gender?: string;
  mobilenumber: string;
  company?: string;
  nationality: string;
  passportNo?: string;
  nationalId?: string;
  phoneNumber?: string;
  addresses: address[];
  employer?: employer;
  profileImgPath?: string;
}

export interface address {
  id?: number;
  street: string;
  city: string;
  state?: string;
  country?: string;
  pincode: string;
  strcode?: string;
  isPrimary?: boolean;
  suburb?: string;
}

export interface employer {
  id?: number;
  employerName: string;
  phone: string;
  salary: number;
  incl: string;
  address ?: address;
}

export interface subMemberUser {

  email: string;
  PhoneNumber: string;
  Mobilenumber?: string;
  title: string;
  Firstname: string;
  Surname: string;
  gender: string;
  PassportNo?: string;
  nationalId?: string;
  nationality: string;
  dob: Moment;
  profileImgPath?: string;
}

export interface submember {
  subMemberUser: subMemberUser;
  relationship: string;
  benefit?: string;
  subMemberUserId?: number;
  asBeneficiary?: boolean;
}

export interface ISearchClient {
  totalCount: number;
  users: SearchClient[];
}

export class SearchClient {
  constructor(public id: number, public firstname: string) { }
}

export interface extendedmember {
  extendedMemberUser: subMemberUser;
  relationship: string;
  benefit?: string;
  extendedMemberUserId?: number;
  asBeneficiary?: boolean;
}

export interface deceasedmember {
  Id?: number;
  clientId?: number;
  title: string;
  firstname: string;
  surname: string;
  passportNo?: string;
  nationalId?: string;
  nationality: string;
  deathPlace: string;
  dod: string;
  doe?: string;
  imagePath?: string;
  documentPath?: string;
  courtesyMsg?: string;
  eventTime?: string;
  venue?: string;
}

export interface clientservicetype {
  id?: number;
  clientId?: number;
  isEvent: boolean;
}

export interface ClientAssignedPlan {
  clientId?: number;
  planId: number;
  dateOfDeduction: number;
  startMonth: number;
  typeOfPayment: number;
  status: number;
}

export interface ClientPackage {
  id?: number; 
  clientId: number;
  packageId: number;
  planId ?: number;
}

export interface ClientPayment {
  status: number;
  paymentMethod: string;
  amount: number;
  clientId?: number;
  premiumId: number;
  planId: number;
}
