import { Component, OnInit, Inject, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, ErrorStateMatcher } from '@angular/material';
import { FormGroup, Validators, FormGroupDirective, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-change-emailid',
  templateUrl: './change-emailid.component.html',
  styleUrls: ['./change-emailid.component.scss']
})
export class ChangeEmailidComponent implements OnInit {

  myForm: FormGroup;
  errorMsg;
  Id;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangeEmailidComponent>, @Inject(MAT_DIALOG_DATA) public data: '', public account: AccountService, public common: CommonService) {
    this.Id = data['Id'];
  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }

  onSubmit(data) {
    this.errorMsg = '';
    if (this.Id) {
      this.common.emailupdate(this.Id, data.email).subscribe(res => {
        this.dialogRef.close(true);
      }, err => {
        this.errorMsg = err.error.reasonPhrase;
      })
    }
    else {
      this.account.emailupdate(data.email).subscribe(res => {
        this.dialogRef.close(true);
      }, err => {
        this.errorMsg = err.error.reasonPhrase;
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}
