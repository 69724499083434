import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Supplier, Suppliers } from '../models/supplier/supplier';
import { status } from '../models/account/common';
import { ISearchClient, SearchClient } from '../models/client/client';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SupplierService {

  constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) { }

  getSupplierlist(page, keyword): Observable<Suppliers> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<Suppliers>("/Supplier?page=" + page + "&search=" + keyword, header);
  }

  getSupplier(id): Observable<Supplier> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<Supplier>("/Supplier/" + id, header);
  }

  getVendorRegionBasedSupplierlist(page, region ): Observable<Suppliers> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<Suppliers>("/Supplier/vendorRegionBasedSupplier/" + region +"?page=" + page, header);
  }

  addSupplier(Supplier: Supplier): Observable<any> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Supplier/", Supplier, header);
  }

  editSupplier(Supplier, id): Observable<Supplier> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpPut<Supplier>("/Supplier/" + id, Supplier, header);
  }

  changeSupplierStatus(status: status): Observable<status> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpPost<status>("/Supplier/changestatus/", status, header);
  }

  deleteSupplier(id): Observable<Supplier> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpDelete<Supplier>("/Supplier/" + id, header);
  }

  searchSupplier(search): Observable<Suppliers> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<Suppliers>("/Supplier?search=" + search, header);
  }

  suppliersearch(filter: { name: string } = { name: '' }, page = 1): Observable<ISearchClient> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/Supplier?search=' + filter.name, header)
      .pipe(
            tap((response: ISearchClient) => {
                response.users = response.users
                    .map(user => new SearchClient(user.id, user.firstname))
                    // Not filtering in the server since in-memory-web-api has somewhat restricted api
                    .filter(user => user.firstname);
                  return response;
              })
          );
  }

  profileImgUpload(data, id): Observable<any> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Supplier/ProfileUploadImg/" + id, data, header);
  }

  GetSupplierBankDetails(supplierId): Observable<any> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Supplier/GetSupplierBankDetails/" + supplierId, header);
  }

  savesupplierpayment(data): Observable<any> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Payment/SupplierPayment/", data, header);
  }

  Getsupplierpayments(supplierId, page, keyword): Observable<SupplierPayments> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<SupplierPayments>("/Payment/SupplierPayment/" + supplierId + "?page=" + (page+1) + "&seach=" + keyword, header);
  }

  getAllPyaments(page, keyword): Observable<SupplierPayments> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<SupplierPayments>('/Payment/SupplierPayments/all?page=' + (page + 1) + '&search=' + keyword, header);
  }

  uploaddocument(data): Observable<any> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/Supplier/document/", data, header);
  }

  GetDocument(supplierId): Observable<any> {
    const header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Supplier/document/" + supplierId, header);
  }
}

export interface SupplierPayment {

}

export interface SupplierPayments {
  pageSize: number;
  totalCount: number;
  payments: SupplierPayment[];
}
