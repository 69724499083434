import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClaimService } from '../../services/claim.service';
import { AccountService } from '../../services/account.service';
import { document, documentdata } from '../../models/documentCenter/document';
import { SupplierService } from '../../services/supplier.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent implements OnInit {

  fileerror;
  path;
  filename;
  document: document;
  documentFormGroup: FormGroup;
  documentData;
  docu;
  errormsg;

  constructor(private _formBuilder: FormBuilder, public supplier: SupplierService, public accountservice: AccountService, public claimservice: ClaimService, public dialogRef: MatDialogRef<UploadDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '', ) {
    this.docu = data;
  }

  ngOnInit() {
    this.documentFormGroup = this._formBuilder.group({
      file: ['', Validators.required],
      doctype: [this.docu.doctype, Validators.required]
    });
  }

  upload(files) {
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.accountservice.uploadfile(formData).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
          this.filename = res.filename;

        }
        else {
          this.fileerror = res.message;
        }
        this.documentFormGroup.controls["file"].reset();

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  remove() {
    this.path;
    this.accountservice.deleteDocument(this.path).subscribe(res => {
      this.path = '';
      this.filename = '';
    });
  }

  onSubmit(data) {
    this.document = {
      docType: this.docu.doctype,
      path: this.path
    };
    var claimdocument = {
      docType: this.docu.doctype,
      path: this.path,
      claimRequestId: this.docu.claimId
    }
    if (!this.docu.user) {
      this.documentData = {
        userId: this.docu.clientId,
        doc: this.document,
        memberType: this.docu.memberType,
        mainmemberId: this.docu.mainmemberId
      };
      this.accountservice.saveDocument(this.documentData).subscribe(resu => {
        this.claimservice.saveclaimdocument(claimdocument).subscribe(res => {
          this.dialogRef.close(true);
        })
      });
    }
    else {
      this.document = {
        docType: data.doctype,
        path: this.path
      };
      if (this.docu.user == "supplier") {
        this.documentData = {
          userId: this.docu.clientId,
          doc: this.document,
        };
        this.supplier.uploaddocument(this.documentData).subscribe(resu => {
          this.dialogRef.close(true);
        });
      }
      if (this.docu.user == "client") {
        this.documentData = {
          userId: this.docu.clientId,
          doc: this.document,
        };
        this.accountservice.saveDocument(this.documentData).subscribe(resu => {
          this.dialogRef.close(true);
        });
      }
      if (this.docu.user == "members") {
        this.documentData = {
          userId: this.docu.clientId,
          doc: this.document,
          memberType: this.docu.memberType,
          mainmemberId: this.docu.mainmemberId
        };
        this.accountservice.saveDocument(this.documentData).subscribe(resu => {
          this.dialogRef.close(true);
        });
      }
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
