import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'empty' })

export class EmptyPipe implements PipeTransform {
  transform(value: any, symbol: string) {
    let result;
    if (value === null || value == '') {
      result = symbol;
    }
    else {
      result = value;
    }
    return result;
  }
}

