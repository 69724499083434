import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CorporateService } from '@app/services';

@Component({
  selector: 'app-view-package',
  templateUrl: './view-package.component.html',
  styleUrls: ['./view-package.component.scss']
})
export class ViewPackageComponent implements OnInit {
  public isLoading = false;
  public package :any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public packageId,
    private corporateService: CorporateService
  ) { }

  ngOnInit() {
    this.getPackageDetails();
  }

  getPackageDetails() {
    this.corporateService.getCorpPackage(this.packageId).subscribe(res => {
      this.isLoading = false;      
      this.package = res;
    }, (err) => {            
    });
  }

}
