import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material';
import { AuthService } from './auth/auth.service';
import { ApiService } from './api.service';
import { Relationship, Province, Town, Benefit, PlanGlobalSettings } from '../models/account/common';
import { Role } from '../models/account/Role';

import moment from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISearchClient, SearchClient } from '../models/client/client';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  _city = ['East London', 'Queenstown', 'Zwelitsha', 'Kroonstad', 'Phuthaditjhaba', 'Bloemfontein', 'Soweto', 'Springs', 'Carletonville', 'Durban', 'Pinetown', 'Phalaborwa', 'Thabazimbi', 'Nelspruit', 'Rustenburg', 'Kuruman', 'Cape Town'];
  _state = ['Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'];
  _country = ['South Africa'];
  _job = [{ id: '1', name: 'White collar, clerical and administration work' }, { id: '2', name: 'Clerical with some travel, teachers, nurses and “good retail”' }, { id: '3', name: 'Retail, light industry and food manufacturing' }, { id: '4', name: 'Moderate industry and manufacturing' }, { id: '5', name: 'Heavy industry; Very heavy industry, mining' }];
  _title = ['Mr', 'Mrs', 'Miss', 'Dr', 'Prof', 'Hon', 'Rev', 'Ms', 'Chief', 'Mx']
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  dateValue;
  _dateValue;
  _salary = ['0-5000', '5001-25000', '25001-50000', '50000+']
  errormessage;
  private messageSource = new BehaviorSubject<any>(false);
  private headerScrollSource = new BehaviorSubject<boolean>(false);
  currentMessage = this.messageSource.asObservable();
  pageheaderscroll = this.headerScrollSource.asObservable();
  VendorId;
  constructor(public snackBar: MatSnackBar, private api: ApiService, private auth: AuthService) {
    this.getstaffvendorId();
  }

  initsidebarstatus() {

  }

  manualcheck(form) {
    this.errormessage = '';
    if (form.valid == false) {
      for (const key in form.controls) {
        if (form.controls.hasOwnProperty(key)) {
          const element = form.controls[key];
          if (element.status == 'INVALID') {
            for (const keye in element.errors) {
              if (element.errors.hasOwnProperty(keye)) {
                const elemente = element.errors[keye];
                if (keye == 'required') {
                  this.errormessage += key + ' ' + keye + '<br>';
                } else {
                  if (key != keye) {
                    this.errormessage += key + ' ' + keye + ' Invalid<br>';
                  } else {
                    this.errormessage += keye + ' Invalid<br>';
                  }
                }
                break;
              }
            }
          }
        }
      }
    }
    return this.errormessage;
  }

  getstaffvendorId() {
    this.getvendorId().subscribe(res => {
      this.VendorId = res.vendorId;
    })
  }

  changeMessage(status) {
    this.messageSource.next(status);
  }

  pageheaderscrollchange(status){
    this.headerScrollSource.next(status);
  }

  permissionSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = ['permission'];
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.autoHide;
    this.snackBar.open(message, action, config);
  }

  openSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.autoHide;
    this.snackBar.open(message, action, config);
  }

  loadRelationship(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/Relationship", header);
  }

  addRelationship(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/GlobalSettings/Relationship", data, header);
  }

  deleteRelationship(name) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/GlobalSettings/Relationship/"+name, header);
  }

  loadProvince(): Observable<Province[]> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Province[]>("/GlobalSettings/Provinces", header);
  }

  loadRegion() {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/GlobalSettings/regions", header);
  }

  addProvince(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/GlobalSettings/Provinces", data, header);
  }

  deleteProvince(name) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/GlobalSettings/Provinces/" + name, header);
  }

  loadTowns(pid): Observable<Town[]> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Town[]>("/GlobalSettings/Towns/" + pid, header);
  }

  loadCitites(region): Observable<Town[]> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Town[]>("/GlobalSettings/cities/" + region, header);
  }

  loadTownsbypName(pName): Observable<Town[]> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Town[]>("/GlobalSettings/GetTownsByName/" + pName, header);
  }

  addTown(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/GlobalSettings/Towns", data, header);
  }

  deleteTown(name) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/GlobalSettings/Towns/" + name, header);
  }

  loadsuburb(city): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/suburb/" + city, header);
  }

  loadcode(suburb): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/code/" + suburb, header);
  }

  addBenefit(data) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/GlobalSettings/Benefit", data, header);
  }

  getAllBenefit(): Observable<Benefit[]> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Benefit[]>("/GlobalSettings/Benefits", header);
  }

  calculateage(date) {
    this.dateValue = moment(date, 'YYYY-MM-DD');
    this._dateValue = moment(this.dateValue).format('YYYY-MM-DD');
    var year_age = moment().diff(this._dateValue, 'years');
    return year_age;
  }


  updatePlanGlobalSettings(data: PlanGlobalSettings) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/GlobalSettings/PlanGlobalSettings", data, header);
  }

  updateCorpGlobalSettings(data: PlanGlobalSettings) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/GlobalSettings/CorpGlobalSettings", data, header);
  }

  loadPlanGlobalSettings(): Observable<PlanGlobalSettings> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<PlanGlobalSettings>("/GlobalSettings/PlanGlobalSettings", header);
  }

  loadCorpGlobalSettings(): Observable<PlanGlobalSettings> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<PlanGlobalSettings>("/GlobalSettings/CorpGlobalSettings", header);
  }

  uploadcsv(data): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/GlobalSettings/importLocation/", data, header);
  }

  UsersCount(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/userscount", header);
  }

  PackagePremiumCalculation(planId, packageId, region, type, age, typeId, membertype): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/PackagePremiumCalculation/" + planId + "/" + packageId + "/" + region + "/" + type + "/" + age + "/" + typeId + "/" + membertype, header);
  }

  getusersbystatus(filter: { name: string } = { name: '' }, page = 1, status): Observable<ISearchClient> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<ISearchClient>('/DocumentCenter/getusersbystatus?page=1&search=' + filter.name + '&status=' + status, header)
      .pipe(
        tap((response: ISearchClient) => {
          response.users = response.users
            .map(user => new SearchClient(user.id, user.firstname))
            .filter(user => user.firstname)
          return response;
        })
      );
  }
  addFreecoverlemous(data): Observable<any>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/GlobalSettings/AddCorpProductFreeCoverlemous", data, header);
  }
  editFreecoverlemous(data): Observable<any>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/GlobalSettings/EditCorpProductFreeCoverlemous", data, header);
  }
  getFreecoverlemous(): Observable<any>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/GetCorporateProductSettings", header);
  }
  getFreecoverlemousById(id): Observable<any>{
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/GetCorporateProductSettings/"+id, header);
  }

  getfucommissionreport(startdate, enddate): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/Fucommissionreport/" + startdate + "/" + enddate, header);
  }

  getclientpaymentreport(startdate, enddate): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/ClientPaymentreport/" + startdate + "/" + enddate, header);
  }

  getclaimpaymentreport(startdate, enddate): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/GlobalSettings/ClaimPaymentreport/" + startdate + "/" + enddate, header);
  }

  GetNotifications(page, keyword): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Notification?page=" + (page+1) + "&search=" + keyword, header);
  }

  DeleteNotifications(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete<any>("/Notification/" + id, header);
  }

  getUnreadCount(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Notification/unreadcount", header);
  }

  getUnreadList(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Notification/unread/list", header);
  }

  readAllUnread(){
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/Notification/unread/read/all", {}, header);
  }

  emailupdate(id, email): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/GlobalSettings/UpdateEmailId/" + id + "/" + email, "", header);
  }

  getemailtemplatelist(pagenumber, keyword): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/EmailTemplate?page=" + (pagenumber + 1) + "&search=" + keyword, header);
  }

  getemailtemplate(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/EmailTemplate/" + id, header);
  }

  updateemailtemplate(email): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut<any>("/EmailTemplate/", email, header);
  }

  addemailtemplate(email): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost<any>("/EmailTemplate/", email, header);
  }

  getRolebyname(name): Observable<Role> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Role>("/account/rolebyname/" + name, header);
  }

  getvendorId(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/account/VendorId/", header);
  }
}
