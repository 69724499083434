import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { superadminMenu, fuMenu, clientMenu, staffMenu, corporateClientMenu, corporateEmployeeMenu, fustaffMenu } from '../../models/SideBarMenuItems.model';
import { AccountService } from '../../services/account.service';
import { CommonService } from '../../services/common.service';
import { RoleService } from '../../services/role.service';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  appversion = environment.version;
  message: string;
  title = 'ClientApp';
  isAuthorized: boolean;
  events: string[] = [];
  opened: boolean = true;
  hidden: string = 'block';
  userRole;
  navItems = [];
  staffnav;
  FUstaffnav;
  userpermission: Array<string>;
  status;
  expanded: boolean;
  isFustaff: boolean;

  public showMenu: string;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    public auth: AuthService,
    public breakpointObserver: BreakpointObserver,
    public account: AccountService,
    public roleservice: RoleService,
    public common: CommonService) {
    //this.navItems = clientMenu;
    this.init();
    //this.common.getstaffvendorId();
  }

  ngOnInit() {
    //this.init();
  }

  init() {
    this.showMenu = '';
    this.auth.init();
    setTimeout(() => {
      this.isAuthorized = this.auth.isAuthorized;
      this.userpermission = this.auth.getmypermission();
      if (this.isAuthorized) {
        this.opened = this.isAuthorized;
        this.userRole = this.auth.userRole;

        switch (this.userRole) {
          case 'Developer': {
            this.navItems = superadminMenu;
            break;
          }
          case 'Administrator': {
            this.navItems = superadminMenu;
            break;
          }
          case 'Funeral Undertaker': {
            this.navItems = fuMenu;
            break;
          }
          case 'Clients': {
            this.navItems = clientMenu;
            break;
          }
          case 'Corporate': {
            this.navItems = corporateClientMenu;
            break;
          }
          case 'Corporate Client': {
            this.navItems = corporateEmployeeMenu;
            break;
          }
          default: {
            this.common.getRolebyname(this.auth.userRole).subscribe(res => {
              //return res.fuStaff;
              if (res.fuStaff) {
                this.FUstaffnav = fustaffMenu;
                this.FUstaffmenu();
              }
              else {
                this.staffnav = staffMenu;
                this.staffmenu();
              }
            });
            
            //this.staffnav = staffMenu;
            //this.staffmenu();
            break;
          }
        }

        if (this.auth._userInfo && this.auth._userInfo.status == "1") {
          this.status = true;
        }
      }
    }, 1000);
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  staffmenu() {
    //console.log('userpermission');
    //console.log(this.userpermission);
    // this.account.getStaffMenu().subscribe(res => {
    //   this.userpermission = res;
    //   console.log('userpermission');
    //   console.log(this.userpermission);
      this.navItems = [];
      this.staffnav.forEach((item, index) => {
        if (item.displayName != 'Dashboard') {
          if (item.displayName != 'Event/Product') {
            if (this.userpermission.includes(item.permission)) {
             
              this.navItems.push(item);
            }
          }
          else {
            if (this.userpermission.includes(item.permission))
              this.navItems.push(item);
            else
              if (this.userpermission.includes('category.view'))
                this.navItems.push(item);
          }
        }
        else {
          this.navItems.push(item);
        }

      // });
      console.log('functiion');
      console.log(this.navItems);
    })


  }

  FUstaffmenu() {
    this.navItems = [];
    this.FUstaffnav.forEach((item, index) => {
      if (item.displayName != 'Dashboard') {
        if (item.displayName != 'Event/Product') {
        if (this.userpermission.includes(item.permission)) {
          this.navItems.push(item);
        }
      }
      else {
          if (this.userpermission.includes(item.permission)) {
            this.navItems.push(item);
          }
          else
            if (this.userpermission.includes('category.view')) {
              this.navItems.push(item);
            }
            
        }
      }
      else {
        this.navItems.push(item);
      }
      //console.log('function fu');
      //console.log(this.navItems);
    })


  }
}
