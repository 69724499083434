import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { planTypeList, plan, plans } from '../models/plan/plan';
import { PlanTypes } from '../models/plan/PlanType';
import { packages, planPackage, planRegion } from '../models/plan/package';
//import { interceptingHandler } from '@angular/common/http/src/module';

@Injectable({
  providedIn: 'root'
})

export class PlanService {

  constructor(private api: ApiService, private auth: AuthService) { }

  GetPlanTypes(): Observable<PlanTypes> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<PlanTypes>("/PlanType/List/", header);
  }

  AddPlanTypeSingle(plantype){
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/PlanType/PlanTypeSingle/", plantype, header);
  }

  AddPlanTypeGroup(plantype) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/PlanType/PlanTypeGroup/", plantype, header);
  }

  UpdatePlanTypeSingle(plantype, id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/PlanType/UpdatePlanTypeSingle/" + id, plantype, header);
  }

  UpdatePlanTypeGroup(plantype, id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/PlanType/UpdatePlanTypeGroup/"+ id, plantype, header);
  }

  getAlreadyUsedPlanType(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/PlanType/AlreadyUsedPlanType/" + id, header);
  }

  getPlanTypeList(pagenumber, keyword): Observable<PlanTypes> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<PlanTypes>("/PlanType/List?page=" + (pagenumber + 1) + "&search=" + keyword, header);
  }

  getPlanType(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/PlanType/" + id, header);
  }

  getPlanTypeListdropdown(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/PlanType/all", header);
  }

  //***************** PLAN *****************//

  AddPlan(plan: plan) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/plan/", plan, header);
  }

  UpdatePlan(plan) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut("/plan/", plan, header);
  }

  deletePlan(id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/plan/" + id, header);
  }

  deleteClientPlantype(id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/plan/plantype/" + id , header);
  }

  Alreadyplanused(id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/plan/AlreadyUsedPlan/" + id, header);
  }

  getPlans(pageno, search?): Observable<plans> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<plans>("/Plan/list?page=" + pageno + "&search=" + search, header);
  }

  deletePlantype(id, name) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/PlanType/" + id + "/" + name, header);
  }

  getPlanList(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/", header);
  }

  getPlan(planid): Observable<plan> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<plan>("/Plan/" + planid, header);
  }

  getRegionBasedPlanList(region, status = -1): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/location/" + region +"?status=" + status, header);
  }

  getPlanRegionBenefit(regionId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/GetPlanRegionBenefit/" + regionId, header);
  }

  addplanpackage(data: planPackage, regionId: number) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/plan/planPackage/" + regionId, data, header);
  }

  addplanregion(data: planRegion, planId: number) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/plan/planRegion/" + planId, data, header);
  }

  changestatus(id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/plan/planPackage/changestatus/" + id, {}, header);
  }

  planstatus(id, status) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/plan/planstatus/" + id + "/" + status, {}, header);
  }

  UpdatePlanPackage(planpackage) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut("/plan/PlanPackage/", planpackage, header);
  }

  //***************** PACKAGE *****************//

  AddPackage(packages: packages) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPost("/plan/package/", packages, header);
  }

  UpdatePackage(id, packages: packages) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpPut("/plan/package/" + id, packages, header);
  }

  alreadyPackageused(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/plan/AlreadyPackageUsed/" + id, header);
  }

  deletePackage(id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/plan/package/" + id, header);
  }

  deletePackageProduct(id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/plan/packageproduct/" + id, header);
  }

  getPackages(): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/packages", header);
  }

  getPackageList(page, keyword): Observable<packagelist> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<packagelist>("/Plan/packages/list?page="+(page+1)+"&search="+keyword, header);
  }

  getPackage(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/package/" + id, header);
  }

  getpackagesbyregion(planId, region): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/PlanPackages/" + planId + "/" + region, header);
  }

  getPlanPackageProduct(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/PlanPackagesProduct/" + id, header);
  }

  getPackageProductOption(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/PackageProductOption/" + id, header);
  }

  //***************** Office Premium *****************//

  getOfficePremium(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/OfficePremium/" + id, header);
  }


  getPlanBenefitOption(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/BenefitProductOption/" + id, header);
  }

  getQuestion(packageId): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/Plan/Question/" + packageId, header);
  }

  deletePlanPackage(id) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpDelete("/plan/planpackage/" + id, header);
  }

  getAlreadyUsedPlanPackage(id): Observable<any> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<any>("/plan/AlreadyUsedPlanPackage/" + id, header);
  }
}

export interface ClientPlan{
  clientId?: number;
  planId: number;
  inceptionDate?: Date;
  dateOfDeduction: string;
  startMonth: string;
  typeOfPayment: string;
  premium: ClientPremium;
  extendedMemberPlans: extenedMemberPlan[]; 
  packages: ClientPlanPackage[];
  status: number;
  policyStatus?: number;
  packageId?: number;
  questions?: [];
}

export interface ClientPremium {
  clientId?: number;
  premiumAmount: number;
  totalPremiumAmount?: number;
  nextDue?: Date;
}

export interface ClientPlanPackage {
  clientId: number;
  packageId: number;
  packageProducts: ClientPlanPackageProduct[]
}

export interface ClientPlanPackageProduct{
  PackageProductId: number;
}

export interface extenedMemberPlan{
  clientId: number;
  planId: number;
  premiumAmount: number;
  age: number;
  planPackages: ExtendedMemberPlanPackage[];
  status?: number;
  packageId?: number;
  questions?: [];
}

export interface ExtendedMemberPlanPackage {
  packageId: number;
  packageProducts: ExtendedMemberPlanPackageProducts[];
}

export interface ExtendedMemberPlanPackageProducts {
  packageProductId: number;
}

export interface packagelist {
  pageSize: number;
  totalCount: number;
  packages: packages[];
}
