import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ClientService } from '../../services/client.service';
import { ClientPayment } from '../../models/client/client';
import { CorporateService } from '../../services/corporate.service';

@Component({
  selector: 'app-product-setting',
  templateUrl: './product-setting.component.html',
  styleUrls: ['./product-setting.component.css']
})
export class ProductSettingComponent implements OnInit {

  inputData;
  benefitProducts;
  BenefitProdForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ProductSettingComponent>,
    public _formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    public corporateService: CorporateService,
    public common: CommonService) {
    this.inputData = data;
    this.benefitProducts = this.inputData.products
  }

  ngOnInit() {
    if (this.inputData.action == 'add') {
      this.BenefitProdForm = this._formbuilder.group({
        productId: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
        isFreeCoverLemous: [true],
        freeCoverLemous: [''],
        isAgeGroup: [true],
        minAge: [''],
        maxAge: [''],
      });
    }
    if (this.inputData.action == 'edit') {
      this.BenefitProdForm = this._formbuilder.group({
        productId: [this.inputData.formValues.productId],
        isFreeCoverLemous: [this.inputData.formValues.isFreeCoverLemous],
        freeCoverLemous: [this.inputData.formValues.freeCoverLemous],
        isAgeGroup: [this.inputData.formValues.isAgeGroup],
        minAge: [this.inputData.formValues.minAge],
        maxAge: [this.inputData.formValues.maxAge],
      });
    }

  }

  changeMultiple(event) {
  }

  submitForm(data) {
    if (this.inputData.action == 'add') {
      this.common.addFreecoverlemous(data).subscribe(res => {
        if (res.succeeded == true)
          this.dialogRef.close(true);
        if (res.succeeded == false)
          this.common.openSnackBar(res.reasonPhrase, 'Close');
      }, error => {
        this.common.openSnackBar(error.error.reasonPhrase, 'Close');
      });
    }
    if (this.inputData.action == 'edit') {
      data.id = this.inputData.formValues.id;
      this.common.editFreecoverlemous(data).subscribe(res => {
        if (res.succeeded == true)
          this.dialogRef.close(true);
        if (res.succeeded == false)
          this.common.openSnackBar(res.reasonPhrase, 'Close');
      }, error => {
        this.common.openSnackBar(error.error.reasonPhrase, 'Close');
      });
    }    
  }

}
