import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment-timezone';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { subMemberUser, submember } from '../../models/client/client';
import { ClientService } from '../../services/client.service';
import { AccountService } from '../../services/account.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-add-emp-submember',
  templateUrl: './add-emp-submember.component.html',
  styleUrls: ['./add-emp-submember.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AddEmpSubmemberComponent implements OnInit {

  title;
  radioerr;
  maxDate = new Date();
  dob = new FormControl(moment());
  age;
  minage;
  maxage;
  ageerror;
  doberror;
  personFormGroup: FormGroup;
  relationship;
  clientid;
  relation;
  relationshipdetails;
  profileimageUrl = '/assets/images/avatar.png';
  errormsg;
  beneficiarycount;
  extendedmembercount;
  existbenecount;
  benefit;
  subMemberUser: subMemberUser;
  submember: submember;
  saving = false;
  enable = false;
  bank;
  errormessage;
  path;
  fileToUpload;
  fileerror;
  NDIerror;

  constructor(
    public common: CommonService,
    public _formbuilder: FormBuilder,
    public clientservice: ClientService,
    public account: AccountService,
    public dialogRef: MatDialogRef<AddEmpSubmemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ''
  ) {
    this.clientid = data;
  }

  manualcheck() {
    this.errormessage = this.common.manualcheck(this.personFormGroup);
  }

  ngOnInit() {
    this.title = this.common._title;
    this.getrelationship();    
    this.personFormGroup = this._formbuilder.group({
      title: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      Firstname: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      Surname: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*$')])],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      age: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
      relationship: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern('^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$')])],
      PassportNo: ['', Validators.compose([Validators.pattern('^[a-zA-Z0-9]*$')])],
      nationalId: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)])],
      nationality: ['', Validators.pattern('[a-zA-Z ]*$')],
      PhoneNumber: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
      Mobilenumber: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])],
      profileImgPath: [''],
      beneficiary: [''],
      benefit: ['', Validators.compose([Validators.required, Validators.min(1)])]
    });
    this.personFormGroup.controls["benefit"].disable();
    this.getbenefit();
    this.personFormGroup.valueChanges.subscribe(val => {
      this.onChange();
    });
  }

  onChange() {
    if (this.personFormGroup.valid) {
      this.enable = false;
    }
  }

  calculateage(type: string, event: MatDatepickerInputEvent<Date>) {
    this.age = this.common.calculateage(event.value);
    this.personFormGroup.get('age').setValue(this.age);
  }

  getrelationship() {
    this.common.loadRelationship().subscribe(res => {
      this.relationship = res;
    });
  }

  bencheck(value) {
    if (value.checked) {
      this.personFormGroup.controls["benefit"].enable();
      this.personFormGroup.addControl('bankName', new FormControl(''));
      this.personFormGroup.addControl('accountNo', new FormControl(''));
      this.personFormGroup.addControl('typeOfAccount', new FormControl(''));
      this.personFormGroup.addControl('accountHolderName', new FormControl(''));
      this.personFormGroup.addControl('branch', new FormControl(''));
      this.personFormGroup.addControl('branchCode', new FormControl(''));
    }
    else {
      this.personFormGroup.controls["benefit"].disable();
      this.personFormGroup.controls["benefit"].reset();
      this.personFormGroup.removeControl('bankName');
      this.personFormGroup.removeControl('accountNo');
      this.personFormGroup.removeControl('typeOfAccount');
      this.personFormGroup.removeControl('accountHolderName');
      this.personFormGroup.removeControl('branch');
      this.personFormGroup.removeControl('branchCode');
    }
  }

  getbenefit() {
    var beneficiarydata;
    this.clientservice.getBeneficiary(this.clientid.clientid).subscribe(res => {
      beneficiarydata = res;
      this.existbenecount = beneficiarydata.length;
      beneficiarydata.forEach((item, index) => {
        this.benefit = this.benefit + Number(item.benefit);
      });
      this.benefit = 100 - this.benefit;
    });
  }

  saveSubmember(data) {
    this.saving = true;
    this.enable = true;
    this.subMemberUser = {
      email: data.email,
      PhoneNumber: data.PhoneNumber,
      Mobilenumber: data.Mobilenumber,
      title: data.title,
      Firstname: data.Firstname,
      Surname: data.Surname,
      PassportNo: data.PassportNo,
      nationalId: data.nationalId,
      nationality: data.nationality,
      gender: data.gender,
      dob: data.dob,
      profileImgPath: this.path
    }
    this.submember = {
      subMemberUser: this.subMemberUser,
      relationship: data.relationship
    }
    if (data.beneficiary) {
      this.submember.benefit = data.benefit;
      this.submember.asBeneficiary = data.beneficiary;
    }
    if (data.beneficiary == '') {
      this.submember.asBeneficiary = false;
    }
    
    this.clientservice.addSubmember(this.clientid.clientid, this.submember).subscribe(res => {
      if (data.beneficiary) {
        this.bank = {
          bankName: data.bankName,
          accountNo: data.accountNo,
          typeOfAccount: data.typeOfAccount,
          accountHolderName: data.accountHolderName,
          branch: data.branch,
          branchCode: data.branchCode
        }
        this.clientservice.SaveClientBankDetails(res.reasonPhrase, this.bank).subscribe(res1 => {});
      }
      this.common.openSnackBar('Submember added Successfully', 'Close');
      this.dialogRef.close(true);
    }, err => {
        this.saving = false;
      this.errormsg = err.error.reasonPhrase;
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  profileupload(file: FileList, files) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.profileimageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.account.uploadimage(formData).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
        }
        else {
          this.fileerror = res.message;
        }

      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  validationnationid() {
    this.NDIerror = '';
    var id = this.personFormGroup.get('nationalId').value;
    var gender = this.personFormGroup.get('gender').value;
    var DOB = new Date(this.personFormGroup.get('dob').value);
    var idbirth = id.substring(0, 6);
    var year = DOB.getFullYear();
    var mon: any = DOB.getMonth() + 1;
    var date: any = DOB.getDate();
    var date1;
    if (String(mon).length == 1)
      mon = 0 + String(mon);
    if (String(date).length == 1)
      date = 0 + String(date);
    var date1: any = (String(year).substring(2, 4)) + mon + date;
    if (+date1 != +idbirth) {
      this.NDIerror = 'Enter valid Nation Id';
      this.personFormGroup.get('nationalId').reset();
    }
    else {
      var genderno = id.substring(6, 7);
      if (genderno >= 0 && genderno <= 4) {
        if (gender != 'F') {
          this.personFormGroup.get('nationalId').reset();
          this.NDIerror = 'Enter valid Nation Id';
        }
      }
      else if (genderno >= 5 && genderno <= 9) {
        if (gender != 'M') {
          this.personFormGroup.get('nationalId').reset();
          this.NDIerror = 'Enter valid Nation Id';
        }
      }
      else {
        this.NDIerror = '';
      }
    }
  }

}


