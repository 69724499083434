import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { AuthService } from '../services/auth/auth.service';
import { AccountService } from '../services/account.service';
import { UserInfo } from '../models/account/common';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  isAuthorized: boolean;
  userRole;
  fuflag = false;
  clientflag = false;
  userInfo: UserInfo;

  constructor(
    public auth: AuthService,
    public router: Router,
    public account: AccountService,
    public common: CommonService
  ) {
    this.doinit();
    //this.common.getstaffvendorId();
  }

  ngOnInit() {
    this.doinit();
  }

  doinit() {
    this.auth.init();
    setTimeout(() => {
      this.isAuthorized = this.auth.isAuthorized;
      console.log("auth status: ", this.auth.isAuthorized);
      if (this.isAuthorized) {
        this.userRole = this.auth.userRole;
        console.log("user role: ", this.auth.userRole);
        this.userInfo = this.auth._userInfo;
        switch (this.userRole) {
          case 'Developer': {
            this.router.navigate(['admin']);
            break;
          }
          case 'Administrator': {
            this.router.navigate(['admin']);
            break;
          }
          case 'Funeral Undertaker': {
            this.router.navigate(['FU']);
            break;
          }
          case 'Clients': {
            this.router.navigate(['client']);
            break;
          }
          case 'Corporate Client': {
            this.router.navigate(['corporate-employee']);
            break;
          }
          case 'Corporate': {
            this.router.navigate(['corporate-client']);
            break;
          }
          default: {
            this.common.getRolebyname(this.auth.userRole).subscribe(res => {
              //return res.fuStaff;
              if (res.fuStaff) {
                this.router.navigate(['fustaff']);
              }
              else {
                this.router.navigate(['staff']);
              }
            });
            
            break;
          }
        }
      }
    }, 500);
  }

}
