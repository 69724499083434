import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.scss']
})
export class PersonalInfoFormComponent implements OnInit {

  inputdata;
  plantype;
  userplantype;
  doneby;

  constructor(
    public dialogRef: MatDialogRef<PersonalInfoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.inputdata = data['userdata'];
    this.plantype = data['plantype'];
    this.userplantype = data['userplantype'];
    this.doneby = data['doneby'];
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close(true);
  }

  resultoutput(event){
    this.close();
  }

}
