import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
      return this.checkUser(route);
  }

  canLoad(state: Router): Observable<boolean> | boolean {
    return this.checkUser(null);
  }

  private checkUser(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.auth.getIsAuthorized().pipe(
      map((isAuthorized: boolean) => {
        if (isAuthorized) {
          if (this.auth._userInfo && this.auth._userInfo.status != "1") {
            this.router.navigate(['/user-status']);
          }
          if(route.data.roles && route.data.roles.indexOf(this.auth._userInfo.role) == -1){
            this.router.navigate(['/updateuser']);
          }
          return true;
        }
        this.router.navigate(['/unauthorize']);
        return false;
      }),
      take(1)
    );
  }
}
