import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VendorService } from '../../services/vendor.service';
import { PlanService } from '../../services/plan.service';

@Component({
  selector: 'app-assign-plan',
  templateUrl: './assign-plan.component.html',
  styleUrls: ['./assign-plan.component.css']
})
export class AssignPlanComponent implements OnInit {

  planFormGroup: FormGroup;
  planlist;
  vendor;
  vendorplan;
  errormsg;
  enabled = true;

  constructor(public dialogRef: MatDialogRef<AssignPlanComponent>, @Inject(MAT_DIALOG_DATA) public data: '',
    public _formBuilder: FormBuilder,
    public planservice: PlanService,
    public vendorservice: VendorService) {
    this.vendor = data;
  }

  ngOnInit() {
    this.getplans();
    this.planFormGroup = this._formBuilder.group({
      planId: ['', Validators.required],
    });
    this.planFormGroup.valueChanges.subscribe(val => {
      this.onChange();
    });
  }

  getplans() {
    this.planservice.getRegionBasedPlanList(this.vendor.region, 1).subscribe(res => {
      this.planlist = res.plans;
    });
  }

  onSubmit(data) {
    let regionid;
    this.enabled = false;
    data.planId.regions.forEach((item, index) => {
      if (item.region == this.vendor.region) {
        regionid = item.id;
      }
    })
    this.vendorplan = {
      vendorId: this.vendor.id,
      planId: data.planId.id,
      regionId: regionid,
    };
    this.vendorservice.assignplan(this.vendorplan).subscribe(res => {
      this.dialogRef.close(true);
    }, err => {
        this.errormsg = err.error.reasonPhrase;
    });
  }

  onChange() {
    this.enabled = true;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
