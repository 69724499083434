import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private auth: AuthService,
    private api: ApiService
  ) { }

  getClientsReport(from, to) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/report/client/"+from+"/"+to, header);
  }

  getReportNewClients(from, to): Observable<NewClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewClients>("/report/client/NewClients/" + from + "/" + to, header);
  }

  getTotalPremiumReceived(from, to): Observable<TotalPremiumReceived> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<TotalPremiumReceived>("/report/client/TotalPremium/" + from + "/" + to, header);
  }

  getSalesReport(): Observable<Salesreports> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Salesreports>("/report/admin/salereport", header);
    }

  getClientClaim(from, to): Observable<TotalPremiumReceived> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<TotalPremiumReceived>("/report/client/Cliameduser/" + from + "/" + to, header);
  }

  getpremiumoverdue(): Observable<NewClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewClients>("/report/client/OverdueClient/", header);
  }

  getVendorClientsReport(from, to) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/report/vendor/count/" + from + "/" + to, header);
  }

  getVendorReportNewClients(from, to): Observable<NewClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewClients>("/report/vendor/NewClients/" + from + "/" + to, header);
  }

  getVendorTotalPremiumReceived(from, to): Observable<TotalPremiumReceived> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<TotalPremiumReceived>("/report/vendor/TotalPremium/" + from + "/" + to, header);
  }

  getVendorClientClaim(from, to): Observable<TotalPremiumReceived> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<TotalPremiumReceived>("/report/vendor/Claimeduser/" + from + "/" + to, header);
  }

  getVendorSalesReport(): Observable<Salesreports> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Salesreports>("/report/vendor/salereport", header);
  }

  getVendorSalesReportByid(id): Observable<Salesreports> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<Salesreports>("/report/vendor/salereport/" + id, header);
  }

  getVendorpremiumoverdue(): Observable<NewClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewClients>("/report/vendor/OverdueClient/", header);
  }
  getVendorCorporateReport(from, to) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/report/vendor/corporate/"+from+"/"+to, header);
  }
  getEmployersEmployees(from, to): Observable<NewClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewClients>("/report/vendor/corporate/NewEmployees/" + from + "/" + to, header);
  }

  getCorporateReport(from, to) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/report/corporate/"+from+"/"+to, header);
  }

  getReportNewEmployers(from, to): Observable<NewClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewClients>("/report/corporate/NewEmployers/" + from + "/" + to, header);
  }
  getReportNewEmployees(from, to): Observable<NewClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewClients>("/report/corporate/NewEmployees/" + from + "/" + to, header);
  }
  getCorporateTotalPremiumReceived(from, to): Observable<TotalPremiumReceived> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<TotalPremiumReceived>("/report/corporate/TotalPremium/" + from + "/" + to, header);
  }

  getNewVendor(from, to): Observable<NewVendors> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewVendors>("/report/vendor/NewVendor/" + from + "/" + to, header);
  }

  getVendorCommission(from, to): Observable<NewVendors> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewVendors>("/report/vendor/Commission/" + from + "/" + to, header);
  }

  getVendorClientTotalPremium(from, to): Observable<NewVendors> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewVendors>("/report/vendor/ClientTotalPremium/" + from + "/" + to, header);
  }

  getfuReport(from, to) {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet("/report/fu/count/" + from + "/" + to, header);
  }

  getVendorNewEmployers(from, to): Observable<NewClients> {
    let header = this.auth.getAuthHeader();
    return this.api.HttpGet<NewClients>("/report/vendor/corporate/NewEmployers/" + from + "/" + to, header);
  }

}



export class NewClients {
  totalCount: number;
  users: [];
}

export class NewVendors {
  totalCount: number;
  vendors: [];
}

export class TotalPremiumReceived {
  totalCount: number;
  data: [];
}

export class Salesreport {
  total: number;
  month: number;
  year: number;
}

export class Salesreports {
  data: Salesreport[]
}
