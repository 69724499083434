import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ClientService, CommonService } from '@app/services';
import { AuthService } from '@app/services/auth/auth.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-extendedmember-profile',
  templateUrl: './extendedmember-profile.component.html',
  styleUrls: ['./extendedmember-profile.component.scss']
})
export class ExtendedmemberProfileComponent implements OnInit {

  @Input() data;
  @Input() plandetails;
  @Input() others;
  @Output() render = new EventEmitter();
  fileToUpload;
  statusList = [
    { id: 0, value: 'Pending', text: 'Pending' },
    { id: 1, value: 'Active', text: 'Active' },
    { id: 2, value: 'Inactive', text: 'Inactive' },
    { id: 3, value: 'Suspend', text: 'Suspended' },
    { id: 4, value: 'Cancel', text: 'Cancelled' },
    { id: 5, value: 'Deceased', text: 'Deceased' },
    { id: 6, value: 'Close', text: 'Closed' }];
  imageURL = '/assets/images/avatar.png';
  fileerror;
  path;
  filename;

  constructor(private clientservice: ClientService,
    public auth: AuthService,
    public dialog: MatDialog,
    public router: Router,
    public common: CommonService) { }

  ngOnInit() {
    if (this.data.extendedMemberUser.profileImgPath){
      this.imageURL = this.auth.imageURL + this.data.extendedMemberUser.profileImgPath;
    }
  }

  StatusView(index) {
    return this.statusList[index].text;
  }
  
  profileupload(file: FileList, files, userId) {
    this.fileToUpload = file.item(0);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    for (let file of files) {
      this.fileerror = null;
      const formData = new FormData();
      formData.append(file.name, file);
      this.clientservice.profileImgUpload(formData, userId).subscribe(res => {
        if (res.succeeded) {
          this.path = res.fileId;
          this.filename = res.filename;
        } else {
          this.fileerror = res.message;
        }
      }, err => {
        this.fileerror = err.error.reasonPhrase;
      });
    }
  }

  edit( id, memberid, path,) {
    this.router.navigate([path, memberid, id]);
  }

}
